<template>
  <!-- 右侧 -->
  <div class="wayline-wrapper">
    <div class="height-100">
      <div class="create-plan-wrapper">
        <div
          style="height: 70px; line-height: 70px; border-bottom: 4px solid #F5F7F9; border-top: 4px solid #F5F7F9; font-weight: 450;">
          <a-row v-if="choosePoint">
            <a-col :span="4" style="display: flex;justify-content: center;align-items: center;">
              <LeftOutlined @click="nextPoint('sub')" style="color: #1890FF;font-size: 20px;" />
            </a-col>
            <a-col :span="12" style="display: flex;justify-content: center;align-items: center;">
              <span style="font-size: 14px;">航点{{ RightForm.index + 1 }}</span>
              <DeleteOutlined style="cursor:point" @click="deletePoint" />
            </a-col>
            <a-col :span="4" style="display: flex;justify-content: center;align-items: center;">
              <RightOutlined @click="nextPoint('add')" style="color: #1890FF;font-size: 20px;" />
            </a-col>
            <a-col :span="4">
              <div @click="closeDrawer" style="position: absolute;right:25px;top:0;cursor: pointer;">
                <CloseOutlined />
              </div>
            </a-col>
          </a-row>
          <a-row v-else>
            <a-col :span="24" style="display: flex;justify-content: center;align-items: center;">请选择航点</a-col>
            <div @click="closeDrawer" style="position: absolute;right:25px;top:0;cursor: pointer;">
              <CloseOutlined />
            </div>

          </a-row>
        </div>
        <div v-if="choosePoint" class="content3">

          <a-form ref="valueRef" layout="horizontal" :hideRequiredMark="true" :model="RightForm">
            <div class="jingwei">
              <div>
                <a-form-item name="height" :labelCol="{ span: 24 }">
                  <template #label>
                    <div class="title-box">
                      <div class="box-ball"></div>
                      <div class="box-text">经度</div>
                    </div>
                  </template>
                  <a-input style="width: 200px;" disabled="true" placeholder="请输入经度"
                    v-model:value="RightForm.Point.longitude" />
                </a-form-item>
                <a-form-item name="height" :labelCol="{ span: 24 }">
                  <template #label>
                    <div class="title-box">
                      <div class="box-ball"></div>
                      <div class="box-text">纬度</div>
                    </div>
                  </template>
                  <a-input style="width: 200px;" disabled="true" placeholder="请输入纬度"
                    v-model:value="RightForm.Point.latitude" />
                </a-form-item>
              </div>

              <div class="wheel">
                <div class="wheel-circle"></div>
                <UpOutlined @click="updatePoint('up')" class="wheel-up" />
                <DownOutlined @click="updatePoint('down')" class="wheel-down" />
                <LeftOutlined @click="updatePoint('left')" class="wheel-left" />
                <RightOutlined @click="updatePoint('right')" class="wheel-right" />
              </div>
            </div>

            <div class="color-white">
              <div class="rightHight">
                <span>海拔高度（EGM96）</span>
                <a-checkbox v-model:checked="RightForm.useGlobalHeight">跟随航线</a-checkbox>
              </div>

              <div v-if="RightForm.useGlobalHeight == false">
                <a-button class="addsubButton" @click="rightNumCount('height', 'sub', 100)" size="small">-100</a-button>
                <a-button class="addsubButton" @click="rightNumCount('height', 'sub', 10)" size="small">-10</a-button>
                <a-button class="addsubButton" @click="rightNumCount('height', 'sub', 1)" size="small">-1</a-button>
                <a-input style="width:55px;margin-left: 5px;" size="small" v-model:value="RightForm.height" :min="1"
                  :max="100" />
                m
                <a-button class="addsubButton" @click="rightNumCount('height', 'add', 1)" size="small">+1</a-button>
                <a-button class="addsubButton" @click="rightNumCount('height', 'add', 10)" size="small">+10</a-button>
                <a-button class="addsubButton" @click="rightNumCount('height', 'add', 100)" size="small">+100</a-button>
              </div>
              <div>椭球高度（EGM96）</div>
              <div>
                <a-button class="addsubButton" @click="rightNumCount('ellipsoidHeight', 'sub', 100)"
                  size="small">-100</a-button>
                <a-button class="addsubButton" @click="rightNumCount('ellipsoidHeight', 'sub', 10)"
                  size="small">-10</a-button>
                <a-button class="addsubButton" @click="rightNumCount('ellipsoidHeight', 'sub', 1)"
                  size="small">-1</a-button>
                <a-input style="width:55px;margin-left: 5px;" size="small" v-model:value="RightForm.ellipsoidHeight"
                  :min="1" :max="100" />
                m
                <a-button class="addsubButton" @click="rightNumCount('ellipsoidHeight', 'add', 1)"
                  size="small">+1</a-button>
                <a-button class="addsubButton" @click="rightNumCount('ellipsoidHeight', 'add', 10)"
                  size="small">+10</a-button>
                <a-button class="addsubButton" @click="rightNumCount('ellipsoidHeight', 'add', 100)"
                  size="small">+100</a-button>
              </div>
            </div>

            <a-form-item name="height" :labelCol="{ span: 24 }">
              <template #label>
                <div class="rightHight">
                  <div class="title-box">
                    <div class="box-ball"></div>
                    <div class="box-text">高度模式</div>
                  </div>
                  <a-checkbox v-model:checked="RightForm.useGlobalTurnParam">跟随航线</a-checkbox>
                </div>
              </template>
              <a-select v-if="RightForm.useGlobalTurnParam == false" v-model:value="RightForm.waypointTurnMode"
                placeholder="请选择">
                <a-select-option value="coordinateTurn">协调转弯，不过点，提前转弯</a-select-option>
                <a-select-option value="toPointAndStopWithDiscontinuityCurvature">直线飞行，飞行器到点停</a-select-option>
                <a-select-option value="toPointAndStopWithContinuityCurvature">曲线飞行，飞行器到点停</a-select-option>
                <a-select-option value="toPointAndPassWithContinuityCurvature">曲线飞行，飞行器过点不停</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item name="name" :labelCol="{ span: 24 }">
              <template #label>
                <div class="title-box">
                  <div class="box-ball"></div>
                  <div class="box-text">航点动作</div>
                </div>
              </template>
              <div class="pointAction">
                <div class="addButtonOut" @click="openPoint">
                  <span style="margin-left: 5px;">添加动作</span>
                  <span style="margin-right: 5px;cursor: pointer;">
                    <PlusOutlined />
                  </span>
                </div>
                <!-- 每一项 -->
                <!-- <div class="flySpeedOut" >
                              <div class="flySpeedOut-top">
                                  <div style="margin-left: 10px;">全局航线飞行速度</div>
                                  <div style="margin-right: 10px;">
                                      <a-input-number size="small" v-model:value="RightForm.gimbalPitchAngle" :min="1" :max="20" style="margin-left: 16px" />
                                      <span>°</span>
                                      <span @click="addAction = false" style="margin-left: 10px;"><DeleteOutlined /></span>
                                  </div>
                              </div>
                              <div class="flySpeedOut-body">
                                <div class="addSub" style="margin-left: 10px;">-</div>
                                <a-slider style="width: 220px;" v-model:value="inputValue1" :min="1" :max="20" />
                                <div class="addSub" style="margin-right: 10px;">+</div>
                              </div>
                          </div> -->
                <span v-if="RightForm.action.length > 0">
                  <div v-for="item in RightForm.action" :key="item" class="flySpeedOut">
                    <!-- 悬停 -->
                    <span v-if="item.actionActuatorFunc === 'hover'" style="border-bottom: 1px solid #F5F7F9;">
                      <div class="flySpeedOut-top">
                        <div class="title-box">
                          <div class="box-ball"></div>
                          <div class="box-text">{{ getActionName(item.actionActuatorFunc) }}</div>
                        </div>
                        <span @click="deleteActions(item)" style="margin-right: 10px;cursor: pointer;">
                          <DeleteOutlined />
                        </span>

                      </div>
                      <div class="flySpeedOut-body">
                        <span>悬停时间</span>
                        <div>
                          <a-input-number size="small"
                            v-model:value="RightForm.actionhover.actionActuatorFuncParam['wpml:hoverTime']" :min="1"
                            :max="3600" style="margin-left: 16px" />
                          <span>秒</span>
                        </div>
                      </div>
                      <div class="flySpeedOut-body">
                        <div class="addSub" style="margin-left: 10px;">-</div>
                        <a-slider style="width: 220px;"
                          v-model:value="RightForm.actionhover.actionActuatorFuncParam['wpml:hoverTime']" :min="1"
                          :max="3600" />
                        <div class="addSub" style="margin-right: 10px;">+</div>
                      </div>
                    </span>
                    <!-- 飞行器偏航 -->
                    <span v-if="item.actionActuatorFunc === 'rotateYaw'" style="border-bottom: 1px solid #F5F7F9;">
                      <div class="flySpeedOut-top">
                        <div class="title-box">
                          <div class="box-ball"></div>
                          <div class="box-text">{{ getActionName(item.actionActuatorFunc) }}</div>
                        </div>
                        <span @click="deleteActions(item)" style="margin-right: 10px;cursor: pointer;">
                          <DeleteOutlined />
                        </span>

                      </div>
                      <div class="flySpeedOut-body">
                        <div>飞行器偏航角转动模式</div>
                        <a-select
                          v-model:value="RightForm.actionrotateYaw.actionActuatorFuncParam['wpml:aircraftPathMode']"
                          placeholder="请选择">
                          <a-select-option value="clockwise">顺时针</a-select-option>
                          <a-select-option value="counterClockwise">逆时针</a-select-option>
                        </a-select>
                      </div>
                      <div class="flySpeedOut-body">
                        <span>飞行器目标偏航角</span>
                        <div>
                          <a-input-number size="small"
                            v-model:value="RightForm.actionrotateYaw.actionActuatorFuncParam['wpml:aircraftHeading']"
                            :min="-180" :max="180" style="margin-left: 16px" />
                          <span>°</span>
                        </div>
                      </div>
                    </span>
                    <!-- 旋转云台 -->
                    <span v-if="item.actionActuatorFunc === 'gimbalRotate'" style="border-bottom: 1px solid #F5F7F9;">
                      <div class="flySpeedOut-top">
                        <div class="title-box">
                          <div class="box-ball"></div>
                          <div class="box-text">{{ getActionName(item.actionActuatorFunc) }}</div>
                        </div>
                        <span @click="deleteActions(item)" style="margin-right: 10px;cursor: pointer;">
                          <DeleteOutlined />
                        </span>

                      </div>
                      <div class="flySpeedOut-body">
                        <div>云台转动模式</div>
                        <a-select
                          v-model:value="RightForm.actiongimbalRotate.actionActuatorFuncParam['wpml:gimbalRotateMode']"
                          placeholder="请选择">
                          <a-select-option value="relativeAngle">相对角度</a-select-option>
                          <a-select-option value="absoluteAngle">绝对角度</a-select-option>
                        </a-select>
                      </div>
                      <div class="flySpeedOut-body">
                        <span>负载挂载位置</span>
                        <a-select
                          v-model:value="RightForm.actiongimbalRotate.actionActuatorFuncParam['wpml:payloadPositionIndex']"
                          placeholder="请选择">
                          <a-select-option value="0">飞行器1号挂载位置</a-select-option>
                          <a-select-option value="1">飞行器2号挂载位置</a-select-option>
                          <a-select-option value="2">飞行器3号挂载位置</a-select-option>
                        </a-select>
                      </div>
                    </span>
                    <!-- 拍照 -->
                    <span v-if="item.actionActuatorFunc === 'takePhoto'" style="border-bottom: 1px solid #F5F7F9;">
                      <div class="flySpeedOut-top">
                        <div class="title-box">
                          <div class="box-ball"></div>
                          <div class="box-text">{{ getActionName(item.actionActuatorFunc) }}</div>
                        </div>
                        <span @click="deleteActions(item)" style="margin-right: 10px;cursor: pointer;">
                          <DeleteOutlined />
                        </span>

                      </div>
                      <div class="flySpeedOut-body">
                        <div>拍摄照片文件后缀</div>
                        <a-input v-model:value="RightForm.actiontakePhoto.actionActuatorFuncParam['wpml:fileSuffix']" />
                      </div>
                      <div class="flySpeedOut-body">
                        <span>负载挂载位置</span>
                        <a-select
                          v-model:value="RightForm.actiontakePhoto.actionActuatorFuncParam['wpml:payloadPositionIndex']"
                          placeholder="请选择">
                          <a-select-option value="0">飞行器1号挂载位置</a-select-option>
                          <a-select-option value="1">飞行器2号挂载位置</a-select-option>
                          <a-select-option value="2">飞行器3号挂载位置</a-select-option>
                        </a-select>
                      </div>
                      <div class="flySpeedOut-body">
                        <span>拍摄照片存储类型</span>
                        <a-select
                          v-model:value="RightForm.actiontakePhoto.actionActuatorFuncParam['wpml:payloadLensIndex']"
                          placeholder="请选择">
                          <a-select-option value="zoom">存储变焦镜头拍摄照片</a-select-option>
                          <a-select-option value="wide">存储广角镜头拍摄照片</a-select-option>
                          <a-select-option value="ir">存储红外镜头拍摄照片</a-select-option>
                          <a-select-option value="narrow_band">存储窄带镜头拍摄照片</a-select-option>
                        </a-select>
                      </div>
                    </span>
                    <!-- 开始录像 -->
                    <span v-if="item.actionActuatorFunc === 'startRecord'" style="border-bottom: 1px solid #F5F7F9;">
                      <div class="flySpeedOut-top">
                        <div class="title-box">
                          <div class="box-ball"></div>
                          <div class="box-text">{{ getActionName(item.actionActuatorFunc) }}</div>
                        </div>
                        <span @click="deleteActions(item)" style="margin-right: 10px;cursor: pointer;">
                          <DeleteOutlined />
                        </span>

                      </div>
                      <div class="flySpeedOut-body">
                        <div>拍摄照片文件后缀</div>
                        <a-input v-model:value="RightForm.actionstartRecord.actionActuatorFuncParam['wpml:fileSuffix']" />
                      </div>
                      <div class="flySpeedOut-body">
                        <span>负载挂载位置</span>
                        <a-select
                          v-model:value="RightForm.actionstartRecord.actionActuatorFuncParam['wpml:payloadPositionIndex']"
                          placeholder="请选择">
                          <a-select-option value="0">飞行器1号挂载位置</a-select-option>
                          <a-select-option value="1">飞行器2号挂载位置</a-select-option>
                          <a-select-option value="2">飞行器3号挂载位置</a-select-option>
                        </a-select>
                      </div>
                      <div class="flySpeedOut-body">
                        <span>拍摄照片存储类型</span>
                        <a-select
                          v-model:value="RightForm.actionstartRecord.actionActuatorFuncParam['wpml:payloadLensIndex']"
                          placeholder="请选择">
                          <a-select-option value="zoom">存储变焦镜头拍摄照片</a-select-option>
                          <a-select-option value="wide">存储广角镜头拍摄照片</a-select-option>
                          <a-select-option value="ir">存储红外镜头拍摄照片</a-select-option>
                          <a-select-option value="narrow_band">存储窄带镜头拍摄照片</a-select-option>
                        </a-select>
                      </div>
                    </span>
                    <!-- 结束录像 -->
                    <span v-if="item.actionActuatorFunc === 'stopRecord'" style="border-bottom: 1px solid #F5F7F9;">
                      <div class="flySpeedOut-top">
                        <div class="title-box">
                          <div class="box-ball"></div>
                          <div class="box-text">{{ getActionName(item.actionActuatorFunc) }}</div>
                        </div>
                        <span @click="deleteActions(item)" style="margin-right: 10px;cursor: pointer;">
                          <DeleteOutlined />
                        </span>

                      </div>
                      <div class="flySpeedOut-body">
                        <span>负载挂载位置</span>
                        <a-select
                          v-model:value="RightForm.actionstopRecord.actionActuatorFuncParam['wpml:payloadPositionIndex']"
                          placeholder="请选择">
                          <a-select-option value="0">飞行器1号挂载位置</a-select-option>
                          <a-select-option value="1">飞行器2号挂载位置</a-select-option>
                          <a-select-option value="2">飞行器3号挂载位置</a-select-option>
                        </a-select>
                      </div>
                      <div class="flySpeedOut-body">
                        <span>拍摄照片存储类型</span>
                        <a-select
                          v-model:value="RightForm.actionstopRecord.actionActuatorFuncParam['wpml:payloadLensIndex']"
                          placeholder="请选择">
                          <a-select-option value="zoom">存储变焦镜头拍摄照片</a-select-option>
                          <a-select-option value="wide">存储广角镜头拍摄照片</a-select-option>
                          <a-select-option value="ir">存储红外镜头拍摄照片</a-select-option>
                          <a-select-option value="narrow_band">存储窄带镜头拍摄照片</a-select-option>
                        </a-select>
                      </div>
                    </span>
                    <!-- 对焦 -->
                    <span v-if="item.actionActuatorFunc === 'focus'" style="border-bottom: 1px solid #F5F7F9;">
                      <div class="flySpeedOut-top">
                        <div class="title-box">
                          <div class="box-ball"></div>
                          <div class="box-text">{{ getActionName(item.actionActuatorFunc) }}</div>
                        </div>
                        <span @click="deleteActions(item)" style="margin-right: 10px;cursor: pointer;">
                          <DeleteOutlined />
                        </span>

                      </div>
                      <div class="flySpeedOut-body">
                        <span>负载挂载位置</span>
                        <a-select
                          v-model:value="RightForm.actionfocus.actionActuatorFuncParam['wpml:payloadPositionIndex']"
                          placeholder="请选择">
                          <a-select-option value="0">飞行器1号挂载位置</a-select-option>
                          <a-select-option value="1">飞行器2号挂载位置</a-select-option>
                          <a-select-option value="2">飞行器3号挂载位置</a-select-option>
                        </a-select>
                      </div>
                    </span>
                    <!-- 变焦 -->
                    <span v-if="item.actionActuatorFunc === 'zoom'" style="border-bottom: 1px solid #F5F7F9;">
                      <div class="flySpeedOut-top">
                        <div class="title-box">
                          <div class="box-ball"></div>
                          <div class="box-text">{{ getActionName(item.actionActuatorFunc) }}</div>
                        </div>
                        <span @click="deleteActions(item)" style="margin-right: 10px;cursor: pointer;">
                          <DeleteOutlined />
                        </span>

                      </div>
                      <div class="flySpeedOut-body">
                        <span>负载挂载位置</span>
                        <a-select
                          v-model:value="RightForm.actionzoom.actionActuatorFuncParam['wpml:payloadPositionIndex']"
                          placeholder="请选择">
                          <a-select-option value="0">飞行器1号挂载位置</a-select-option>
                          <a-select-option value="1">飞行器2号挂载位置</a-select-option>
                          <a-select-option value="2">飞行器3号挂载位置</a-select-option>
                        </a-select>
                      </div>
                      <div class="flySpeedOut-body">
                        <span>变焦焦距</span>
                        <div>
                          <a-input-number size="small"
                            v-model:value="RightForm.actionzoom.actionActuatorFuncParam['wpml:actionzoom']" :min="0"
                            style="margin-left: 16px" />
                          <span>mm</span>
                        </div>
                      </div>
                    </span>
                    <!-- 新文件夹 -->
                    <span v-if="item.actionActuatorFunc === 'customDirName'" style="border-bottom: 1px solid #F5F7F9;">
                      <div class="flySpeedOut-top">
                        <div class="title-box">
                          <div class="box-ball"></div>
                          <div class="box-text">{{ getActionName(item.actionActuatorFunc) }}</div>
                        </div>
                        <span @click="deleteActions(item)" style="margin-right: 10px;cursor: pointer;">
                          <DeleteOutlined />
                        </span>

                      </div>
                      <div class="flySpeedOut-body">
                        <span>负载挂载位置</span>
                        <a-select
                          v-model:value="RightForm.actioncustomDirName.actionActuatorFuncParam['wpml:payloadPositionIndex']"
                          placeholder="请选择">
                          <a-select-option value="0">飞行器1号挂载位置</a-select-option>
                          <a-select-option value="1">飞行器2号挂载位置</a-select-option>
                          <a-select-option value="2">飞行器3号挂载位置</a-select-option>
                        </a-select>
                      </div>
                      <div class="flySpeedOut-body">
                        <span>文件名称</span>
                        <a-input size="small"
                          v-model:value="RightForm.actioncustomDirName.actionActuatorFuncParam['wpml:directoryName']"
                          style="margin-left: 16px" />

                      </div>
                    </span>

                  </div>
                </span>

              </div>
            </a-form-item>
            <a-form-item name="name" :labelCol="{ span: 24 }">
              <template #label>
                <div class="rightHight">
                  <div class="title-box">
                    <div class="box-ball"></div>
                    <div class="box-text">飞行器偏航角模式</div>
                  </div>
                  <a-checkbox v-model:checked="RightForm.useGlobalHeadingParam">跟随航线</a-checkbox>
                </div>
              </template>
              <a-select v-if="RightForm.useGlobalHeadingParam == false" v-model:value="RightForm.waypointHeadingMode"
                placeholder="请选择">
                <a-select-option value="followWayline">沿航线方向</a-select-option>
                <a-select-option value="manually">手动控制</a-select-option>
                <a-select-option value="fixed">锁定当前偏航角</a-select-option>
                <a-select-option value="smoothTransition">自定义</a-select-option>
                <!-- <a-select-option value="towardPOI">朝向兴趣点</a-select-option> -->
              </a-select>
            </a-form-item>
            <a-form-item v-if="RightForm.waypointHeadingMode == 'smoothTransition'" label="飞行器偏航角度" name="name"
              :labelCol="{ span: 24 }">
              <a-input style="background: black;" placeholder="偏航角度" v-model:value="RightForm.waypointHeadingAngle" />
            </a-form-item>
            <a-form-item v-if="RightForm.waypointHeadingMode == 'towardPOI'" label="飞行器偏航角度" name="name"
              :labelCol="{ span: 24 }">
              <!-- 朝兴趣点,应该是个坐标 -->
            </a-form-item>
            <a-form-item v-if="RightForm.useGlobalHeadingParam == false" name="name" :labelCol="{ span: 24 }">
              <template #label>
                <div class="title-box">
                  <div class="box-ball"></div>
                  <div class="box-text">飞行器偏航角转动方向</div>
                </div>
              </template>
              <a-select v-model:value="RightForm.waypointHeadingPathMode" placeholder="请选择">
                <a-select-option value="clockwise">顺时针旋转飞行器偏航角</a-select-option>
                <a-select-option value="counterClockwise">逆时针旋转飞行器偏航角</a-select-option>
                <a-select-option value="followBadArc">沿最短路径旋转飞行器偏航角</a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item name="name" :labelCol="{ span: 24 }">
              <template #label>
                <div class="rightHightSpeed">

                  <div class="title-box">
                    <div class="box-ball"></div>
                    <div class="box-text">飞行速度</div>
                  </div>

                  <div class="rightHightSpeed-right">
                    <a-checkbox v-model:checked="RightForm.useGlobalSpeed">跟随航线</a-checkbox>
                    <div v-if="RightForm.useGlobalSpeed == false">
                      <a-input style="width:55px;margin-left: 5px;" size="small" v-model:value="RightForm.waypointSpeed"
                        :min="1" :max="15" />
                      <span style="padding-right: 10px;"> m/s</span>
                    </div>
                  </div>

                </div>
              </template>
              <div v-if="RightForm.useGlobalSpeed == false" class="flySpeed-body">
                <div class="addSub" @click="rightNumCount('waypointSpeed', 'sub', 1)" style="margin-left: 10px;">-</div>
                <a-slider style="width: 220px;" v-model:value="RightForm.waypointSpeed" :min="1" :max="15" />
                <div class="addSub" @click="rightNumCount('waypointSpeed', 'add', 1)" style="margin-right: 10px;">+</div>
              </div>
            </a-form-item>
            <a-form-item v-if="false" name="name" :labelCol="{ span: 24 }">
              <template #label>
                <div class="rightHightSpeed">
                  <span>云台俯仰角</span>
                  <div class="rightHightSpeed-right">
                    <div>
                      <a-input style="width:55px;background-color: #7e7e7e;margin-left: 5px;" size="small"
                        v-model:value="RightForm.gimbalPitchAngle" :min="1" :max="100" />
                      <span> °</span>
                    </div>
                  </div>

                </div>
              </template>
              <div class="flySpeed-body">
                <div class="addSub" @click="rightNumCount('gimbalPitchAngle', 'sub', 1)" style="margin-left: 10px;">-
                </div>
                <a-slider style="width: 220px;" v-model:value="RightForm.gimbalPitchAngle" :min="1" :max="20" />
                <div class="addSub" @click="rightNumCount('gimbalPitchAngle', 'add', 1)" style="margin-right: 10px;">+
                </div>
              </div>
            </a-form-item>

          </a-form>
          <div>
            <a-button @click="comfirmPoint" style="margin-left: 10px;">确定航点</a-button>
            <a-button @click="onaddWayline" style="margin-left: 10px;">创建航线</a-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 动作 -->
  <div v-if="pointDrawer" class="pointDiv">
    <div class="pointDiv-header">
      <div class="header-titles" style="padding-left:15px">添加动作</div>
      <div class="header-icon" @click="closeAction">
        <CloseOutlined />
      </div>
    </div>
    <div class="pointDiv-body">
      <div class="body-main">
        <div class="color-white">飞行器动作</div>
        <div class="addButton" @click="addActions('hover')">
          <span style="margin-left: 5px;">悬停</span>
          <span style="margin-right: 5px;">
            <PlusOutlined />
          </span>
        </div>
        <div class="addButton" @click="addActions('rotateYaw')">
          <span style="margin-left: 5px;">飞行器偏航</span>
          <span style="margin-right: 5px;">
            <PlusOutlined />
          </span>
        </div>

        <div class="color-white">云台动作</div>
        <div class="addButton" @click="addActions('gimbalRotate')">
          <span style="margin-left: 5px;">旋转云台</span>
          <span style="margin-right: 5px;">
            <PlusOutlined />
          </span>
        </div>
        <!-- <div class="addButton" @click="addActions('云台俯仰角')">
              <span style="margin-left: 5px;">云台俯仰角</span>
              <span style="margin-right: 5px;"><PlusOutlined /></span>
            </div> -->

        <div class="color-white">负载动作</div>
        <div class="addButton" @click="addActions('takePhoto')">
          <span style="margin-left: 5px;">拍照</span>
          <span style="margin-right: 5px;">
            <PlusOutlined />
          </span>
        </div>
        <div class="addButton" @click="addActions('startRecord')">
          <span style="margin-left: 5px;">开始录像</span>
          <span style="margin-right: 5px;">
            <PlusOutlined />
          </span>
        </div>
        <div class="addButton" @click="addActions('stopRecord')">
          <span style="margin-left: 5px;">停止录像</span>
          <span style="margin-right: 5px;">
            <PlusOutlined />
          </span>
        </div>
        <div class="addButton" @click="addActions('zoom')">
          <span style="margin-left: 5px;">相机变焦</span>
          <span style="margin-right: 5px;">
            <PlusOutlined />
          </span>
        </div>
        <div class="addButton" @click="addActions('customDirName')">
          <span style="margin-left: 5px;">创建文件夹</span>
          <span style="margin-right: 5px;">
            <PlusOutlined />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { message } from 'ant-design-vue'
import { onMounted, ref, onBeforeUnmount } from 'vue'
import { CloseOutlined, PlusOutlined, DeleteOutlined, UpOutlined, DownOutlined, CameraFilled, UserOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons-vue'
import { addWayline, detailWayline, getWaylineMSG, deleteWaylineFile, downloadWaylineFile, getWaylineFiles } from '/@/api/wayline'
import { ERouterName } from '/@/types'
import { useMyStore } from '/@/store'
import { getBindingDevices } from '/@/api/manage'
import { IPage } from '/@/api/http/type'
import { RuleObject } from 'ant-design-vue/es/form/interface'
import { getRoot } from '/@/root'
import { ELocalStorageKey } from '/@/types/enums'
import router from '/@/router'
import marker1 from '/@/assets/icons/marker1.png'
import bus from '/@/utils/mitt'
const workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId)!

const store = useMyStore()
const root = getRoot()
const valueRef = ref()
const disabled = ref(false)
const checked = ref(false)
const pointDrawer = ref(false)

const map = root.$map
const Form = ref({
  author: '默认名称',
  executeHeightMode: '', // 执行高度模式 way
  globalHeight: 120, // 海拔 tem
  ellipsoidHeight: 0, // 航点高度（WGS84椭球高度） tem

  autoFlightSpeed: 1, // 全局航线飞行速度 1-15

  waypointHeadingMode: '', // 飞行器偏航角模式
  waypointHeadingAngle: '', // 偏航角模式 自定义
  waypointPoiPoint: '', // 偏航角模式 兴趣点
  waypointHeadingPathMode: '', // 飞行器偏航角转动方向（枚举）

  gimbalPitchMode: '', // 云台俯仰角控制模式 tem

  globalWaypointTurnMode: '', // 全局航点类型  tem
  globalUseStraightLine: '', // 全局航段轨迹是否尽量贴合直线  tem

  globalRTHHeight: 100, // 返航高度 非必须 miss

  finishAction: '', // 航线结束动作      miss
  exitOnRCLost: '', // 失控是否继续执行航线 miss
  executeRCLostAction: '', // 失控动作类型 miss

})
const RightForm = ref({ // <Placemark>航点信息
  index: 0,
  Point: {
    longitude: 0,
    latitude: 0
  },
  height: 1, // 航点高度
  ellipsoidHeight: 0, // 航点椭球高度
  waypointTurnMode: 'toPointAndStopWithDiscontinuityCurvature', // 航点类型
  waypointHeadingMode: 'followWayline', // 飞行器偏航角模式
  mappingHeadingAngle: '', // 飞行器偏航角 0-360
  waypointSpeed: 1, // 航点飞行速度
  useGlobalHeight: false, // 是否使用全局高度
  useGlobalTurnParam: false, // 是否使用全局航点类型（全局航点转弯模式）
  useGlobalHeadingParam: false, // 是否使用全局偏航角模式参数
  waypointHeadingAngle: 0, // 飞行器偏航角度
  useGlobalSpeed: false, // 是否全局速度
  waypointHeadingPathMode: 'followBadArc', // 飞行器偏航角转动方向
  action: [],

  actionhover: { // hover悬停
    actionActuatorFunc: '',
    actionId: '',
    actionActuatorFuncParam: {
      'wpml:hoverTime': ''
    }
  },
  actionrotateYaw: { // rotateYaw飞行器偏航
    actionActuatorFunc: '',
    actionId: '',
    actionActuatorFuncParam: {
      'wpml:aircraftHeading': '', // 飞行器目标偏航角 -180 180
      'wpml:aircraftPathMode': ''
    }
  },
  actiongimbalRotate: { // rotateYaw飞行器偏航
    actionActuatorFunc: '',
    actionId: '',
    actionActuatorFuncParam: {
      'wpml:gimbalRotateTime': 0,
      'wpml:gimbalHeadingYawBase': 'north',
      'wpml:gimbalRotateTimeEnable': 0,
      'wpml:gimbalRollRotateEnable': 0,
      'wpml:payloadPositionIndex': 0,
      'wpml:gimbalRollRotateAngle': 0,
      'wpml:gimbalRotateMode': 'absoluteAngle',
      'wpml:gimbalPitchRotateAngle': 0,
      'wpml:gimbalPitchRotateEnable': 0,
      'wpml:gimbalYawRotateEnable': 1,
      'wpml:gimbalYawRotateAngle': -136.1
    }
  },
  actiontakePhoto: { // rotateYaw飞行器偏航
    actionActuatorFunc: '',
    actionId: '',
    actionActuatorFuncParam: {
      'wpml:payloadPositionIndex': '',
      'wpml:fileSuffix': '',
      'wpml:payloadLensIndex': '',
      'wpml:useGlobalPayloadLensIndex': 1
    }
  },
  actionstartRecord: { // rotateYaw飞行器偏航
    actionActuatorFunc: '',
    actionId: '',
    actionActuatorFuncParam: {
      'wpml:payloadPositionIndex': '',
      'wpml:fileSuffix': '',
      'wpml:payloadLensIndex': '',
      'wpml:useGlobalPayloadLensIndex': 1
    }
  },
  actionstopRecord: { // rotateYaw飞行器偏航
    actionActuatorFunc: '',
    actionId: '',
    actionActuatorFuncParam: {
      'wpml:payloadPositionIndex': '',
      'wpml:payloadLensIndex': '',
    }
  },
  actionfocus: { // rotateYaw飞行器偏航
    actionActuatorFunc: '',
    actionId: '',
    actionActuatorFuncParam: {
      'wpml:payloadPositionIndex': '',
      'wpml:isPointFocus': 1, // 是否点对焦
      'wpml:focusX': 0,
      'wpml:focusY': 0,
      'wpml:focusRegionWidth': 0,
      'wpml:focusRegionHeight': 0,
      'wpml:isInfiniteFocus': 0,
    }
  },
  actionzoom: { // rotateYaw飞行器偏航
    actionActuatorFunc: '',
    actionId: '',
    actionActuatorFuncParam: {
      'wpml:payloadPositionIndex': '',
      'wpml:actionzoom': 0,
    }
  },
  actioncustomDirName: { // rotateYaw飞行器偏航
    actionActuatorFunc: '',
    actionId: '',
    actionActuatorFuncParam: {
      'wpml:payloadPositionIndex': '',
      'wpml:directoryName': '',
    }
  },
})

const lastForm = {
  'wpml:author': Form.value.author,
  'wpml:createTime': Date.now(),
  'wpml:updateTime': Date.now(),
  'wpml:missionConfig': {
    'wpml:flyToWaylineMode': 'safely',
    'wpml:finishAction': Form.value.finishAction, // 航线结束动作
    'wpml:exitOnRCLost': Form.value.exitOnRCLost, // 航线飞行中失控
    'wpml:executeRCLostAction': Form.value.executeRCLostAction, // 失控动作
    'wpml:takeOffSecurityHeight': 1.5, // 安全起飞高度
    'wpml:takeOffRefPoint': '27,120,20',
    'wpml:takeOffRefPointAGLHeight': 1.5,
    'wpml:globalTransitionalSpeed': 15, // 全局航线过渡速度
    'wpml:globalRTHHeight': Number(Form.value.globalRTHHeight), // 返航高度
    'wpml:droneInfo': {
      'wpml:droneEnumValue': 67,
      'wpml:droneSubEnumValue': 0
    },
    'wpml:payloadInfo': {
      'wpml:payloadEnumValue': 52,
      'wpml:payloadSubEnumValue': 0,
      'wpml:payloadPositionIndex': 0
    }
  },
  template: {
    Folder: {
      'wpml:templateType': 'waypoint',
      'wpml:templateId': '0',
      'wpml:waylineCoordinateSysParam': {
        'wpml:coordinateMode': 'WGS84',
        'wpml:heightMode': 'EGM96'
      },
      // 'wpml:mappingHeadingParam': {
      //   'wpml:mappingHeadingMode': Form.mappingHeadingMode, // 飞行器偏航角模式
      //   'wpml:mappingHeadingAngle': Form.mappingHeadingAngle// 飞行器偏航角
      // },
      'wpml:autoFlightSpeed': Form.value.autoFlightSpeed, // 全局航线飞行速度
      'wpml:globalHeight': Form.value.globalHeight, // 全局航线高度
      'wpml:caliFlightEnable': '0',
      'wpml:gimbalPitchMode': Form.value.gimbalPitchMode, // 航点间云台俯仰角控制模式

      'wpml:waypointHeadingParam': { // 当useGlobalHeadingParam为0
        'wpml:waypointHeadingPathMode': RightForm.value.waypointHeadingPathMode, // 飞行器偏航角转动方向
        'wpml:waypointHeadingPoiIndex': 0,
        'wpml:waypointHeadingAngle': RightForm.value.waypointHeadingAngle, // 偏航角度
        'wpml:waypointPoiPoint': '0.000000,0.000000,0.000000',
        'wpml:waypointHeadingMode': RightForm.value.waypointHeadingMode // 飞行器偏航角模式
      },

      'wpml:globalWaypointTurnMode': Form.value.globalWaypointTurnMode, // 全局航点类型（全局航点转弯模式）
      'wpml:globalUseStraightLine': Form.value.globalUseStraightLine, // 全局航段轨迹是否尽量贴合直线
      'wpml:payloadParam': {
        'wpml:payloadPositionIndex': '0',
        'wpml:focusMode': 'firstPoint',
        'wpml:meteringMode': 'average',
        'wpml:returnMode': 'singleReturnFirst',
        'wpml:samplingRate': '240000',
        'wpml:scanningMode': 'repetitive',
        'wpml:imageFormat': 'wide,zoom'
      }
    },
    Placemark: [

    ]
  },
  waylines: {
    Folder: {
      'wpml:templateId': 0,
      'wpml:executeHeightMode': Form.value.executeHeightMode, // 执行高度模式
      'wpml:waylineId': 0,
      'wpml:distance': 1107.31262207031,
      'wpml:duration': 129.730501651764,
      'wpml:autoFlightSpeed': Form.value.autoFlightSpeed, // 全局航线飞行速度
    },
    Placemark: [

    ]
  }
}
const rules = {
  name: [
    { required: true, message: '请输入计划名称.' },
    { max: 20, message: '长度限定为1到20', trigger: 'blur' }
  ],
  file_id: [{ required: true, message: '选择路线' }],
  dock_sn: [{ required: true, message: '选择设备' }],
  select_execute_time: [{ required: true, message: '选择开始时间' }],
  rth_altitude: [
    {
      validator: async (rule: RuleObject, value: string) => {
        if (!/^[0-9]{1,}$/.test(value)) {
          throw new Error('RTH 相对高度需要填写数字')
        }
      },
    }
  ],
  out_of_control_action: [{ required: true, message: '选择失联操作' }],
}

// 添加动作相关
// const addAction = ref(false)
const getActionName = (name) => {
  switch (name) {
    case 'hover':
      return '悬停'
    case 'rotateYaw':
      return '飞行器偏航'
    case 'gimbalRotate':
      return '旋转云台'
    case 'customDirName':
      return '创建新文件夹'
    case 'zoom':
      return '相机变焦'
    case 'focus':
      return '相机对焦'
    case 'stopRecord':
      return '结束录像'
    case 'startRecord':
      return '开始录像'
    case 'takePhoto':
      return '拍照'
  }
}
const getActionParmars = (items) => {
  switch (items['wpml:actionActuatorFunc']) {
    case 'hover':
      return {
        'wpml:payloadPositionIndex': 123
      }
    case 'rotateYaw':
      return '飞行器偏航'
    case 'gimbalRotate':
      return '旋转云台'
    case 'customDirName':
      return '创建新文件夹'
    case 'zoom':
      return '相机变焦'
    case 'focus':
      return '相机对焦'
    case 'stopRecord':
      return '结束录像'
    case 'startRecord':
      return '开始录像'
    case 'takePhoto':
      return {
        'wpml:payloadPositionIndex': items['wpml:actionActuatorFuncParam'].payloadPositionIndex// 负载挂载位置012
      }
  }
}
const addActions = (name: string) => {
  const found = RightForm.value.action.find(item => item.actionActuatorFunc === name)
  if (!found) {
    switch (name) {
      case 'hover':
        RightForm.value.action.push({
          actionActuatorFunc: name,
          actionId: '',
          actionActuatorFuncParam: {
            hoverTime: '',
          }
        })
        break
      case 'rotateYaw':
        RightForm.value.action.push({
          actionActuatorFunc: name,
          actionId: '',
          actionActuatorFuncParam: {
            aircraftHeading: '', // 飞行器目标偏航角 -180 180
            aircraftPathMode: ''
          }
        })
        break
      case 'gimbalRotate':
        RightForm.value.action.push({
          actionActuatorFunc: name,
          actionId: '',
          actionActuatorFuncParam: {
            gimbalRotateTime: 0,
            gimbalHeadingYawBase: 'north',
            gimbalRotateTimeEnable: 0,
            gimbalRollRotateEnable: 0,
            payloadPositionIndex: 0, // 负载挂载位置
            gimbalRollRotateAngle: 0,
            gimbalRotateMode: 'absoluteAngle', // 云台转动模式
            gimbalPitchRotateAngle: 0,
            gimbalPitchRotateEnable: 0,
            gimbalYawRotateEnable: 1,
            gimbalYawRotateAngle: -136.1
          }
        })
        break
      case 'takePhoto':
        RightForm.value.action.push({
          actionActuatorFunc: name,
          actionId: '',
          actionActuatorFuncParam: {
            payloadPositionIndex: 0,
            fileSuffix: '',
            payloadLensIndex: '',
            useGlobalPayloadLensIndex: 1
          }
        })
        break
      case 'startRecord':
        RightForm.value.action.push({
          actionActuatorFunc: name,
          actionId: '',
          actionActuatorFuncParam: {
            payloadPositionIndex: 0,
            fileSuffix: '',
            payloadLensIndex: '',
            useGlobalPayloadLensIndex: 1
          }
        })
        break
      case 'stopRecord':
        RightForm.value.action.push({
          actionActuatorFunc: name,
          actionId: '',
          actionActuatorFuncParam: {
            payloadPositionIndex: 0,
            payloadLensIndex: '',
          }
        })
        break
      case 'focus':
        RightForm.value.action.push({
          actionActuatorFunc: name,
          actionId: '',
          actionActuatorFuncParam: {
            payloadPositionIndex: 0,
            isPointFocus: 1, // 是否点对焦
            focusX: 0,
            focusY: 0,
            focusRegionWidth: 0,
            focusRegionHeight: 0,
            isInfiniteFocus: 0,
          }
        })
        break
      case 'zoom':
        RightForm.value.action.push({
          actionActuatorFunc: name,
          actionId: '',
          actionActuatorFuncParam: {
            payloadPositionIndex: 0,
            actionzoom: 0,
          }
        })
        break
      case 'customDirName':
        RightForm.value.action.push({
          actionActuatorFunc: name,
          actionId: '',
          actionActuatorFuncParam: {
            payloadPositionIndex: 0,
            directoryName: 0,
          }
        })
        break
    }
  }
}
const deleteActions = (RightItem) => {
  const index = RightForm.value.action.findIndex(item => item === RightItem)
  if (index !== -1) {
    RightForm.value.action.splice(index, 1)
  }
}

const openPoint = () => {
  store.commit('SET_WAYLINE_VISIBLE', '2')
  pointDrawer.value = true
}
const closeDrawer = () => {
  store.commit('SET_WAYLINE_VISIBLE', null)
  store.commit('CLEAR_ALLPOINT')
  router.push('/wayline')
}
const closeAction = () => {
  store.commit('SET_WAYLINE_VISIBLE', '1')
  pointDrawer.value = false
}
const addForm = async () => {
  console.log('发送表单')
}
function closePlan() {
  root.$router.push('/' + ERouterName.WAYLINE)
}
function onSubmit() {
  valueRef.value.validate().then(() => {
    disabled.value = true

    addForm().then(res => {
      setTimeout(() => {
        disabled.value = false
      }, 1500)
    }).finally(() => {
      closePlan()
    })
  }).catch((e: any) => {
    console.log('validate err', e)
  })
}

// 加减法
function rightNumCount(val: string, type: string, num: number) {
  switch (type) {
    case 'add':
      console.log('加')
      RightForm.value[val] += num
      console.log(Form.value.globalHeight)
      break
    case 'sub':
      console.log('减')
      RightForm.value[val] -= num
      break
    default:
      break
  }
}

// 新建航线
async function onaddWayline() {
  // console.log(store.state.mapPoint, '获取地图标点')
  store.state.waylinePoints.forEach((item) => {
    lastForm.template.Placemark.push((dealTem(item)))
    lastForm.waylines.Placemark.push((dealWay(item)))
  })
  // 处理三个文件
  dealMiss()
  wayFolder()
  temFolder()

  console.log(lastForm, '最后的Form')

  const res = await addWayline(workspaceId, JSON.stringify(lastForm))
  console.log(res, 'resres')
  // 下载文件流
  const blob = new Blob([res], {
    // 下载的文件类型(此处可更改：具体取值参考以下链接地址)
    type: 'application/vnd.google-earth.kmz'
  })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.style.display = 'none'
  link.download = `${Form.value.author}.kmz`
  link.href = url
  document.body.appendChild(link)
  link.click()
}
// 处理templacemark
function dealTem(item: any) {
  const dealobj = {
    Point: {
      coordinates: `${item.Point.longitude},${item.Point.latitude}`// 航点经纬度
    },
    'wpml:index': item.index,
    'wpml:useGlobalHeight': item.useGlobalHeight ? 1 : 0, // 是否全局高度
    'wpml:height': Number(item.height), // 点海拔高度
    'wpml:ellipsoidHeight': Number(item.ellipsoidHeight), // 点椭球高度

    'wpml:useGlobalTurnParam': item.useGlobalTurnParam ? 1 : 0, // 是否使用全局航点类型（全局航点转弯模式）
    'wpml:useStraightLine': 1, // 航线是否贴合直线
    'wpml:waypointTurnParam': { // 航点类型（航点转弯模式）
      'wpml:waypointTurnDampingDist': 0.2, // 使用coordinateTurn必须
      'wpml:waypointTurnMode': item.waypointTurnMode // 类型
    },

    'wpml:useGlobalHeadingParam': item.useGlobalHeadingParam ? 1 : 0, // 是否使用全局偏航角模式参数
    'wpml:waypointHeadingParam': {
      'wpml:waypointHeadingPathMode': item.waypointHeadingPathMode, // 飞行器偏航角转动方向
      'wpml:waypointHeadingAngle': item.waypointHeadingAngle, // 偏航角度
      'wpml:waypointPoiPoint': '0.000000,0.000000,0.000000',
      'wpml:waypointHeadingMode': item.waypointHeadingMode, // 飞行器偏航角模式
      'wpml:waypointHeadingPoiIndex': 0, // ???
    },

    'wpml:useGlobalSpeed': item.useGlobalSpeed ? 1 : 0, // 是否使用全局速度
    'wpml:waypointSpeed': Number(item.waypointSpeed), // 航点飞行速度

    'wpml:actionGroup': { // 动作组
      'wpml:actionGroupStartIndex': item.index, // "动作组开始生效的航点"
      'wpml:actionGroupEndIndex': item.index, // "动作组结束生效的航点"
      'wpml:action': [ // 动作列表，一个对象为一组
      ],
      'wpml:actionGroupMode': 'sequence', // "动作执行模式，串行执行"
      'wpml:actionGroupId': item.index, // "动作组id，一个kmz文件内唯一"
      'wpml:actionTrigger': { // "动作触发器"
        'wpml:actionTriggerType': 'reachPoint'
        //  "reachPoint", 动作触发器类型，到达航点触发、航段触发、定时触发、定距触发
        // "betweenAdjacentPoints",
        // "multipleTiming",
        // "multipleDistance"
      }
    },
    'wpml:mappingHeadingParam': { // 建图航拍飞行器朝向参数
      'wpml:mappingHeadingMode': 'followWayline', // 飞行器偏航角模式
      'wpml:mappingHeadingAngle': 0, // 飞行器偏航角 0-360
    },
  }
  dealobj['wpml:actionGroup']['wpml:action'] = []
  item.action.forEach((items: any, i: number) => {
    switch (items.actionActuatorFunc) {
      case 'takePhoto':
        dealobj['wpml:actionGroup']['wpml:action'].push(
          {
            'wpml:actionActuatorFunc': items.actionActuatorFunc,
            'wpml:actionId': i, // "动作id，动作组内唯一"
            'wpml:actionActuatorFuncParam': { ...RightForm.value.actiontakePhoto.actionActuatorFuncParam }
          })
        break
      case 'startRecord':
        dealobj['wpml:actionGroup']['wpml:action'].push(
          {
            'wpml:actionActuatorFunc': items.actionActuatorFunc,
            'wpml:actionId': i, // "动作id，动作组内唯一"
            'wpml:actionActuatorFuncParam': { ...RightForm.value.actionstartRecord.actionActuatorFuncParam }
          })
        break
      case 'stopRecord':
        dealobj['wpml:actionGroup']['wpml:action'].push(
          {
            'wpml:actionActuatorFunc': items.actionActuatorFunc,
            'wpml:actionId': i, // "动作id，动作组内唯一"
            'wpml:actionActuatorFuncParam': { ...RightForm.value.actionstopRecord.actionActuatorFuncParam }
          })
        break
      case 'focus':
        dealobj['wpml:actionGroup']['wpml:action'].push(
          {
            'wpml:actionActuatorFunc': items.actionActuatorFunc,
            'wpml:actionId': i, // "动作id，动作组内唯一"
            'wpml:actionActuatorFuncParam': { ...RightForm.value.actionfocus.actionActuatorFuncParam }
          })
        break
      case 'zoom':
        dealobj['wpml:actionGroup']['wpml:action'].push(
          {
            'wpml:actionActuatorFunc': items.actionActuatorFunc,
            'wpml:actionId': i, // "动作id，动作组内唯一"
            'wpml:actionActuatorFuncParam': { ...RightForm.value.actionzoom.actionActuatorFuncParam }
          })
        break
      case 'customDirName':
        dealobj['wpml:actionGroup']['wpml:action'].push(
          {
            'wpml:actionActuatorFunc': items.actionActuatorFunc,
            'wpml:actionId': i, // "动作id，动作组内唯一"
            'wpml:actionActuatorFuncParam': { ...RightForm.value.actioncustomDirName.actionActuatorFuncParam }
          })
        break
      case 'gimbalRotate':
        dealobj['wpml:actionGroup']['wpml:action'].push(
          {
            'wpml:actionActuatorFunc': items.actionActuatorFunc,
            'wpml:actionId': i, // "动作id，动作组内唯一"
            'wpml:actionActuatorFuncParam': { ...RightForm.value.actiongimbalRotate.actionActuatorFuncParam }
          })
        break
      case 'rotateYaw':
        dealobj['wpml:actionGroup']['wpml:action'].push(
          {
            'wpml:actionActuatorFunc': items.actionActuatorFunc,
            'wpml:actionId': i, // "动作id，动作组内唯一"
            'wpml:actionActuatorFuncParam': { ...RightForm.value.actionrotateYaw.actionActuatorFuncParam }
          })
        break
      case 'hover':
        dealobj['wpml:actionGroup']['wpml:action'].push(
          {
            'wpml:actionActuatorFunc': items.actionActuatorFunc,
            'wpml:actionId': i, // "动作id，动作组内唯一"
            'wpml:actionActuatorFuncParam': { ...RightForm.value.actionhover.actionActuatorFuncParam }
          })
        break

      default:
        break
    }
  })
  return dealobj
}
function dealWay(item: any) {
  const dealobj = {
    Point: {
      coordinates: `${item.Point.longitude},${item.Point.latitude}`// 航点经纬度
    },
    'wpml:useStraightLine': 1, // 航线是否贴合直线
    'wpml:waypointTurnParam': { // 航点类型（航点转弯模式）
      'wpml:waypointTurnDampingDist': 0.2, // 使用coordinateTurn必须
      'wpml:waypointTurnMode': item.waypointTurnMode // 类型
    },
    'wpml:waypointHeadingParam': { // 当useGlobalHeadingParam为0
      'wpml:waypointHeadingPathMode': item.waypointHeadingPathMode, // 飞行器偏航角转动方向
      'wpml:waypointHeadingPoiIndex': 0,
      'wpml:waypointHeadingAngle': item.waypointHeadingAngle, // 偏航角度
      'wpml:waypointPoiPoint': '0.000000,0.000000,0.000000',
      'wpml:waypointHeadingMode': item.waypointHeadingMode // 飞行器偏航角模式
    },

    'wpml:useGlobalSpeed': item.useGlobalSpeed ? 1 : 0, // 是否使用全局速度
    'wpml:waypointSpeed': Number(item.waypointSpeed),

    'wpml:actionGroup': { // 动作组
      'wpml:actionGroupStartIndex': item.index, // "动作组开始生效的航点"
      'wpml:actionGroupEndIndex': item.index, // "动作组结束生效的航点"
      'wpml:action': [ // 动作列表，一个对象为一组
      ],
      'wpml:actionGroupMode': 'sequence', // "动作执行模式，串行执行"
      'wpml:actionGroupId': item.index, // "动作组id，一个kmz文件内唯一"
      'wpml:actionTrigger': { // "动作触发器"
        'wpml:actionTriggerType': 'reachPoint'
        //  "reachPoint", 动作触发器类型，到达航点触发、航段触发、定时触发、定距触发
        // "betweenAdjacentPoints",
        // "multipleTiming",
        // "multipleDistance"
      }
    },
  }
  dealobj['wpml:actionGroup']['wpml:action'] = []
  item.action.forEach((items: any, i: number) => {
    switch (items.actionActuatorFunc) {
      case 'takePhoto':
        dealobj['wpml:actionGroup']['wpml:action'].push(
          {
            'wpml:actionActuatorFunc': items.actionActuatorFunc,
            'wpml:actionId': i, // "动作id，动作组内唯一"
            'wpml:actionActuatorFuncParam': { ...RightForm.value.actiontakePhoto.actionActuatorFuncParam }
          })
        break
      case 'startRecord':
        dealobj['wpml:actionGroup']['wpml:action'].push(
          {
            'wpml:actionActuatorFunc': items.actionActuatorFunc,
            'wpml:actionId': i, // "动作id，动作组内唯一"
            'wpml:actionActuatorFuncParam': { ...RightForm.value.actionstartRecord.actionActuatorFuncParam }
          })
        break
      case 'stopRecord':
        dealobj['wpml:actionGroup']['wpml:action'].push(
          {
            'wpml:actionActuatorFunc': items.actionActuatorFunc,
            'wpml:actionId': i, // "动作id，动作组内唯一"
            'wpml:actionActuatorFuncParam': { ...RightForm.value.actionstopRecord.actionActuatorFuncParam }
          })
        break
      case 'focus':
        dealobj['wpml:actionGroup']['wpml:action'].push(
          {
            'wpml:actionActuatorFunc': items.actionActuatorFunc,
            'wpml:actionId': i, // "动作id，动作组内唯一"
            'wpml:actionActuatorFuncParam': { ...RightForm.value.actionfocus.actionActuatorFuncParam }
          })
        break
      case 'zoom':
        dealobj['wpml:actionGroup']['wpml:action'].push(
          {
            'wpml:actionActuatorFunc': items.actionActuatorFunc,
            'wpml:actionId': i, // "动作id，动作组内唯一"
            'wpml:actionActuatorFuncParam': { ...RightForm.value.actionzoom.actionActuatorFuncParam }
          })
        break
      case 'customDirName':
        dealobj['wpml:actionGroup']['wpml:action'].push(
          {
            'wpml:actionActuatorFunc': items.actionActuatorFunc,
            'wpml:actionId': i, // "动作id，动作组内唯一"
            'wpml:actionActuatorFuncParam': { ...RightForm.value.actioncustomDirName.actionActuatorFuncParam }
          })
        break
      case 'gimbalRotate':
        dealobj['wpml:actionGroup']['wpml:action'].push(
          {
            'wpml:actionActuatorFunc': items.actionActuatorFunc,
            'wpml:actionId': i, // "动作id，动作组内唯一"
            'wpml:actionActuatorFuncParam': { ...RightForm.value.actiongimbalRotate.actionActuatorFuncParam }
          })
        break
      case 'rotateYaw':
        dealobj['wpml:actionGroup']['wpml:action'].push(
          {
            'wpml:actionActuatorFunc': items.actionActuatorFunc,
            'wpml:actionId': i, // "动作id，动作组内唯一"
            'wpml:actionActuatorFuncParam': { ...RightForm.value.actionrotateYaw.actionActuatorFuncParam }
          })
        break
      case 'hover':
        dealobj['wpml:actionGroup']['wpml:action'].push(
          {
            'wpml:actionActuatorFunc': items.actionActuatorFunc,
            'wpml:actionId': i, // "动作id，动作组内唯一"
            'wpml:actionActuatorFuncParam': { ...RightForm.value.actionhover.actionActuatorFuncParam }
          })
        break

      default:
        break
    }
  })
  return dealobj
}
function dealMiss() {
  lastForm['wpml:missionConfig'] = {

    'wpml:flyToWaylineMode': 'safely',
    'wpml:finishAction': Form.value.finishAction, // 航线结束动作
    'wpml:exitOnRCLost': Form.value.exitOnRCLost, // 航线飞行中失控
    'wpml:executeRCLostAction': Form.value.executeRCLostAction, // 失控动作
    'wpml:takeOffSecurityHeight': 1.5, // 安全起飞高度
    'wpml:takeOffRefPoint': '27,120,20',
    'wpml:takeOffRefPointAGLHeight': 1.5,
    'wpml:globalTransitionalSpeed': 15, // 全局航线过渡速度
    'wpml:globalRTHHeight': Number(Form.value.globalRTHHeight), // 返航高度
    'wpml:droneInfo': {
      'wpml:droneEnumValue': 67,
      'wpml:droneSubEnumValue': 0
    },
    'wpml:payloadInfo': {
      'wpml:payloadEnumValue': 52,
      'wpml:payloadSubEnumValue': 0,
      'wpml:payloadPositionIndex': 0
    }

  }
}

function wayFolder() {
  lastForm.waylines.Folder = {
    'wpml:templateId': 0,
    'wpml:executeHeightMode': Form.value.executeHeightMode, // 执行高度模式
    'wpml:waylineId': 0,
    'wpml:autoFlightSpeed': Form.value.autoFlightSpeed, // 全局航线飞行速度
  }
}
function temFolder() {
  lastForm.template.Folder = {
    // 模板共用的元素
    'wpml:templateType': 'waypoint',
    'wpml:templateId': 0,
    'wpml:autoFlightSpeed': Form.value.autoFlightSpeed, // 全局航线飞行速度
    'wpml:waylineCoordinateSysParam': {
      'wpml:coordinateMode': 'WGS84',
      'wpml:heightMode': 'EGM96'
    },
    'wpml:payloadParam': { // 负载设置
      'wpml:payloadPositionIndex': '0',
      'wpml:focusMode': 'firstPoint',
      'wpml:meteringMode': 'average',
      'wpml:returnMode': 'singleReturnFirst',
      'wpml:samplingRate': '240000',
      'wpml:scanningMode': 'repetitive',
      'wpml:imageFormat': 'wide,zoom'
    },
    // 航点飞行模板元素

    'wpml:globalWaypointTurnMode': Form.value.globalWaypointTurnMode, // 全局航点类型（全局航点转弯模式）
    'wpml:globalUseStraightLine': Form.value.globalUseStraightLine, // 全局航段轨迹是否尽量贴合直线
    'wpml:gimbalPitchMode': Form.value.gimbalPitchMode, // 云台俯仰角控制模式
    'wpml:globalHeight': Form.value.globalHeight, // 全局航线高度

    'wpml:globalWaypointHeadingParam': { // 全局偏航角模式参数
      'wpml:waypointHeadingMode': Form.value.waypointHeadingMode, // 模式
      // 偏航角模式，沿航线飞行、需手动控制、锁定偏航角、自定义、向兴趣点
      'wpml:waypointHeadingAngle': Form.value.waypointHeadingAngle, // 自定义必须
      'wpml:waypointPoiPoint': Form.value.waypointPoiPoint, // 兴趣点必须
      'wpml:waypointHeadingPathMode': Form.value.waypointHeadingPathMode, // 偏航角转动方向

      'wpml:waypointHeadingPoiIndex': '0' // ????
    },
  }
}
// 获取航线详情
async function ondetailWayline() {
  const res = await detailWayline(workspaceId, '8d74d27a-e965-4ceb-9f18-cbaacfc59223')
  console.log(res, 'resres航线详情')
  // if (code === 0) {
  //   message.success('Upload Media File successfully')
  // }
}
function comfirmPoint() {
  store.commit('SET_POINT', RightForm.value)
  message.success('成功!')
}
const choosePoint = ref(false)
function deletePoint() {
  store.state.waylinePoints.forEach((item: any) => { // 将store中的值赋予form
    if (JSON.stringify(item.index) === JSON.stringify(RightForm.value.index)) {
      // const map = root.$map
      store.commit('DELETE_POINT', item.index)
      choosePoint.value = false
    }
  })
}
const nextPoint = (type: string) => {
  const thisIndex = Number(RightForm.value.index)
  const pointIndex = store.state.waylinePoints.findIndex(item => item.index === thisIndex)
  const map = root.$map
  if (type === 'add') {
    if (store.state.waylinePoints[pointIndex + 1]) {
      RightForm.value = { ...store.state.waylinePoints[pointIndex + 1] }
      map.setZoomAndCenter(15, [RightForm.value.Point.longitude + 0.005, RightForm.value.Point.latitude + 0.002])
    }
  } else {
    if (store.state.waylinePoints[pointIndex - 1]) {
      RightForm.value = { ...store.state.waylinePoints[pointIndex - 1] }
      map.setZoomAndCenter(15, [RightForm.value.Point.longitude + 0.005, RightForm.value.Point.latitude + 0.002])
    }
  }
}

const updatePoint = (type: string) => {
  // console.log(marker._position)
  const AMap = root.$aMap
  const thisIndex = Number(RightForm.value.index)
  const marker = store.state.waylineMarkers.find(item => item.index === thisIndex).marker

  switch (type) {
    case 'up':
      RightForm.value.Point.latitude = (0.0001 + Number(RightForm.value.Point.latitude)).toFixed(6)
      marker.setPosition(new AMap.LngLat(parseFloat(RightForm.value.Point.longitude), parseFloat(RightForm.value.Point.latitude)))
      store.commit('SET_POINT', RightForm.value)
      break
    case 'down':
      RightForm.value.Point.latitude = (Number(RightForm.value.Point.latitude) - 0.0001).toFixed(6)
      marker.setPosition(new AMap.LngLat(parseFloat(RightForm.value.Point.longitude), parseFloat(RightForm.value.Point.latitude)))
      store.commit('SET_POINT', RightForm.value)

      break
    case 'left':
      RightForm.value.Point.longitude = (Number(RightForm.value.Point.longitude) - 0.0001).toFixed(6)
      marker.setPosition(new AMap.LngLat(parseFloat(RightForm.value.Point.longitude), parseFloat(RightForm.value.Point.latitude)))
      store.commit('SET_POINT', RightForm.value)

      break
    case 'right':
      RightForm.value.Point.longitude = (0.0001 + Number(RightForm.value.Point.longitude)).toFixed(6)
      marker.setPosition(new AMap.LngLat(parseFloat(RightForm.value.Point.longitude), parseFloat(RightForm.value.Point.latitude)))
      store.commit('SET_POINT', RightForm.value)
      break
    default:
      break
  }
  // marker.setPosition(new AMap.LngLat(113.935248, 22.584471))
}

onMounted(() => {
  // if (map) {
  //   map.clearMap()
  // }
  // ondetailWayline()
  console.log(store.state.waylinePoints, 'store.state.waylinePoints')
  store.commit('SET_WAYLINE_VISIBLE', '1')// 1代表还没有打开动作
  bus.on('click', (event: any) => {
    console.log(event, '收到啦！')
    RightForm.value = { // <Placemark>航点信息
      index: 0,
      Point: {
        longitude: 0,
        latitude: 0
      },
      height: 1, // 航点高度
      ellipsoidHeight: 0, // 航点椭球高度
      waypointTurnMode: 'toPointAndStopWithDiscontinuityCurvature', // 航点类型
      waypointHeadingMode: 'followWayline', // 飞行器偏航角模式
      mappingHeadingAngle: '', // 飞行器偏航角 0-360
      waypointSpeed: 1, // 航点飞行速度
      useGlobalHeight: false, // 是否使用全局高度
      useGlobalTurnParam: false, // 是否使用全局航点类型（全局航点转弯模式）
      useGlobalHeadingParam: false, // 是否使用全局偏航角模式参数
      waypointHeadingAngle: 0, // 飞行器偏航角度
      useGlobalSpeed: false, // 是否全局速度
      waypointHeadingPathMode: 'followBadArc', // 飞行器偏航角转动方向
      action: [],

      actionhover: { // hover悬停
        actionActuatorFunc: '',
        actionId: '',
        actionActuatorFuncParam: {
          'wpml:hoverTime': ''
        }
      },
      actionrotateYaw: { // rotateYaw飞行器偏航
        actionActuatorFunc: '',
        actionId: '',
        actionActuatorFuncParam: {
          'wpml:aircraftHeading': '', // 飞行器目标偏航角 -180 180
          'wpml:aircraftPathMode': ''
        }
      },
      actiongimbalRotate: { // rotateYaw飞行器偏航
        actionActuatorFunc: '',
        actionId: '',
        actionActuatorFuncParam: {
          'wpml:gimbalRotateTime': 0,
          'wpml:gimbalHeadingYawBase': 'north',
          'wpml:gimbalRotateTimeEnable': 0,
          'wpml:gimbalRollRotateEnable': 0,
          'wpml:payloadPositionIndex': 0,
          'wpml:gimbalRollRotateAngle': 0,
          'wpml:gimbalRotateMode': 'absoluteAngle',
          'wpml:gimbalPitchRotateAngle': 0,
          'wpml:gimbalPitchRotateEnable': 0,
          'wpml:gimbalYawRotateEnable': 1,
          'wpml:gimbalYawRotateAngle': -136.1
        }
      },
      actiontakePhoto: { // rotateYaw飞行器偏航
        actionActuatorFunc: '',
        actionId: '',
        actionActuatorFuncParam: {
          'wpml:payloadPositionIndex': '',
          'wpml:fileSuffix': '',
          'wpml:payloadLensIndex': '',
          'wpml:useGlobalPayloadLensIndex': 1
        }
      },
      actionstartRecord: { // rotateYaw飞行器偏航
        actionActuatorFunc: '',
        actionId: '',
        actionActuatorFuncParam: {
          'wpml:payloadPositionIndex': '',
          'wpml:fileSuffix': '',
          'wpml:payloadLensIndex': '',
          'wpml:useGlobalPayloadLensIndex': 1
        }
      },
      actionstopRecord: { // rotateYaw飞行器偏航
        actionActuatorFunc: '',
        actionId: '',
        actionActuatorFuncParam: {
          'wpml:payloadPositionIndex': '',
          'wpml:payloadLensIndex': '',
        }
      },
      actionfocus: { // rotateYaw飞行器偏航
        actionActuatorFunc: '',
        actionId: '',
        actionActuatorFuncParam: {
          'wpml:payloadPositionIndex': '',
          'wpml:isPointFocus': 1, // 是否点对焦
          'wpml:focusX': 0,
          'wpml:focusY': 0,
          'wpml:focusRegionWidth': 0,
          'wpml:focusRegionHeight': 0,
          'wpml:isInfiniteFocus': 0,
        }
      },
      actionzoom: { // rotateYaw飞行器偏航
        actionActuatorFunc: '',
        actionId: '',
        actionActuatorFuncParam: {
          'wpml:payloadPositionIndex': '',
          'wpml:actionzoom': 0,
        }
      },
      actioncustomDirName: { // rotateYaw飞行器偏航
        actionActuatorFunc: '',
        actionId: '',
        actionActuatorFuncParam: {
          'wpml:payloadPositionIndex': '',
          'wpml:directoryName': '',
        }
      },
    }
    RightForm.value.Point.latitude = event.test[1]
    RightForm.value.Point.longitude = event.test[0]
    // RightForm.value.index = event.test[0]

    store.state.waylinePoints.forEach((item: any) => { // 将store中的值赋予form
      if (JSON.stringify(item.Point) === JSON.stringify(RightForm.value.Point)) {
        console.log(item, '123123')

        RightForm.value = { ...item }
      }
    })
    const map = root.$map
    map.setZoomAndCenter(15, [event.test[0] + 0.005, event.test[1] + 0.002])
    choosePoint.value = true
  })
  bus.on('formChange', (form: any) => {
    Form.value = { ...form }
  })
})
onBeforeUnmount(() => {
  store.commit('CLEAR_POINT')
  map.clearMap()
})

</script>

<style lang="scss" scoped>
.wayline-wrapper {
  width: 366px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  background: rgb(46, 46, 46);

  .create-plan-wrapper {
    background-color: #fff;
    color: black;
    padding-bottom: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;

    .content3 {
      height: calc(100% - 145px);
      overflow-y: auto;

      // width: 285px;
      form {
        margin: 10px;
      }

      form label,
      input {
        background-color: #fff;
        color: black;
      }

      .ant-form-item {
        margin-bottom: 0px;
      }

      .ant-input-suffix {
        color: black;
      }

      .jingwei {
        width: 100%;
        display: flex;
        position: relative;

        .wheel {
          width: 110px;
          height: 110px;
          background-color: #fff;
          position: absolute;
          border-radius: 50%;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          border: 1px solid #D9D9D9;
          justify-content: center;

          .wheel-circle {
            width: 24px;
            height: 24px;
            background-color: #D9D9D9;
            border-radius: 50%;
          }

          .wheel-up {
            font-size: 22px;
            position: absolute;
            top: 5px;
            cursor: pointer;

            &:hover {
              color: #1890FF;
            }
          }

          .wheel-down {
            font-size: 22px;
            position: absolute;
            bottom: 5px;
            cursor: pointer;

            &:hover {
              color: #1890FF;
            }
          }

          .wheel-left {
            font-size: 22px;
            position: absolute;
            left: 5px;
            cursor: pointer;

            &:hover {
              color: #1890FF;
            }
          }

          .wheel-right {
            font-size: 22px;
            position: absolute;
            right: 5px;
            cursor: pointer;

            &:hover {
              color: #1890FF;
            }
          }
        }

      }

      .rightHight {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        width: 340px;
      }

      .rightHightSpeed {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        width: 340px;

        .rightHightSpeed-right {
          display: flex;

        }
      }

      .flySpeed-body {
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .plan-timer-form-item {
        // flex-direction: row;

        .ant-radio-button-wrapper {
          background-color: #fff;
          color: black;

          &.ant-radio-button-wrapper-checked {
            background-color: #1890ff;
          }
        }
      }

      .addsubButton {
        background: #1890FF;
        border: none;
        color: #fff;
        margin-left: 4px;
      }

      .flySpeed {
        margin-top: 15px;

        .flySpeed-top {
          display: flex;
        }

        .flySpeed-body {
          padding: 10px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }

}

.pointDiv {
  position: absolute;
  right: 366px;
  width: 240px;
  height: 100vh;
  float: left;
  top: 0;
  z-index: 1000;
  color: black;
  background: #fff;
  border-right: 4px solid #F5F7F9;

  .pointDiv-header {
    height: 70px;
    position: relative;
    border-bottom: 4px solid #F5F7F9;
    border-top: 4px solid #F5F7F9;
    line-height: 70px;

    text-align:left .header-titles {
      text-align: center;
      height: 100%;
      font-size: 16px;
      line-height: 40px;
    }

    .header-icon {
      position: absolute;
      right: 10px;
      top: 0px;
      cursor: pointer;
    }
  }

  .pointDiv-body {
    .body-main {
      padding: 20px;

      .addButton {
        margin: 15px 0;
        width: 208px;
        height: 32px;
        background-color: #fff;
        color: black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border: 1px solid #D9D9D9
      }

      .addButton:hover {
        background-color: #ccc;
      }
    }

  }
}

.color-white {
  color: black;
}

.ant-form-item-label {
  color: red;
}

.pointAction {
  background-color: #1890FF;
  padding: 2px;

  .addButtonOut {

    // width: 208px;
    height: 32px;
    background-color: #1890FF;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .addButtonOut:hover {
    background-color: #ccc;
  }

  .flySpeedOut {
    background-color: #fff;
    color: black;

    .flySpeedOut-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 30px;
      padding: 10px;
      border: 1px solid #F5F7F9
    }

    .flySpeedOut-body {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

}

.title-box {
  display: flex;
  align-items: center;
  background-color: #fff;
  color: black;

  .box-ball {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #1890FF;
    margin-right: 10px;
  }

  .box-text {
    font-size: 18px;
    font-weight: 500;
  }

  .box-textSpeed {
    display: flex;
    font-size: 18px;
    font-weight: 500;

    .textSpeed-right {
      margin-left: 90px;
    }
  }
}

.addSub {
  width: 20px;
  height: 20px;
  background-color: #1890FF;
  color: #fff;
  text-align: center;
  line-height: 16px;
  font-size: 30px;
  cursor: pointer;
}

.size12 {
  font-size: 12px;
  margin-bottom: 15px;
}
</style>
