<template>
  <div class="project-app-wrapper">
    <div v-if="root.$route.name === 'newdevices'" class="wrapper-header">
      设备管理
    </div>

    <div class="wrapper-body">
      <div class="body-item" v-for="dock in onlineDocks.data" :key="dock.sn">
        <div class="item-name">
          <div class="item-name-icon"></div>
          <!-- <div class="item-name-text">{{ dock.model }}</div> -->
          <a-tooltip :title="`${dock.gateway.callsign} - ${dock.callsign ?? 'No Drone'}`">
            <div class="item-name-text">{{ dock.gateway.callsign }} - {{ dock.callsign ?? 'No Drone' }}</div>
          </a-tooltip>
        </div>
        <div class="item-body">
          <div class="item-body-state">
            <img :src="state1" alt="">
            <div class="item-body-state-text">无人机状态</div>
            <div class="item-body-state-line"></div>
            <div class="item-body-state-right"> {{ dockInfo[dock.gateway.sn] ?
              EDockModeCodeTranslate(EDockModeCode[dockInfo[dock.gateway.sn].basic_osd?.mode_code]) :
              EDockModeCodeTranslate(EDockModeCode[EDockModeCode.Disconnected]) }}</div>
          </div>
          <div class="item-body-state">
            <img :src="state2" alt="">
            <div class="item-body-state-text">无人机运行状态</div>
            <div class="item-body-state-line"></div>
            <div class="item-body-state-right">{{ deviceInfo[dock.sn] ? EDockModeCodeTranslate(EModeCode[deviceInfo[dock.sn].mode_code]) :
              EDockModeCodeTranslate(EModeCode[EModeCode.Disconnected]) }}</div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>
<script lang="ts" setup>
import { AndroidOutlined } from '@ant-design/icons-vue'
import { getApp, getRoot } from '/@/root'
import { ref, reactive, onMounted, computed, watch } from 'vue'
import { EDeviceTypeName, ELocalStorageKey } from '/@/types'
import { getDeviceTopo, getUnreadDeviceHms, updateDeviceHms } from '/@/api/manage'
import { OnlineDevice, EModeCode, OSDVisible, EDockModeCode, DeviceOsd } from '/@/types/device'
import { useMyStore } from '/@/store'
import state1 from '/@/assets/item/state1.png'
import state2 from '/@/assets/item/state2.png'
const store = useMyStore()
const onlineDevices = reactive({ // 在线设备
  data: [] as OnlineDevice[]
})
const onlineDocks = reactive({ // 在线机场
  data: [] as OnlineDevice[]
})
const deviceInfo = computed(() => store.state.deviceState.deviceInfo)// 设备信息
const dockInfo = computed(() => store.state.deviceState.dockInfo)// 机场信息
const hmsInfo = computed({
  get: () => store.state.hmsInfo,
  set: (val) => {
    return val
  }
})
const hmsVisible = new Map<string, boolean>()// 存储信息（无人机和机场sn）
const root = getRoot()
const workspaceId = ref(localStorage.getItem(ELocalStorageKey.WorkspaceId)!)
function EDockModeCodeTranslate (str: string) { // 翻译机场状态
  console.log(str, 'str')

  switch (str) {
    case 'Idle':
      return '闲置'
    case 'Disconnected':
      return '连接已断开'
    case '-1':
      return '未连接'
  }
}
// 获取在线内容
function getOnlineTopo () {
  getDeviceTopo(workspaceId.value).then((res) => { // Get Device Topo 获取工作中联机设备的拓扑表
    if (res.code !== 0) {
      return
    }
    onlineDevices.data = []
    onlineDocks.data = []
    console.log(res, '工作中联机设备的拓扑表')

    res.data.forEach((gateway: any) => { // 遍历机场
      const child = gateway.children// 机场的无人机
      const device: OnlineDevice = { // 无人机信息
        model: child?.device_name, // 无人机名称
        callsign: child?.nickname, // 无人机昵称
        sn: child?.device_sn, // 无人机sn
        mode: EModeCode.Disconnected, // 代表断开连接
        gateway: { // 网关
          model: gateway?.device_name, // 机场名称
          callsign: gateway?.nickname, // 机场昵称
          sn: gateway?.device_sn, // 机场sn
          domain: gateway?.domain// 领域？3
        },
        payload: []// 负载
      }
      child?.payloads_list.forEach((payload: any) => { // 遍历无人机的负载列表
        device.payload.push({ // 存放到无人机信息对象中
          index: payload.index,
          model: payload.model,
          payload_name: payload.payload_name, // 负载名称：xxx相机
          payload_sn: payload.payload_sn,
          control_source: payload.control_source, // ?
          payload_index: payload.payload_index// ?
        })
      })
      if (EDeviceTypeName.Dock === gateway.domain) { // 如果设备类型（机场）？==当前设备类型
        hmsVisible.set(device.sn, false)
        hmsVisible.set(device.gateway.sn, false)

        onlineDocks.data.push(device)
      }
      if (gateway.status && EDeviceTypeName.Gateway === gateway.domain) {
        onlineDevices.data.push(device)
      }
    })
  })
}
// 获取未读信息？
function getUnreadHms (sn: string) {
  getUnreadDeviceHms(workspaceId.value, sn).then(res => {
    if (res.data.length !== 0) {
      hmsInfo.value[sn] = res.data
    }
  })
  console.info(hmsInfo.value)
}
// 获取在线设备信息
function getOnlineDeviceHms () {
  const snList = Object.keys(dockInfo.value)
  if (snList.length === 0) {
    return
  }
  snList.forEach(sn => {
    getUnreadHms(sn)
  })
  const deviceSnList = Object.keys(deviceInfo.value)
  if (deviceSnList.length === 0) {
    return
  }
  deviceSnList.forEach(sn => {
    getUnreadHms(sn)
  })
}
// 读消息
function readHms (visiable: boolean, sn: string) {
  if (!visiable) {
    updateDeviceHms(workspaceId.value, sn).then(res => {
      if (res.code === 0) {
        delete hmsInfo.value[sn]
      }
    })
  }
}
// 展开
// function switchVisible (e: any, device: OnlineDevice, isDock: boolean, isClick: boolean) {
//   console.log('触发switchVisible')

//   if (!isClick) {
//     e.target.style.cursor = 'not-allowed'
//     return
//   }
//   if (device.sn === osdVisible.value.sn) {
//     osdVisible.value.visible = !osdVisible.value.visible
//   } else {
//     osdVisible.value.sn = device.sn
//     osdVisible.value.callsign = device.callsign
//     osdVisible.value.model = device.model
//     osdVisible.value.visible = true
//     osdVisible.value.gateway_sn = device.gateway.sn
//     osdVisible.value.is_dock = isDock
//     osdVisible.value.gateway_callsign = device.gateway.callsign
//     osdVisible.value.payloads = device.payload
//   }
//   console.log('SET_OSD_VISIBLE_INFO', osdVisible)

//   store.commit('SET_OSD_VISIBLE_INFO', osdVisible)
// }
onMounted(() => {
  getOnlineTopo()
  setTimeout(() => { // 每隔3s获取一次在线信息？
    watch(() => store.state.deviceStatusEvent,
      data => {
        getOnlineTopo()
        if (data.deviceOnline.sn) {
          getUnreadHms(data.deviceOnline.sn)
        }
      },
      {
        deep: true
      }
    )
    getOnlineDeviceHms()
  }, 3000)
})
</script>
<style lang="scss" scoped>
.project-app-wrapper {
  color: #000;
  flex-direction: column;
  padding: 15px;

  .wrapper-header {
    width: 100%;
    height: 50px;
    background: #fff;
    padding: 18px;
    text-align: start;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 18px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #000000;
  }

  .wrapper-body {
    .body-item {
      // background-color: #FDFDFD;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      cursor: pointer;
      margin-bottom: 10px;
      border-radius: 6px;
  overflow: hidden;
      .item-name {
        width: 100%;
        display: flex;
        align-items: center;
        height: 40px;
        line-height: 40px;
        background-color: #fff;

        .item-name-icon {
          width: 8px;
          height: 8px;
          background-color: #00D9FF;
          margin-left: 23px;
        }

        .item-name-text {
          font-size: 16px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #000000;
          margin-left: 7px;
        }

      }

      .item-body {
        width: 100%;
        background-color: #fdfdfd;
        padding: 16px 64px 16px 23px;

        .item-body-state {
          width: 300px;
          background-color: #F2F2F2;
          margin-bottom: 8px;
          display: flex;
          height: 35px;
          align-items: center;

          img {
            margin-left: 17px;
            width: 12px;
            height: 12px;
          }

          .item-body-state-text {
            margin-left: 3px;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #545454;
          }

          .item-body-state-line {
            margin-left: 17px;
            width: 1px;
            height: 16px;
            background-color: #979797;
          }

          .item-body-state-right {
            margin-left: 17px;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #545454;
          }
        }
      }
    }
  }
}
</style>
