import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { ERouterName } from '/@/types/index'
import { useMyStore } from '/@/store'
import CreatePlan from '/@/components/task/CreatePlan.vue'
import CreateWayline from '/@/components/wayline/cteateWayline.vue'
import WaylinePanel from '/@/pages/page-web/projects/wayline.vue'
import DockPanel from '/@/pages/page-web/projects/dock.vue'
import Newdevices from '/@/pages/page-web/projects/newdevices.vue'
import LiveAgora from '/@/components/livestream-agora.vue'
import LiveOthers from '/@/components/livestream-others.vue'
import { ELocalStorageKey, EUserType } from '/@/types'
import { message } from 'ant-design-vue'

// const store = useMyStore()

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   redirect: '/' + ERouterName.PROJECT
  // },
  // 首页
  // {
  //   path: '/' + ERouterName.PROJECT,
  //   name: ERouterName.PROJECT,
  //   component: () => import('/@/pages/page-web/index.vue')
  // },
  {
    path: '/',
    name: ERouterName.PROJECT,
    component: () => import('/@/pages/page-web/index.vue')
  },
  // 手机直播
  {
    path: '/' + ERouterName.PHONELIVING,
    name: ERouterName.PHONELIVING,
    component: () => import('/@/components/live-phone.vue')
  },
  // members, devices
  {
    path: '/' + ERouterName.HOME,
    name: ERouterName.HOME,
    component: () => import('/@/pages/page-web/home.vue'),
    children: [
      {
        path: '/' + ERouterName.MEMBERS,
        name: ERouterName.MEMBERS,
        component: () => import('/@/pages/page-web/projects/members.vue')
      },
      {
        path: '/' + ERouterName.DEVICES,
        name: ERouterName.DEVICES,
        component: () => import('/@/pages/page-web/projects/devices.vue')
      },
      {
        path: '/' + ERouterName.FIRMWARES,
        name: ERouterName.FIRMWARES,
        component: () => import('../pages/page-web/projects/Firmwares.vue')
      }
    ]
  },
  // workspace
  {
    path: '/' + ERouterName.WORKSPACE,
    name: ERouterName.WORKSPACE,
    component: () => import('/@/pages/page-web/projects/workspace.vue'),
    redirect: '/' + ERouterName.TSA,
    children: [
      // {
      //   path: '/' + ERouterName.LIVESTREAM,
      //   name: ERouterName.LIVESTREAM,
      //   component: () => import('/@/pages/page-web/projects/livestream.vue'),
      //   children: [
      //     {
      //       path: ERouterName.LIVING,
      //       name: ERouterName.LIVING,
      //       components: {
      //         LiveAgora,
      //         LiveOthers
      //       }
      //     }
      //   ]
      // },
      // 最新直播
      {
        path: '/' + ERouterName.NEWLIVESTREAM,
        name: ERouterName.NEWLIVESTREAM,
        component: () => import('/@/pages/page-web/projects/newlivestream.vue'),
        children: [
          {
            path: ERouterName.LIVING,
            name: ERouterName.LIVING,
            components: {
              LiveAgora,
              LiveOthers
            }
          },

        ]
      },
      {
        path: '/' + ERouterName.TSA,
        component: () => import('/@/pages/page-web/projects/tsa.vue')
      },
      {
        name: ERouterName.ITEM,
        path: '/' + ERouterName.ITEM,
        component: () => import('/@/pages/page-web/projects/item.vue')
      },
      {
        path: '/' + ERouterName.LAYER,
        name: ERouterName.LAYER,
        component: () => import('/@/pages/page-web/projects/layer.vue'),

      },
      {
        path: '/' + ERouterName.MEDIA,
        name: ERouterName.MEDIA,
        component: () => import('/@/pages/page-web/projects/media.vue')
      },
      {
        path: '/' + ERouterName.FIRST,
        name: ERouterName.FIRST,
        component: () => import('/@/pages/page-web/projects/first.vue')
      },
      // 航线库
      {
        path: '/' + ERouterName.WAYLINE,
        name: ERouterName.WAYLINE,
        component: () => import('/@/pages/page-web/projects/wayline.vue'),
        children: [
          {
            path: ERouterName.CREATE_WAYLINE,
            name: ERouterName.CREATE_WAYLINE,
            component: CreateWayline,

          }

        ],

      },
      // 任务计划库
      {
        path: '/' + ERouterName.TASK,
        name: ERouterName.TASK,
        component: () => import('/@/pages/page-web/projects/task.vue'),
        children: [
          {
            path: ERouterName.CREATE_PLAN,
            name: ERouterName.CREATE_PLAN,
            component: CreatePlan,
            children: [
              {
                path: ERouterName.SELECT_PLAN,
                name: ERouterName.SELECT_PLAN,
                components: {
                  WaylinePanel,
                  DockPanel,
                  Newdevices
                }
              }
            ]
          }

        ]
      },
      // 最新人员管理
      {
        path: '/' + ERouterName.NEWMEMBERS,
        name: ERouterName.NEWMEMBERS,
        // component: () => import('/@/pages/page-web/projects/newmembers.vue')
        component: () => import('/@/pages/page-web/projects/newmembers.vue')
      },
      // 最新设备管理
      {
        path: '/' + ERouterName.NEWDEVICES,
        name: ERouterName.NEWDEVICES,
        // component: () => import('/@/pages/page-web/projects/newmembers.vue')
        component: () => import('/@/pages/page-web/projects/newdevices.vue')
      },
      // 项目设置
      {
        path: '/' + ERouterName.ITEMSETTING,
        name: ERouterName.ITEMSETTING,
        // component: () => import('/@/pages/page-web/projects/newmembers.vue')
        component: () => import('/@/pages/page-web/projects/itemSetting.vue')
      },
    ]
  },
  // pilot
  {
    path: '/' + ERouterName.PILOT,
    name: ERouterName.PILOT,
    component: () => import('/@/pages/page-pilot/pilot-index.vue'),
  },
  {
    path: '/' + ERouterName.PILOT_HOME,
    component: () => import('/@/pages/page-pilot/pilot-home.vue')
  },
  {
    path: '/' + ERouterName.PILOT_MEDIA,
    component: () => import('/@/pages/page-pilot/pilot-media.vue')
  },
  {
    path: '/' + ERouterName.PILOT_LIVESHARE,
    component: () => import('/@/pages/page-pilot/pilot-liveshare.vue')
  },
  {
    path: '/' + ERouterName.PILOT_BIND,
    component: () => import('/@/pages/page-pilot/pilot-bind.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (!localStorage.getItem(ELocalStorageKey.Token)) {
    if (to.name === ERouterName.PROJECT || to.path === '/' || to.name === ERouterName.PHONELIVING || to.path === '/' + ERouterName.PHONELIVING) {
      next()
    } else {
      router.push('/')
    }
  } else {
    const grade = localStorage.getItem(ELocalStorageKey.Grade)
    if (grade == 1) {
      if (to.name === ERouterName.PROJECT ||
        to.name === ERouterName.FIRST || ERouterName.PHONELIVING) {
        next()
      } else {
        console.log('判断')
        if (to.path === '/' || ERouterName.PHONELIVING) {
          next() // 当前路径已经是根路径，直接通过
        } else {
          router.push('/') // 重定向到根路径
          message.error('暂无权限')
        }
      }
    } else {
      next()
    }
  }
})

export default router
