
import * as Cesium from 'cesium'
import bus from '/@/utils/mitt'
import pointBlue from '/@/assets/waylines/pointBlue.png'
import pointPurple from '/@/assets/waylines/pointPurple.png'
import pointYellow from '/@/assets/waylines/pointYellow.png'
import pointRed from '/@/assets/waylines/pointRed.png'
import pointGreen from '/@/assets/waylines/pointGreen.png'
import { getApp, getRoot } from '/@/root'
import { useGMapManage } from '/@/hooks/use-g-map'

// import airportModel from '../../src/assets/CM-20.glb'
let viewer: any = null// 地图
let viewereye: any = null
let viewereye2: any = null
let spotLightCameraeye: any = null// 无人机摄像头
let spotLightCamera: any = null // 云台摄像头
// 飞机
let positions = Cesium.Cartesian3.fromDegrees(113.943225499, 22.577673716, 500.0)
let position: any = null
// 平移速度
const speed = 5
// 旋转速度
const angle = 0.9
// 俯仰速度
const pitchspeed = 0.2
// 初始高度
const airheight = 80
let entity: any = null
let primitivesone: any = null
let primitivestwo: any = null
let linearr: any = []
let pointArr: any = []// 标点坐标集合
let startpos: any = null// 飞机起始点
let airline: any = null
const airpoint: any = null
export const manager = new Cesium.PrimitiveCollection()// 用于方便地组织和操作一组图元
manager.destroyPrimitives = false// 是阻止Cesium.PrimitiveCollection中的图元在移除或销毁时自动被销毁，需要手动管理图元的生命周期。
// 初始化地图
export function init3DMap(container: string, pos: any) {
  Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlYzkzOWNlMy02N2I0LTRmNWUtODg0ZC0wNzdhYzYwMjBjMmYiLCJpZCI6MTU3ODY3LCJpYXQiOjE2OTA5NDA0MTl9.IWpgsXCpxfnJF50ofCSccSuqzkji1oAJ147bxvRsTiQ'
  viewer = new Cesium.Viewer(container, {
    imageryProvider: new Cesium.UrlTemplateImageryProvider({
      url: 'https://webst02.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}',
    }),
    selectionIndicator: false, // 取消默认绿色选择框
    infoBox: false, // 去除右上角弹窗
    geocoder: false, // 右上角 搜索
    homeButton: false, // 右上角 Home
    sceneModePicker: false, // 右上角 2D/3D切换
    baseLayerPicker: false, // 右上角 地形
    navigationHelpButton: false, // 右上角 Help
    fullscreenButton: false, // 右下角全屏按钮
    // geocoder: false,//隐藏查找位置
    // homeButton:true,//是否显示回到初始位置
    // baseLayerPicker: false,//图层选择
    // navigationHelpButton: false,//帮助
    animation: false, // 动画控制
    // shouldAnimate: false,
    timeline: false, // 时间轴
    // creditsDisplay: false,
    // // 注释时相当于使用默认地形，解开注释相当于使用全球地形
    // // terrainProvider: Cesium.createWorldTerrain()

  })
  // viewer.scene.pickTranslucentDepth = true  //启用使用深度缓冲区拾取半透明几何体
  // 高德地图标识
  const annLayer = new Cesium.UrlTemplateImageryProvider({
    url: 'http://webst02.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scale=1&style=8',
    minimumLevel: 3,
    maximumLevel: 18
  })
  viewer.imageryLayers.addImageryProvider(annLayer)
  // 加载Arcgis地形服务
  viewer.terrainProvider = Cesium.createWorldTerrain({
    // requestWaterMask: true,
    // requestVertexNormals: true,
  })
  // viewer.terrainProvider = new Cesium.ArcGISTiledElevationTerrainProvider({
  //   // url: 'https://elevation3d.arcgis.com/arcgis/rest/services/WorldElevation3D/Terrain3D/ImageServer',

  //   requestWaterMask: true, // 请求水体效果所需要的海岸线数据
  //   requestVertexNormals: true, // 请求地形照明数据
  // })
  // 初始位置
  viewer.camera.setView({
    destination: Cesium.Cartesian3.fromDegrees(pos[1], pos[0], 1300.0), // 中心的坐标
    orientation: { // 方向
      heading: Cesium.Math.toRadians(0),
      pitch: Cesium.Math.toRadians(-90.0),
      roll: 0
    }
  })
  // // 将视角修改为中国的上方
  // Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(
  //   75.0, // 西经
  //   0.0, // 南纬
  //   140.0, // 东经
  //   60.0 // 北纬
  // )
  // 隐藏版本
  viewer._cesiumWidget._creditContainer.style.display = 'none'
  // 禁止相机穿过地形
  viewer.scene.underground = true

  spotLightCameraeye = new Cesium.Camera(viewer.scene)// 无人机的摄像头
  spotLightCameraeye.setView({ // 设置视椎体的俯仰角度
    destination: positions,
    orientation: {
      heading: 0,
      pitch: 0,
      roll: 0
    }
  })
  spotLightCamera = new Cesium.Camera(viewer.scene) // 云台摄像头
  spotLightCamera.setView({ // 设置视椎体的俯仰角度
    destination: positions,
    orientation: {
      heading: 0,
      pitch: 0,
      roll: 0
    }
  })

  viewer.scene.primitives.add(manager) // 将manager对象添加到primitives图元集合中。primitives是Cesium场景中存储和管理图元的集合。
}
let isEyeOk = false// 小地图是否渲染完毕
// 小地图初始化
export function initeye(eye: string): any {
  isEyeOk = false
  Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlYzkzOWNlMy02N2I0LTRmNWUtODg0ZC0wNzdhYzYwMjBjMmYiLCJpZCI6MTU3ODY3LCJpYXQiOjE2OTA5NDA0MTl9.IWpgsXCpxfnJF50ofCSccSuqzkji1oAJ147bxvRsTiQ'
  viewereye = new Cesium.Viewer(eye, {
    imageryProvider: new Cesium.UrlTemplateImageryProvider({
      url: 'https://webst02.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}',
    }),
    geocoder: false,
    homeButton: false,
    sceneModePicker: false,
    baseLayerPicker: false,
    navigationHelpButton: false,
    animation: false,
    creditContainer: 'credit',
    timeline: false,
    fullscreenButton: false,
    vrButton: false,
    terrainProvider: Cesium.createWorldTerrain({
      requestWaterMask: true,
      requestVertexNormals: true,
    }),
    // contextOptions: {
    //   webgl: {
    //     alpha: true,
    //     depth: true,
    //     stencil: true,
    //     antialias: true,
    //     premultipliedAlpha: true,
    //     // 通过canvas.toDataURL()实现截图需要将该项设置为true
    //     preserveDrawingBuffer: true,
    //     failIfMajorPerformanceCaveat: true
    //   }
    // }
  })
  // const control = viewereye.scene.screenSpaceCameraController
  // control.enableRotate = false
  // control.enableTranslate = false
  // control.enableZoom = false
  // control.enableTilt = false
  // control.enableLook = false
  isEyeOk = true
}
// 初始化首页小地图
export function initeye2(container: string, pos: any) {
  Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlYzkzOWNlMy02N2I0LTRmNWUtODg0ZC0wNzdhYzYwMjBjMmYiLCJpZCI6MTU3ODY3LCJpYXQiOjE2OTA5NDA0MTl9.IWpgsXCpxfnJF50ofCSccSuqzkji1oAJ147bxvRsTiQ'
  viewereye2 = new Cesium.Viewer(container, {
    imageryProvider: new Cesium.UrlTemplateImageryProvider({
      url: 'https://webst02.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}',
    }),
    selectionIndicator: false, // 取消默认绿色选择框
    infoBox: false, // 去除右上角弹窗
    geocoder: false, // 右上角 搜索
    homeButton: false, // 右上角 Home
    sceneModePicker: false, // 右上角 2D/3D切换
    baseLayerPicker: false, // 右上角 地形
    navigationHelpButton: false, // 右上角 Help
    fullscreenButton: false, // 右下角全屏按钮
    // geocoder: false,//隐藏查找位置
    // homeButton:true,//是否显示回到初始位置
    // baseLayerPicker: false,//图层选择
    // navigationHelpButton: false,//帮助
    animation: false, // 动画控制
    // shouldAnimate: false,
    timeline: false, // 时间轴
    // creditsDisplay: false,
    // // 注释时相当于使用默认地形，解开注释相当于使用全球地形
    // // terrainProvider: Cesium.createWorldTerrain()

  })
  // viewer.scene.pickTranslucentDepth = true  //启用使用深度缓冲区拾取半透明几何体
  // 高德地图标识
  const annLayer = new Cesium.UrlTemplateImageryProvider({
    url: 'http://webst02.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scale=1&style=8',
    minimumLevel: 3,
    maximumLevel: 18
  })
  viewereye2.imageryLayers.addImageryProvider(annLayer)
  // 加载Arcgis地形服务
  viewereye2.terrainProvider = Cesium.createWorldTerrain({
    // requestWaterMask: true,
    // requestVertexNormals: true,
  })
  // viewer.terrainProvider = new Cesium.ArcGISTiledElevationTerrainProvider({
  //   // url: 'https://elevation3d.arcgis.com/arcgis/rest/services/WorldElevation3D/Terrain3D/ImageServer',

  //   requestWaterMask: true, // 请求水体效果所需要的海岸线数据
  //   requestVertexNormals: true, // 请求地形照明数据
  // })
  // 初始位置
  viewereye2.camera.setView({
    destination: Cesium.Cartesian3.fromDegrees(pos[1], pos[0], 1300.0), // 中心的坐标
    orientation: { // 方向
      heading: Cesium.Math.toRadians(0),
      pitch: Cesium.Math.toRadians(-90.0),
      roll: 0
    }
  })
  // // 将视角修改为中国的上方
  // Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(
  //   75.0, // 西经
  //   0.0, // 南纬
  //   140.0, // 东经
  //   60.0 // 北纬
  // )
  // 隐藏版本
  viewereye2._cesiumWidget._creditContainer.style.display = 'none'
  // 禁止相机穿过地形
  viewereye2.scene.underground = true

  // spotLightCameraeye = new Cesium.Camera(viewer.scene)// 无人机的摄像头
  // spotLightCameraeye.setView({ // 设置视椎体的俯仰角度
  //   destination: positions,
  //   orientation: {
  //     heading: 0,
  //     pitch: 0,
  //     roll: 0
  //   }
  // })
  // spotLightCamera = new Cesium.Camera(viewer.scene) // 云台摄像头
  // spotLightCamera.setView({ // 设置视椎体的俯仰角度
  //   destination: positions,
  //   orientation: {
  //     heading: 0,
  //     pitch: 0,
  //     roll: 0
  //   }
  // })

  // viewer.scene.primitives.add(manager) // 将manager对象添加到primitives图元集合中。primitives是Cesium场景中存储和管理图元的集合。
}
// 清除起飞点
export function clearStart() {
  isFlypos = false
  startpos = null
  pointArr = []
}
// 设置起飞点
let isFlypos = false// 起飞点标识，false代表未设置起飞点
export function setflypos(notEdit: boolean, stores: any): any {
  bus.on('setFlypos', () => {
    isFlypos = false
    startpos = null
    pointArr = []
  })
  // 删除之前地图处理方法
  const handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas)
  handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK)
  bus.on('clearfly', () => {
    handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK)
  })
  if (notEdit) { // 如果不是进入编辑页面
    // 如果已设置起飞点，在点击位置生成起飞点标识图片
    handler.setInputAction(function (click: any) {
      if (!isFlypos) {
        const pickRay = viewer.camera.getPickRay(click.position)
        const intersection = viewer.scene.globe.pick(pickRay, viewer.scene)// 查找射线与渲染的地球表面之间的交点。射线必须以世界坐标给出。
        if (Cesium.defined(intersection)) {
          const cartographicPosition = viewer.scene.globe.ellipsoid.cartesianToCartographic(intersection)// 将提供的笛卡尔坐标转换为制图表示。笛卡尔在椭圆体的中心未定义。
          const longitude = Cesium.Math.toDegrees(cartographicPosition.longitude)
          const latitude = Cesium.Math.toDegrees(cartographicPosition.latitude)
          startpos = intersection// 交点，起飞点
          const cartographic11 = Cesium.Cartographic.fromCartesian(startpos)

          // 获取经度和纬度
          const longitude11 = Cesium.Math.toDegrees(cartographic11.longitude)
          const latitude11 = Cesium.Math.toDegrees(cartographic11.latitude)
          const height11 = cartographic11.height
          bus.emit('setFlyPosition', [longitude11, latitude11, height11])
          viewer.entities.add({
            id: 'flypos',
            name: 'build',
            position: intersection,
            billboard: { // 图标
              // image: '@/../../src/assets/icons/flypos.ico',
              image: 'http://uav.zszc-xsm.com/assets/flypos.ico',
              width: 30,
              height: 30,
            },
          })
          positions = Cesium.Cartesian3.fromDegrees(longitude, latitude, cartographicPosition.height + airheight)
          spotLightCameraeye.setView({ // 设置相机目标位置
            destination: positions,
            orientation: {
              heading: 0,
              pitch: 0,
              roll: 0
            }
          })
          spotLightCamera.setView({
            destination: positions,
            orientation: {
              heading: 0,
              pitch: 0,
              roll: 0
            }
          })
          bus.emit('takepos')// 显示距离标尺界面
          bus.emit('changeFlypos')// 参考起飞点
          showair()// 飞机模型
          showairline()// 飞机下面的定位线
          cone()// 锥体

          isFlypos = true// 设置了一次起飞点就不能再设置了
        } else {
          console.log('not intersection')
        }
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK)
  } else {
    const arrRef = stores.state.waylineForm.takeOffRefPoint.split(',')// 获取起飞点
    const positionsArr = stores.state.waylinePointMsg.map((item: any) => {
      return [Number(item.Point.longitude), Number(item.Point.latitude), Number(item.height)]
    })
    positionsArr.unshift([Number(arrRef[1]), Number(arrRef[0]), Number(arrRef[2])])

    drawWaylinePoint(positionsArr, stores)
    const arr = [//
      stores.state.waylinePointMsg[stores.state.waylinePointMsg.length - 1].Point.longitude,
      stores.state.waylinePointMsg[stores.state.waylinePointMsg.length - 1].Point.latitude,
      stores.state.waylinePointMsg[stores.state.waylinePointMsg.length - 1].height
    ]// 获取航线最后一点
    const intersection = Cesium.Cartesian3.fromDegrees(Number(arr[0]), Number(arr[1]), Number(arr[2]))
    const pointRef = Cesium.Cartesian3.fromDegrees(Number(arrRef[1]), Number(arrRef[0]), Number(arrRef[2]))
    // viewer.entities.add({
    //   id: 'flypos',
    //   name: 'build',
    //   position: pointRef,
    //   billboard: { // 图标
    //     image: '@/../../src/assets/icons/flypos.ico',
    //     // image: 'http://uav.zszc-xsm.com/assets/flypos.ico',
    //     width: 30,
    //     height: 30,
    //   },
    // })
    positions = intersection

    spotLightCameraeye.setView({ // 设置云台以及小相机目标位置
      destination: positions,
      orientation: {
        heading: 0,
        pitch: 0,
        roll: 0
      }
    })
    spotLightCamera.setView({
      destination: positions,
      orientation: {
        heading: 0,
        pitch: 0,
        roll: 0
      }
    })
    const timer = setInterval(() => { // 小地图渲染完成后执行
      if (isEyeOk) {
        cone()// 锥体
        clearInterval(timer)
      }
    }, 100)
    bus.emit('takepos')// 显示距离标尺界面
    bus.emit('changeFlypos')// 参考起飞点
    isFlypos = true// 设置了一次起飞点就不能再设置了
  }
}

// 生成机场
export function showdock(stores: any) {
  const root = getRoot()
  const AMap = root.$aMap
  console.log(AMap, '机场AMap')

  for (const i in stores.state.deviceState.dockInfo) {
    const lo = stores.state.deviceState.dockInfo[i].basic_osd.alternate_land_point.longitude
    const la = stores.state.deviceState.dockInfo[i].basic_osd.alternate_land_point.latitude

    AMap.convertFrom([lo, la], 'gps', function (status, result) {
      if (result.info === 'ok') {
        const lnglats = result.locations // Array.<LngLat>
        const arr = [lnglats[0].lng, lnglats[0].lat]
        Cesium.sampleTerrainMostDetailed(viewer.terrainProvider, [new Cesium.Cartographic(arr[0], arr[1])])
          .then(function (samples) {
            // 获取海拔高度
            const height = samples[0].height
            viewer.entities.add({
              id: 'dock',
              name: 'build',
              position: Cesium.Cartesian3.fromDegrees(arr[0], arr[1], height),
              billboard: { // 图标
                // image: '@/../../src/assets/icons/dock.png',
                image: 'http://uav.zszc-xsm.com/assets/dock.png',
                width: 50,
                height: 50,
              },
            })
          })
          .catch(function (error) {
            // 处理错误
            console.log('获取地形高度时发生错误:', error)
          })
      }
    })
  }
}
// 小地图生成机场
export function showdockmini(stores: any) {
  const root = getRoot()
  const AMap = root.$aMap
  console.log(AMap, '小机场AMap')

  for (const i in stores.state.deviceState.dockInfo) {
    const lo = stores.state.deviceState.dockInfo[i].basic_osd.alternate_land_point.longitude
    const la = stores.state.deviceState.dockInfo[i].basic_osd.alternate_land_point.latitude
    AMap.convertFrom([lo, la], 'gps', function (status, result) {
      if (result.info === 'ok') {
        const lnglats = result.locations // Array.<LngLat>
        const arr = [lnglats[0].lng, lnglats[0].lat]
        Cesium.sampleTerrainMostDetailed(viewereye2.terrainProvider, [new Cesium.Cartographic(arr[0], arr[1])])
          .then(function (samples) {
            // 获取海拔高度
            const height = samples[0].height
            viewereye2.entities.add({
              id: 'dock',
              name: 'build',
              position: Cesium.Cartesian3.fromDegrees(arr[0], arr[1], height),
              billboard: { // 图标
                // image: '@/../../src/assets/icons/dock.png',
                image: 'http://uav.zszc-xsm.com/assets/dock.png',
                width: 30,
                height: 30,
              },
            })
          })
          .catch(function (error) {
            // 处理错误
            console.log('获取地形高度时发生错误:', error)
          })
      }
    })
  }
}
// 生成飞机
export function showair() {
  console.log('生成飞机')
  entity = viewer.entities.add({
    //   //加载飞机模型
    id: 'airport',
    model: {
      uri: 'http://uav.zszc-xsm.com/assets/CM-20.glb',
      // uri: '@/../../src/assets/CM-20.glb',
      minimumPixelSize: 64,
    },
    position: new Cesium.CallbackProperty(() => {
      return positions
    }, false),
    orientation: Cesium.Transforms.headingPitchRollQuaternion(// 指定实体方向的属性。
      positions,
      new Cesium.HeadingPitchRoll(
        Cesium.Math.toRadians(-90), // 设置这个属性即可（顺时针旋转的角度值）
        Cesium.Math.toRadians(0),
        Cesium.Math.toRadians(0)
      )
    )
  })
  return entity
}
export function showairPosition(po: any) {
  entity = viewer.entities.add({
    //   //加载飞机模型
    id: 'airport',
    model: {
      uri: 'http://uav.zszc-xsm.com/assets/CM-20.glb',
      // uri: '@/../../src/assets/CM-20.glb',
      minimumPixelSize: 64,
    },
    position: new Cesium.CallbackProperty(() => {
      return positions
    }, false),
    orientation: Cesium.Transforms.headingPitchRollQuaternion(// 指定实体方向的属性。
      positions,
      new Cesium.HeadingPitchRoll(
        Cesium.Math.toRadians(-90), // 设置这个属性即可（顺时针旋转的角度值）
        Cesium.Math.toRadians(0),
        Cesium.Math.toRadians(0)
      )
    )
  })
  positions = Cesium.Cartesian3.fromDegrees(Number(po[0]), Number(po[1]), Number(po[2]))
}

// 生成飞机下面的线
export function showairline() {
  const rayDirection = Cesium.Cartesian3.subtract(Cesium.Cartesian3.fromRadians(viewer.scene.globe.ellipsoid.cartesianToCartographic(spotLightCamera.position).longitude, viewer.scene.globe.ellipsoid.cartesianToCartographic(spotLightCamera.position).latitude, 0), spotLightCamera.position, new Cesium.Cartesian3())
  const pickRay = new Cesium.Ray(spotLightCamera.position, rayDirection)
  const intersection = viewer.scene.globe.pick(pickRay, viewer.scene)
  const cartographicPosition = viewer.scene.globe.ellipsoid.cartesianToCartographic(intersection)
  linearr = [spotLightCamera.position, Cesium.Cartesian3.fromRadians(cartographicPosition.longitude, cartographicPosition.latitude, cartographicPosition.height)]
  airline = viewer.entities.add({
    id: 'underLine',
    polyline: {
      show: true,
      positions: new Cesium.CallbackProperty(function () {
        return linearr
      }, false),
      width: 1,
      material: new Cesium.PolylineDashMaterialProperty({
        color: Cesium.Color.CYAN,
      }),
    },
  })

  // viewer.entities.add({
  //   name: "sphere_height",

  //   position:spotLightCamera.position,
  //   ellipsoid: {
  //     radii: new Cesium.Cartesian3(4, 4, 4), //xyz三个方向的半径，相等则为球
  //     heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
  //     material: Cesium.Color.RED,
  //     distanceDisplayCondition: new Cesium.DistanceDisplayCondition(100.0, 20000.0)
  //   },
  // })
}

let zhuitiId = 0// 轮廓及锥体id
bus.on('changeZhuitiId', (id: any) => {
  // console.log('收到', id)
  zhuitiId = id
})
// 移动视锥体
export function cone(stores?: any, add?: any, index?: any, type?: any) {
  if (primitivesone || primitivestwo) {
    if (!add) {
      manager.remove(primitivesone)
      manager.remove(primitivestwo)
    } else {
      manager.remove(primitivesone)
      manager.remove(primitivestwo)
      addCone(stores, index, type)// 生成锥体，添加拍照、录像动作
    }
  }

  // viewereye小地图视角移动到spotLightCamera云台相机视角
  viewereye.camera.flyTo({ // viewereye是小地图
    destination: spotLightCamera.position,
    orientation: { // 角度
      heading: spotLightCamera.heading, // 上下
      pitch: spotLightCamera.pitch, // 左右
      roll: spotLightCamera.roll// 旋转
    },
    duration: 0// 视角移动时间
  })

  const scratchRight = new Cesium.Cartesian3()// 一个 3D 笛卡尔点。
  const scratchRotation = new Cesium.Matrix3()// 一个 3x3 矩阵，可索引为列主要顺序数组。为了代码可读性，构造函数参数按行优先顺序排列。
  const scratchOrientation = new Cesium.Quaternion()// 一组 4 维坐标，用于表示 3 维空间中的旋转。

  position = spotLightCamera.positionWC // 获取相机在世界坐标中的位置。

  const directions = spotLightCamera.directionWC // 获取相机在世界坐标中的视图方向。
  const up = spotLightCamera.upWC// 获取相机在世界坐标中的向上方向。
  let right = spotLightCamera.rightWC// 获取相机在世界坐标中的向右方向。
  right = Cesium.Cartesian3.negate(right, scratchRight)// 用于计算给定向量的相反向量

  const rotation = scratchRotation// 设置矩阵信息
  Cesium.Matrix3.setColumn(rotation, 0, right, rotation)
  Cesium.Matrix3.setColumn(rotation, 1, up, rotation)
  Cesium.Matrix3.setColumn(rotation, 2, directions, rotation)

  // 计算视锥姿态
  const orientation = Cesium.Quaternion.fromRotationMatrix(rotation, scratchOrientation)// 从提供的 Matrix3 实例计算四元数。
  spotLightCamera.frustum.near = 1 // 视野中的空间区域，近平面的距离。
  spotLightCamera.frustum.far = 100// 视野中的空间区域，远平面的距离。

  // 视锥图形
  const instanceOutline = new Cesium.GeometryInstance({
    geometry: new Cesium.FrustumGeometry({
      frustum: spotLightCamera.frustum, // 截锥体的包围体积
      origin: position, // 截锥体的包围体积
      orientation: orientation// 截锥体的方向
    }),
    material: Cesium.Color.RED.withAlpha(1),
    id: 'priX',
    attributes: {
      color: Cesium.ColorGeometryInstanceAttribute.fromColor(new Cesium.Color(1.0, 1.0, 0.0, 0.5)),
      show: new Cesium.ShowGeometryInstanceAttribute(true)
    }
  })
  // 轮廓线
  const instance = new Cesium.GeometryInstance({
    geometry: new Cesium.FrustumOutlineGeometry({
      frustum: spotLightCamera.frustum, // 开合程度、长度
      origin: position, // 坐标
      orientation: orientation
    }),
    material: Cesium.Color.RED.withAlpha(0.1),
    id: 'pri0X',
    attributes: {
      color: Cesium.ColorGeometryInstanceAttribute.fromColor(new Cesium.Color(1.0, 0.0, 0.0, 1)),
      show: new Cesium.ShowGeometryInstanceAttribute(true)
    }
  })

  primitivesone = manager.add(new Cesium.Primitive({ // 视椎体

    geometryInstances: instance,
    appearance: new Cesium.PerInstanceColorAppearance({
      translucent: true,
      flat: true
    }),
    asynchronous: false
  }))

  primitivestwo = manager.add(new Cesium.Primitive({ // 轮廓
    geometryInstances: instanceOutline,
    appearance: new Cesium.PerInstanceColorAppearance({
      translucent: true,
      flat: true
    }),
    asynchronous: false
  }))
  if (viewer.scene.primitives.length <= 1) {
    viewer.scene.primitives.add(manager)
  }
}
// 拍照、录像、生成视锥体
export function addCone(stores: any, index: any, type: any) {
  console.log('拍照、录像、生成视锥体')

  const msg = {
    index: index,
    pitch: (spotLightCamera.pitch * (180 / Math.PI)).toFixed(1), // 俯仰，从弧度转换为角度
    yaw: (spotLightCamera.heading * (180 / Math.PI)).toFixed(1), // 偏航
  }
  if (type === 'photo') { // 根据type判断动作
    stores.commit('SET_WAYLINE_PHOTO', msg)
  } else {
    stores.commit('SET_WALINE_VIDEO', msg)
  }

  const scratchRight = new Cesium.Cartesian3()
  const scratchRotation = new Cesium.Matrix3()
  const scratchOrientation = new Cesium.Quaternion()

  position = spotLightCamera.positionWC

  const directions = spotLightCamera.directionWC
  const up = spotLightCamera.upWC
  let right = spotLightCamera.rightWC
  right = Cesium.Cartesian3.negate(right, scratchRight)

  const rotation = scratchRotation
  Cesium.Matrix3.setColumn(rotation, 0, right, rotation)
  Cesium.Matrix3.setColumn(rotation, 1, up, rotation)
  Cesium.Matrix3.setColumn(rotation, 2, directions, rotation)
  // 计算视锥姿态
  const orientation = Cesium.Quaternion.fromRotationMatrix(rotation, scratchOrientation)
  spotLightCamera.frustum.near = 1
  spotLightCamera.frustum.far = 100

  // 视锥轮廓线图形
  const instanceOutline = new Cesium.GeometryInstance({
    geometry: new Cesium.FrustumGeometry({
      frustum: spotLightCamera.frustum, // 截锥体的包围体积
      origin: position, // 截锥体的包围体积
      orientation: orientation// 截锥体的方向
    }),
    material: Cesium.Color.RED.withAlpha(1),
    // id: 'pri' + viewer.scene.primitives.length + 1,
    id: 'pri' + JSON.parse(JSON.stringify(zhuitiId)),
    attributes: {
      color: Cesium.ColorGeometryInstanceAttribute.fromColor(new Cesium.Color(1.0, 1.0, 0.0, 0.5)),
      show: new Cesium.ShowGeometryInstanceAttribute(true)
    }
  })

  const instance = new Cesium.GeometryInstance({
    geometry: new Cesium.FrustumOutlineGeometry({
      frustum: spotLightCamera.frustum, // 开合程度、长度
      origin: position, // 坐标
      orientation: orientation
    }),
    material: Cesium.Color.RED.withAlpha(0.1),
    // id: 'pri0' + viewer.scene.primitives.length + 1,

    id: 'pri0' + JSON.parse(JSON.stringify(zhuitiId)),
    attributes: {
      color: Cesium.ColorGeometryInstanceAttribute.fromColor(new Cesium.Color(1.0, 0.0, 0.0, 1)),
      show: new Cesium.ShowGeometryInstanceAttribute(true)
    }
  })

  primitivesone = manager.add(new Cesium.Primitive({ // 视椎体

    geometryInstances: instance,
    appearance: new Cesium.PerInstanceColorAppearance({
      translucent: true,
      flat: true
    }),
    asynchronous: false
  }))

  primitivestwo = manager.add(new Cesium.Primitive({ // 轮廓
    geometryInstances: instanceOutline,
    appearance: new Cesium.PerInstanceColorAppearance({
      translucent: true,
      flat: true
    }),
    asynchronous: false
  }))
  if (viewer.scene.primitives.length <= 1) {
    viewer.scene.primitives.add(manager)
  }
}

// 平移视锥体及模型
export function changedir(dir: string) {
  if (dir === 'KeyC') {
    spotLightCameraeye.moveUp(speed)
  } else if (dir === 'KeyZ') {
    spotLightCameraeye.moveDown(speed)
  } else if (dir === 'KeyW') {
    spotLightCameraeye.moveForward(speed)
  } else if (dir === 'KeyA') {
    spotLightCameraeye.moveLeft(speed)
  } else if (dir === 'KeyS') {
    spotLightCameraeye.moveBackward(speed)
  } else if (dir === 'KeyD') {
    spotLightCameraeye.moveRight(speed)
  }
  spotLightCamera.setView({ // 设置云台视角
    destination: spotLightCameraeye.positionWC,
    orientation: {
      heading: spotLightCamera.heading,
      pitch: spotLightCamera.pitch,
      roll: spotLightCamera.roll
    }
  })

  cone()// 重新生成视锥体

  entity.position = spotLightCamera.position // 获取云台在世界坐标中的位置赋值给飞机，实现飞机移动

  // 获取云台坐标设置高度为0，再计算两者之间差距
  // 将笛卡尔3转为WGS84弧度制
  const radiansLng = viewer.scene.globe.ellipsoid.cartesianToCartographic(spotLightCamera.position).longitude
  const radiansLat = viewer.scene.globe.ellipsoid.cartesianToCartographic(spotLightCamera.position).latitude
  // WGS84弧度制转笛卡尔3
  const cart3 = Cesium.Cartesian3.fromRadians(radiansLng, radiansLat, 0)
  // 计算两个笛卡尔3向量差值
  const rayDirection = new Cesium.Cartesian3()
  Cesium.Cartesian3.subtract(cart3, spotLightCamera.position, rayDirection)// 得到与地面差距
  // Ray(光线来源，方向)
  const pickRay = new Cesium.Ray(spotLightCamera.position, rayDirection)
  // 查找射线与渲染的地球表面之间的交点
  const intersection = viewer.scene.globe.pick(pickRay, viewer.scene)
  // // 将交点 笛卡尔3转为WGS84弧度制
  // const cartographicPosition = viewer.scene.globe.ellipsoid.cartesianToCartographic(intersection)
  // WGS84弧度制转笛卡尔3
  // Cesium.Cartesian3.fromRadians(cartographicPosition.longitude, cartographicPosition.latitude, cartographicPosition.height)

  linearr = [spotLightCamera.position, intersection]// [云台位置，交点位置]

  return { alt: (Cesium.Cartographic.fromCartesian(spotLightCamera.position).height).toFixed(1) }
}

// 左右旋转视锥体及模型
export function spin(dir: string) {
  if (dir === 'KeyQ') {
    spotLightCameraeye.lookLeft(Cesium.Math.toRadians(angle))
  } else if (dir === 'KeyE') {
    spotLightCameraeye.lookRight(Cesium.Math.toRadians(angle))
  } else if (dir === 'ArrowRight') {
    spotLightCamera.lookRight(Cesium.Math.toRadians(angle))
    cone()
  } else if (dir === 'ArrowLeft') {
    spotLightCamera.lookLeft(Cesium.Math.toRadians(angle))
    cone()
  }

  // flyheadCss.value = `rotate(${spotLightCameraeye.heading * (180 / Math.PI)}deg)`
  // flyhead.value = (spotLightCameraeye.heading * (180 / Math.PI)).toFixed(1)
  console.log((spotLightCameraeye.heading * (180 / Math.PI)).toFixed(1), '转换的角度')
  console.log((spotLightCamera.heading * (180 / Math.PI)).toFixed(1), '转换的角度摄像')

  entity.orientation = Cesium.Transforms.headingPitchRollQuaternion(
    position,
    new Cesium.HeadingPitchRoll(
      spotLightCameraeye.heading - Cesium.Math.toRadians(90), // 设置这个属性即可（顺时针旋转的角度值）
      Cesium.Math.toRadians(0),
      Cesium.Math.toRadians(0)
    )
  )
  return { flyheadCss: `rotate(${spotLightCameraeye.heading * (180 / Math.PI) - 45}deg)`, flyhead: (spotLightCameraeye.heading * (180 / Math.PI)).toFixed(1) }
}

// 上下俯仰视锥体
export function pitch(dir: string) {
  if (dir == 'ArrowUp') {
    if (spotLightCamera.pitch * (180 / Math.PI) < 44.9) {
      spotLightCamera.lookUp(Cesium.Math.toRadians(pitchspeed))
      cone()
      // carpitch.value = (spotLightCamera.pitch * (180 / Math.PI)).toFixed(1)
      // carpitchtop.value = `${(-carpitch.value - 9) / 2}px`
    }
  } else {
    if (spotLightCamera.pitch * (180 / Math.PI) > -90) {
      spotLightCamera.lookDown(Cesium.Math.toRadians(pitchspeed))
      cone()
      // carpitch.value = (spotLightCamera.pitch * (180 / Math.PI)).toFixed(1)
      // carpitchtop.value = `${(-carpitch.value - 9) / 2}px`
    }
  }
  return { carpitch: (spotLightCamera.pitch * (180 / Math.PI)).toFixed(1), carpitchtop: `${(-(spotLightCamera.pitch * (180 / Math.PI)).toFixed(1) - 9) / 2}px` }
}

// 标点划线、拍照
export async function addpointline(stores: any, type?: any) {
  let isRepeat = false// 标点数组中是否存在重复数据
  pointArr.forEach((item: any) => {
    if (JSON.stringify(spotLightCamera.position) === JSON.stringify(JSON.parse(item).position)) {
      isRepeat = true
    }
  })
  if (isRepeat) return 0

  // 将云台高度笛卡尔3转换为弧度制WGS84，高度m
  const longitudeTemp = viewer.scene.globe.ellipsoid.cartesianToCartographic(spotLightCamera.position).longitude
  const latitudeTemp = viewer.scene.globe.ellipsoid.cartesianToCartographic(spotLightCamera.position).latitude
  const infoHeight = viewer.scene.globe.ellipsoid.cartesianToCartographic(spotLightCamera.position).height
  // 将弧度制转换为度数显示
  const lat = Cesium.Math.toDegrees(latitudeTemp)// 原来是lat
  const lng = Cesium.Math.toDegrees(longitudeTemp)// 原来是lng

  // 将弧度制的经纬度转换为笛卡尔3,高度除以2是为了在中间显示
  const carPosition = Cesium.Cartesian3.fromRadians(longitudeTemp, latitudeTemp, infoHeight / 2)
  // 高度为0的笛卡尔3
  const carPosition0 = Cesium.Cartesian3.fromRadians(longitudeTemp, latitudeTemp, 0)

  pointArr.push(JSON.stringify({ position: { ...spotLightCamera.position }, id: stores.state.waylinePointMsg.length }))// 存储当前摄像头位置
  // pointArr.push(JSON.stringify({ position: { ...truePositonCar3 }, id: stores.state.waylinePointMsg.length }))// 存储当前摄像头位置
  // 创建一个标点
  const point = viewer.entities.add({
    id: stores.state.waylinePointMsg.length + 1,
    name: 'build',
    position: spotLightCamera.position, // spotLightCamera.position为笛卡尔3
    // position: truePositonCar3, // spotLightCamera.position为笛卡尔3
    label: { // 文字标签
      text: `${stores.state.waylinePointMsg.length + 1}`,
      font: '500 30px Helvetica', // 15pt monospace
      scale: 0.5,
      style: Cesium.LabelStyle.FILL,
      fillColor: Cesium.Color.WHITE,
      pixelOffset: new Cesium.Cartesian2(0, -5), // 偏移量
      backgroundColor: Cesium.Color.CORNFLOWERBLUE,
      showBackground: true,
    },
    billboard: { // 图标
      image: 'http://uav.zszc-xsm.com/assets/markers.png',
      // image: '../../src/assets/icons/markers.png',
      width: 30,
      height: 30,
    },
  })

  // 画下面显示信息的点
  viewer.entities.add({
    id: `info${stores.state.waylinePointMsg.length + 1}`,
    show: false,
    name: 'infobox',
    position: carPosition,
    label: { // 文字标签
      text: `ASL海拔:${infoHeight.toFixed(1)} m`,
      font: '500 30px Helvetica', // 15pt monospace
      scale: 0.5,
      style: Cesium.LabelStyle.FILL,
      fillColor: Cesium.Color.WHITE,
      pixelOffset: new Cesium.Cartesian2(0, -5), // 偏移量
      backgroundColor: Cesium.Color.CORNFLOWERBLUE,
      showBackground: true,
    },

  })

  // 画每一个标点垂直往下的虚线
  viewer.entities.add({
    id: `verticalLine${stores.state.waylinePointMsg.length + 1}`,
    polyline: {
      positions: [JSON.parse(JSON.stringify(spotLightCamera.position)), carPosition0], // 表示虚线从哪里到哪里，用JSON把点的位置固定
      // positions: [JSON.parse(JSON.stringify(truePositonCar3)), carPosition0], // 表示虚线从哪里到哪里，用JSON把点的位置固定
      width: 1,
      material: new Cesium.PolylineDashMaterialProperty({
        color: Cesium.Color.CYAN,
      }),
    },
  })

  // 画连接点的线段
  if (pointArr.length > 1) {
    viewer.entities.add({
      id: `line${stores.state.waylinePointMsg.length + 1}`,
      polyline: {
        show: true,
        positions: [JSON.parse(pointArr[pointArr.length - 2]).position, JSON.parse(pointArr[pointArr.length - 1]).position],
        width: 5,
        material: Cesium.Color.RED,
      },
    })
  } else {
    viewer.entities.add({
      id: `line${stores.state.waylinePointMsg.length + 1}`,
      polyline: {
        show: true,
        positions: [JSON.parse(pointArr[pointArr.length - 1]).position, startpos], // 代表从起飞点到第一个点
        width: 5,
        material: Cesium.Color.RED,
      },
    })
  }

  // 将笛卡尔3转换为弧度制
  // const latTemp = Cesium.Cartographic.fromCartesian(spotLightCamera.position).latitude
  // const lngTemp = Cesium.Cartographic.fromCartesian(spotLightCamera.position).longitude
  // const heightTemp = Cesium.Cartographic.fromCartesian(spotLightCamera.position).height
  // 将弧度制转换为度数显示
  // const lat = Cesium.Math.toDegrees(latTemp)
  // const lng = Cesium.Math.toDegrees(lngTemp)

  // 使用sampleTerrain方法获取地形的海拔高度
  Cesium.sampleTerrainMostDetailed(viewer.terrainProvider, [Cesium.Cartographic.fromDegrees(lng, lat)])
    .then(function (samples) {
      // 获取地形海拔高度
      const height2 = samples[0].height

      if (stores) {
        stores.commit('SET_WAYLINE_MSG', {
          position: {
            lng: Number(lng),
            lat: Number(lat)
          },
          index: stores.state.waylinePointMsg.length,
          height: infoHeight,
          dixingHeight: infoHeight - height2
        })
        stores.commit('SET_POINT_ID')
        if (type) {
          cone(stores, true, stores.state.waylinePointMsg.length - 1, type)
        }
      }
      // console.log('返回值：', lat, lng)
      // return { lat: lat, lng: lng }
    })
    .catch(function (error) {
      // 处理错误
      console.log('获取地形高度时发生错误:', error)
    })
}

// 删除航线库中的point，上面是以前的只删除单个航点，现在是删除那个航点之后全部内容
export function deleteWaylinePoint(msg: any, stores: any) {
  // 先删除点
  const entityCollection = viewer.entities
  const entityId = msg.id
  stores.commit('DELETE_WAYLINE_MSG', entityId)// 删除点后所有内容
  console.log(pointArr, 'pointArr')

  const deleteIndex = JSON.parse(
    pointArr.filter((item) => {
      return JSON.parse(item).id == msg.id
    })[0]
  ).id
  // 通过 id 查找要删除的 Primitive
  const primitivesToRemove: any = []
  console.log(manager._primitives, '列表')
  pointArr.forEach((item: any) => {
    if (JSON.parse(item).id > deleteIndex || JSON.parse(item).id == deleteIndex) {
      const entityIds = JSON.parse(item).id
      manager._primitives.forEach((itemPri: any) => {
        console.log(itemPri._instanceIds[0], 'pri' + entityIds, '艾迪')
        if (itemPri._instanceIds[0] === 'pri0' + entityIds || itemPri._instanceIds[0] === 'pri' + entityIds) {
          primitivesToRemove.push(itemPri)
          console.log('添加的item ', itemPri)
        }
      })
    }
  })

  primitivesToRemove.forEach((item: any) => {
    manager.remove(item)
  })
  // 删除标点

  pointArr.forEach((item: any) => {
    if (JSON.parse(item).id > deleteIndex || JSON.parse(item).id == deleteIndex) {
      const entityId = JSON.parse(item).id + 1
      const entityIdInfo = `info${JSON.parse(item).id + 1}`
      const entity = entityCollection.getById(entityId)
      const entity2 = entityCollection.getById(entityIdInfo)
      if (entity) {
        entityCollection.remove(entity)
      }
      if (entity2) {
        entityCollection.remove(entity2)
      }
    }
  })
  pointArr.splice(deleteIndex)
  console.log(pointArr, '画线的数组')

  // 再删除线段
  const entitiesToRemove: any = []
  const entities = entityCollection.values
  entities.forEach((entity: any) => {
    if (entity.polyline) {
      entitiesToRemove.push(entity)
    }
  })
  entitiesToRemove.forEach((entity: any) => {
    entityCollection.remove(entity)
  })

  // 再重新渲染
  pointArr.forEach((item: any, index: number) => {
    console.log(JSON.parse(item), '重新')
    if (index > 0) {
      viewer.entities.add({
        id: `line${JSON.parse(item).id + 1}`,
        polyline: {
          show: true,
          positions: [JSON.parse(pointArr[index - 1]).position, JSON.parse(pointArr[index]).position],
          width: 5,
          material: Cesium.Color.RED,
        },
      })
    } else {
      viewer.entities.add({
        id: `line${JSON.parse(item).id + 1}`,
        polyline: {
          show: true,
          positions: [JSON.parse(pointArr[index]).position, startpos], // 代表从起飞点到第一个点
          width: 5,
          material: Cesium.Color.RED,
        },
      })
    }
  })
}
// 删除标注中的标注
export function deleteEntity(id: any) {
  console.log('要删除的实体id:', id)
  const entityCollection = viewer.entities
  const entityId = id
  const entity = entityCollection.getById(entityId)
  if (entity) {
    entityCollection.remove(entity)
  }
}

// 航线查看标点，画点，注意！实体点的id从1开始，信息点从0开始
export const drawWaylinePoint = async (positions: any, stores: any) => {
  if (stores.state.waylineLength > 0) {
    // 先删除之前的信息
    let num = 0
    pointArr = []
    const entityCollection = viewer.entities// 获取实体集合

    while (num < stores.state.waylineLength + 1) {
      // const entity1 = entityCollection.getById(`wayline${num}`)
      // const entity2 = entityCollection.getById(`waylineLine${num}`)
      // const entity3 = entityCollection.getById(`waylineRedline${num}`)
      // const entity4 = entityCollection.getById('flypos')
      const entity1 = entityCollection.getById(`${num}`)
      const entity2 = entityCollection.getById(`verticalLine${num}`)
      const entity3 = entityCollection.getById(`line${num}`)
      const entity4 = entityCollection.getById('flypos')
      if (entity1) {
        entityCollection.remove(entity1)
      }

      if (entity2) {
        entityCollection.remove(entity2)
      }

      if (entity3) {
        entityCollection.remove(entity3)
      }

      if (entity4) {
        entityCollection.remove(entity4)
      }
      num++
    }
  }
  // 进行标点
  const root = getRoot()
  const AMap = root.$aMap
  const truePositons: any = []// 转换为84坐标后的坐标组
  for (const posi of positions) {
    await new Promise(resolve => {
      AMap.convertFrom([posi[0], posi[1]], 'gps', function (status, result) {
        if (result.info === 'ok') {
          const lnglats = result.locations // Array.<LngLat>
          truePositons.push([lnglats[0].lng, lnglats[0].lat, posi[2]])
          resolve()
        }
      })
    })
  }
  console.log('转换前的坐标', positions)
  console.log('转换后正确的坐标', truePositons)

  // positions.forEach(async (posi, index) => {
  //   await new Promise(resolve => {
  //     AMap.convertFrom([posi[0], posi[1]], 'gps', function (status, result) {
  //       if (result.info === 'ok') {
  //         const lnglats = result.locations // Array.<LngLat>
  //         truePositons.push([lnglats[0].lng, lnglats[0].lat, posi[2]])
  //         resolve()
  //       }
  //     })
  //   })
  // })

  truePositons.forEach((item: any, index: number, hasTakeoff?: any) => {
    const po = Cesium.Cartesian3.fromDegrees(item[0], item[1], item[2])
    const position2 = Cesium.Cartographic.fromDegrees(item[0], item[1])

    // 跳转坐标
    if (index === 0) {
      const cameraHeight = 1200 // 设置视角高度，单位为米
      const destination = Cesium.Cartesian3.fromDegrees(item[0], item[1], cameraHeight)

      viewer.camera.flyTo({ // 跳转视角
        destination: destination,
        orientation: {
          heading: Cesium.Math.toRadians(0),
          pitch: Cesium.Math.toRadians(-90),
          roll: 0
        },
        duration: 2 // 飞行持续时间，单位为秒
      })
    }
    if (index > 0) {
      // 给坐标数组添加元素
      pointArr.push(JSON.stringify({ position: { ...po }, id: index - 1 }))

      // 创建一个标点
      console.log('创建的点的id', index)

      const point = viewer.entities.add({
        id: `${index}`,
        position: po, // spotLightCamera.position为笛卡尔3
        label: { // 文字标签
          text: `${index}`,
          font: '500 30px Helvetica', // 15pt monospace
          scale: 0.5,
          style: Cesium.LabelStyle.FILL,
          fillColor: Cesium.Color.WHITE,
          pixelOffset: new Cesium.Cartesian2(0, -5), // 偏移量
          backgroundColor: Cesium.Color.CORNFLOWERBLUE,
          showBackground: true,
        },
        billboard: { // 图标
          // image: '../../src/assets/icons/markers.png',
          image: 'http://uav.zszc-xsm.com/assets/markers.png',
          width: 30,
          height: 30,
        },
      })

      // 画每一个标点垂直往下的虚线
      Cesium.sampleTerrainMostDetailed(viewer.terrainProvider, [position2])
        .then(function (samples) {
          // 获取海拔高度
          const height = samples[0].height
          viewer.entities.add({
            id: `verticalLine${index}`,
            polyline: {
              positions: [po, Cesium.Cartesian3.fromDegrees(item[0], item[1], height)], // 表示虚线从哪里到哪里，用JSON把点的位置固定
              width: 1,
              material: new Cesium.PolylineDashMaterialProperty({
                color: Cesium.Color.CYAN,
              }),
            },
          })
        })
        .catch(function (error) {
          // 处理错误
          console.log('获取地形高度时发生错误:', error)
        })
    }
    // 画连接点的线段
    if (positions.length > 1 && index !== positions.length - 1) {
      // 如果机场在线
      if (index === 0 && JSON.stringify(stores.state.deviceState.dockInfo) !== '{}') {
        for (const i in stores.state.deviceState.dockInfo) {
          const lo = stores.state.deviceState.dockInfo[i].basic_osd.alternate_land_point.longitude
          const la = stores.state.deviceState.dockInfo[i].basic_osd.alternate_land_point.latitude
          const startArr = [lo, la]
          AMap.convertFrom(startArr, 'gps', function (status, result) {
            if (result.info === 'ok') {
              const lnglats = result.locations // Array.<LngLat>
              const arr = [lnglats[0].lng, lnglats[0].lat, item[2]]
              Cesium.sampleTerrainMostDetailed(viewer.terrainProvider, [new Cesium.Cartographic(lnglats[0].lng, lnglats[0].lat)])
                .then(function (samples) {
                  // 获取海拔高度
                  const height = samples[0].height

                  const start = [lnglats[0].lng, lnglats[0].lat, height]

                  const end = truePositons[index]
                  const startC3 = Cesium.Cartesian3.fromDegrees(start[0], start[1], start[2])
                  const endC3 = Cesium.Cartesian3.fromDegrees(end[0], end[1], end[2])
                  startpos = [startC3, endC3]
                  viewer.entities.add({
                    id: 'flypos',
                    polyline: {
                      show: true,
                      positions: [startC3, endC3],
                      width: 5,
                      material: Cesium.Color.RED,
                    },
                  })
                })
                .catch(function (error) {
                  // 处理错误
                  console.log('获取地形高度时发生错误:', error)
                })
            }
          })
        }
      } else if (index === 0) { // 若不在线，使用文件中起飞点
        console.log('机场不在线')
        startpos = po
        viewer.entities.add({
          id: 'flypos',
          position: po,
          billboard: {
            // image: '@/../../src/assets/icons/flypos.ico',
            image: 'http://uav.zszc-xsm.com/assets/flypos.ico',
            width: 30,
            height: 30
          }
          // polyline: {
          //   show: true,
          //   positions: [startC3, endC3],
          //   width: 5,
          //   material: Cesium.Color.RED,
          // },
        })
      }
      const start = truePositons[index]
      const end = truePositons[index + 1]
      console.log(truePositons[index + 1], ' truePositons[index + 1] truePositons[index + 1]')

      const startC3 = Cesium.Cartesian3.fromDegrees(start[0], start[1], start[2])
      const endC3 = Cesium.Cartesian3.fromDegrees(end[0], end[1], end[2])
      viewer.entities.add({
        id: `line${index}`,
        polyline: {
          show: true,
          positions: [startC3, endC3],
          width: 5,
          material: Cesium.Color.RED,
        },
      })
    }
  })
  console.log(pointArr, '添加之后')

  stores.commit('SET_WAYLINE_LENGTH', positions.length - 1)// 长度不算起飞点
}
// 小地图绘制航线信息
export const drawWaylinePointLittle = async (positions: any, stores: any) => {
  if (stores.state.littleWaylineLength > 0) {
    // 先删除之前的信息
    let num = 0
    pointArr = []
    const entityCollection = viewereye2.entities// 获取实体集合

    while (num < stores.state.littleWaylineLength + 1) {
      // const entity1 = entityCollection.getById(`wayline${num}`)
      // const entity2 = entityCollection.getById(`waylineLine${num}`)
      // const entity3 = entityCollection.getById(`waylineRedline${num}`)
      // const entity4 = entityCollection.getById('flypos')
      const entity1 = entityCollection.getById(`${num}`)
      const entity2 = entityCollection.getById(`verticalLine${num}`)
      const entity3 = entityCollection.getById(`line${num}`)
      const entity4 = entityCollection.getById('flypos')
      if (entity1) {
        entityCollection.remove(entity1)
      }

      if (entity2) {
        entityCollection.remove(entity2)
      }

      if (entity3) {
        entityCollection.remove(entity3)
      }

      if (entity4) {
        entityCollection.remove(entity4)
      }
      num++
    }
  }
  // 进行标点
  const root = getRoot()
  const AMap = root.$aMap
  const truePositons: any = []// 转换为84坐标后的坐标组
  for (const posi of positions) {
    await new Promise(resolve => {
      AMap.convertFrom([posi[0], posi[1]], 'gps', function (status, result) {
        if (result.info === 'ok') {
          const lnglats = result.locations // Array.<LngLat>
          truePositons.push([lnglats[0].lng, lnglats[0].lat, posi[2]])
          resolve()
        }
      })
    })
  }
  truePositons.forEach((item: any, index: number, hasTakeoff?: any) => {
    const po = Cesium.Cartesian3.fromDegrees(item[0], item[1], item[2])
    const position2 = Cesium.Cartographic.fromDegrees(item[0], item[1])

    // 跳转坐标
    if (index === 0) {
      const cameraHeight = 1200 // 设置视角高度，单位为米
      const destination = Cesium.Cartesian3.fromDegrees(item[0], item[1], cameraHeight)

      viewereye2.camera.flyTo({ // 跳转视角
        destination: destination,
        orientation: {
          heading: Cesium.Math.toRadians(0),
          pitch: Cesium.Math.toRadians(-90),
          roll: 0
        },
        duration: 2 // 飞行持续时间，单位为秒
      })
    }
    if (index > 0) {
      const point = viewereye2.entities.add({
        id: `${index}`,
        position: po, // spotLightCamera.position为笛卡尔3
        label: { // 文字标签
          text: `${index}`,
          font: '500 30px Helvetica', // 15pt monospace
          scale: 0.5,
          style: Cesium.LabelStyle.FILL,
          fillColor: Cesium.Color.WHITE,
          pixelOffset: new Cesium.Cartesian2(0, -5), // 偏移量
          backgroundColor: Cesium.Color.CORNFLOWERBLUE,
          showBackground: true,
        },
        billboard: { // 图标
          // image: '../../src/assets/icons/markers.png',
          image: 'http://uav.zszc-xsm.com/assets/markers.png',
          width: 20,
          height: 20,
        },
      })

      // 画每一个标点垂直往下的虚线
      Cesium.sampleTerrainMostDetailed(viewereye2.terrainProvider, [position2])
        .then(function (samples) {
          // 获取海拔高度
          const height = samples[0].height
          viewereye2.entities.add({
            id: `verticalLine${index}`,
            polyline: {
              positions: [po, Cesium.Cartesian3.fromDegrees(item[0], item[1], height)], // 表示虚线从哪里到哪里，用JSON把点的位置固定
              width: 1,
              material: new Cesium.PolylineDashMaterialProperty({
                color: Cesium.Color.CYAN,
              }),
            },
          })
        })
        .catch(function (error) {
          // 处理错误
          console.log('获取地形高度时发生错误:', error)
        })
    }
    // 画连接点的线段
    if (positions.length > 1 && index !== positions.length - 1) {
      // 如果机场在线
      if (index === 0 && JSON.stringify(stores.state.deviceState.dockInfo) !== '{}') {
        for (const i in stores.state.deviceState.dockInfo) {
          const lo = stores.state.deviceState.dockInfo[i].basic_osd.alternate_land_point.longitude
          const la = stores.state.deviceState.dockInfo[i].basic_osd.alternate_land_point.latitude
          const startArr = [lo, la]
          AMap.convertFrom(startArr, 'gps', function (status, result) {
            if (result.info === 'ok') {
              const lnglats = result.locations // Array.<LngLat>
              const arr = [lnglats[0].lng, lnglats[0].lat, item[2]]
              Cesium.sampleTerrainMostDetailed(viewereye2.terrainProvider, [new Cesium.Cartographic(lnglats[0].lng, lnglats[0].lat)])
                .then(function (samples) {
                  // 获取海拔高度
                  const height = samples[0].height

                  const start = [lnglats[0].lng, lnglats[0].lat, height]

                  const end = truePositons[index]
                  const startC3 = Cesium.Cartesian3.fromDegrees(start[0], start[1], start[2])
                  const endC3 = Cesium.Cartesian3.fromDegrees(end[0], end[1], end[2])
                  startpos = [startC3, endC3]
                  viewereye2.entities.add({
                    id: 'flypos',
                    polyline: {
                      show: true,
                      positions: [startC3, endC3],
                      width: 5,
                      material: Cesium.Color.RED,
                    },
                  })
                })
                .catch(function (error) {
                  // 处理错误
                  console.log('获取地形高度时发生错误:', error)
                })
            }
          })
        }
      } else if (index === 0) { // 若不在线，使用文件中起飞点
        console.log('机场不在线')
        startpos = po
        viewereye2.entities.add({
          id: 'flypos',
          position: po,
          billboard: {
            // image: '@/../../src/assets/icons/flypos.ico',
            image: 'http://uav.zszc-xsm.com/assets/flypos.ico',
            width: 20,
            height: 20
          }
          // polyline: {
          //   show: true,
          //   positions: [startC3, endC3],
          //   width: 5,
          //   material: Cesium.Color.RED,
          // },
        })
      }
      const start = truePositons[index]
      const end = truePositons[index + 1]

      const startC3 = Cesium.Cartesian3.fromDegrees(start[0], start[1], start[2])
      const endC3 = Cesium.Cartesian3.fromDegrees(end[0], end[1], end[2])
      viewereye2.entities.add({
        id: `line${index}`,
        polyline: {
          show: true,
          positions: [startC3, endC3],
          width: 5,
          material: Cesium.Color.RED,
        },
      })
    }
  })

  stores.commit('SET_LITTLEWAYLINE_LENGTH', positions.length - 1)// 长度不算起飞点
}
// 航线查看标点，退出删除航线
export const drawWaylinePointDelete = (stores: any) => {
  if (stores.state.waylineLength > 0) {
    console.log('删除！！！！')
    // startpos = null
    // 先删除之前的信息
    let num = 0
    const entityCollection = viewer.entities// 获取实体集合
    while (num < stores.state.waylineLength + 1) {
      const entity1 = entityCollection.getById(`${num}`)
      const entity2 = entityCollection.getById(`verticalLine${num}`)
      const entity3 = entityCollection.getById(`line${num}`)
      const entity4 = entityCollection.getById('flypos')

      if (entity1) {
        entityCollection.remove(entity1)
      }
      if (entity2) {
        entityCollection.remove(entity2)
      }
      if (entity3) {
        entityCollection.remove(entity3)
      }
      if (entity4) {
        entityCollection.remove(entity4)
      }
      num++
    }
  }
}
// 小地图航线查看标点，退出删除航线
export const drawWaylinePointDeleteLittle = (stores: any) => {
  if (stores.state.littleWaylineLength > 0) {
    // startpos = null
    // 先删除之前的信息
    let num = 0
    const entityCollection = viewereye2.entities// 获取实体集合
    while (num < stores.state.littleWaylineLength + 1) {
      const entity1 = entityCollection.getById(`${num}`)
      const entity2 = entityCollection.getById(`verticalLine${num}`)
      const entity3 = entityCollection.getById(`line${num}`)
      const entity4 = entityCollection.getById('flypos')

      if (entity1) {
        entityCollection.remove(entity1)
      }
      if (entity2) {
        entityCollection.remove(entity2)
      }
      if (entity3) {
        entityCollection.remove(entity3)
      }
      if (entity4) {
        entityCollection.remove(entity4)
      }
      num++
    }
  }
}
// 根据坐标绘制标点
function positionDrawPin(msg: any, type: string) {
  console.log(msg.color, '画标点中标点的信息')
  const color = Cesium.Color.fromCssColorString(msg.color)
  function getImg() {
    switch (msg.color) {
      case '#2D8CF0FF': // 蓝色
        return pointBlue
      case '#B620E0FF': // 紫色
        return pointPurple
      case '#FFBB00FF': // 黄色
        return pointYellow
      case '#E23C39FF': // 红色
        return pointRed
      case '#19BE6BFF': // 绿色
        return pointGreen
      default:
        return pointBlue
    }
  }
  const myImg = getImg()

  const coordinates = msg.position

  // 将经纬度坐标转换为Cesium的世界坐标（Cartesian3）
  const position = Cesium.Cartesian3.fromDegrees(coordinates[0], coordinates[1], 0)
  const position2 = Cesium.Cartographic.fromDegrees(coordinates[0], coordinates[1])
  // 将世界坐标转换为屏幕坐标（像素坐标）

  const Cartesian = Cesium.SceneTransforms.wgs84ToWindowCoordinates(viewer.scene, position)

  const pickRay = viewer.camera.getPickRay(Cartesian)

  const intersection = viewer.scene.globe.pick(pickRay, viewer.scene)// 查找射线与渲染的地球表面之间的交点。射线必须以世界坐标给出。

  if (intersection) {
    const cartographicPosition = viewer.scene.globe.ellipsoid.cartesianToCartographic(intersection)// 将提供的笛卡尔坐标转换为制图表示。笛卡尔在椭圆体的中心未定义。
    const longitude = Cesium.Math.toDegrees(cartographicPosition.longitude)
    const latitude = Cesium.Math.toDegrees(cartographicPosition.latitude)
    const height = cartographicPosition.height
    const promise = Cesium.sampleTerrainMostDetailed(viewer.terrainProvider, [position2]).then((updatedPositions) => {
      // updatedPositions 包含了查询点的高程信息

      const height = updatedPositions[0].height // 高度值
    })

    // // 创建实体
    const entity = viewer.entities.add({
      // position: Cesium.Cartesian3.fromDegrees(longitude, latitude, height + (coordinates[2] ? coordinates[2] : 0)),
      position: Cesium.Cartesian3.fromDegrees(coordinates[0], coordinates[1], height + (coordinates[2] ? coordinates[2] : 0)),
      // point: {
      //   pixelSize: 10,
      //   color: color,
      //   heightReference: Cesium.HeightReference.NONE,
      // },
      billboard: {
        image: myImg,
        // width 和 height 分别指定图片的宽度和高度，单位为像素
        width: 50,
        height: 50,
        // scaleByDistance 控制随距离变化的尺寸缩放
        heightReference: Cesium.HeightReference.NONE,
        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        pixelOffset: new Cesium.Cartesian2(-10, 5),
        scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1, 1.5e7, 0.01),
      },
      properties: { ...msg, type: type, ASL: height, AGL: msg.position[2] ? msg.position[2] : 0, user_name: msg.user_name },
      id: msg.id
    })
  } else {
    // 使用sampleTerrain方法获取地形的海拔高度
    Cesium.sampleTerrainMostDetailed(viewer.terrainProvider, [Cesium.Cartographic.fromDegrees(coordinates[0], coordinates[1])])
      .then(function (samples) {
        // 获取海拔高度
        const height = samples[0].height
        // // 创建实体
        const entity = viewer.entities.add({
          position: Cesium.Cartesian3.fromDegrees(coordinates[0], coordinates[1], height + (coordinates[2] ? coordinates[2] : 0)),
          // point: {
          //   pixelSize: 10,
          //   color: color,
          //   heightReference: Cesium.HeightReference.NONE,
          // },
          billboard: {
            image: myImg,
            // width 和 height 分别指定图片的宽度和高度，单位为像素
            width: 50,
            height: 50,
            // scaleByDistance 控制随距离变化的尺寸缩放
            heightReference: Cesium.HeightReference.NONE,
            horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            pixelOffset: new Cesium.Cartesian2(-10, 5),
            scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1, 1.5e7, 0.01),
          },
          properties: { ...msg, type: type, ASL: height, AGL: msg.position[2] ? msg.position[2] : 0, user_name: msg.user_name },
          id: msg.id
        })
        console.log('创建成功！')
        // 输出海拔高度
        console.log('海拔高度:', height)
      })
      .catch(function (error) {
        // 处理错误
        console.log('获取地形高度时发生错误:', error)
      })
  }
}
// 根据坐标绘制线段
function positionDrawLineString(msg: any, type: string) {
  const color = Cesium.Color.fromCssColorString(msg.color)
  const coordinates = msg.position
  const positions: any = []
  coordinates.forEach((item: any) => {
    positions.push(Cesium.Cartesian3.fromDegrees(item[0], item[1], item[2] ? item[2] : 0).clone())
  })

  if (positions.length >= 2) {
    const entity = viewer.entities.add({
      polyline: {
        positions: positions,
        width: 5,
        material: color,
        clampToGround: true, // 设置 clampToGround 为 true，实现贴地
      },
      properties: { ...msg, type: type, user_name: msg.user_name },
      id: msg.id
    })
  }
  console.log('创建成功！')
}
// 根据坐标绘制多边形
function positionDrawPolygon(msg: any, type: string) {
  const color = Cesium.Color.fromCssColorString(msg.color)
  const tempPol: any = null
  const positions: any = []// 存放位置数组
  const addPosition: any = [[]]// 存放传递数组位置
  const coordinates = msg.position
  // console.log(coordinates[2] ? coordinates[2] : 0, 'coordinatescoordinates')
  coordinates[0].forEach((item: any) => {
    positions.push(Cesium.Cartesian3.fromDegrees(item[0], item[1], item[2] ? item[2] : 0).clone())
  })
  if (positions.length >= 3) {
    const lineState = computedPolygon(msg.position[0])
    const entity = viewer.entities.add({

      polygon: {
        hierarchy: positions,
        // extrudedHeight: 0,
        material: color,
        clampToGround: true,
        outline: false,
        outlineColor: Cesium.Color.MAGENTA,
        arcType: Cesium.ArcType.RHUMB,
      },
      properties: { ...msg, type: type, position: msg.position, user_name: msg.user_name, maxHeightDifference: lineState.maxHeightDifference },
      id: msg.id
    })
  }
  console.log(msg, '多边形创建成功！')
}
// 计算线段水平距离、高度差、直线距离,传入第一个、最后一个点的经纬度，点的数组
export function computedLine(startPoint: any, endPoint: any, arr: any, Cartesian3?: boolean) {
  console.log(startPoint, endPoint, arr, '计算的各个值')

  const tempArr: any = []
  let startPointCar: any = null
  let endPointCar: any = null
  if (Cartesian3) {
    startPointCar = new Cesium.Cartesian3(startPoint.x, startPoint.y, startPoint.z)
    endPointCar = new Cesium.Cartesian3(endPoint.x, endPoint.y, endPoint.z)
  } else {
    const startTemp = Cesium.Cartesian3.fromDegrees(startPoint[0], startPoint[1], startPoint[2])
    const endTemp = Cesium.Cartesian3.fromDegrees(endPoint[0], endPoint[1], endPoint[2])
    startPointCar = new Cesium.Cartesian3(startTemp.x, startTemp.y, startTemp.z)
    endPointCar = new Cesium.Cartesian3(endTemp.x, endTemp.y, endTemp.z)
    arr.forEach((item: any) => {
      tempArr.push(Cesium.Cartesian3.fromDegrees(item[0], item[1], item[2]))
    })
  }

  // 计算水平距离
  const horizontalDistance = Cesium.Cartesian2.distance(
    new Cesium.Cartesian2(startPointCar.x, startPointCar.y),
    new Cesium.Cartesian2(endPointCar.x, endPointCar.y)
  ).toFixed(1)
  // 计算直线距离
  const straightLineDistance = Cesium.Cartesian3.distance(startPointCar, endPointCar).toFixed(1)
  // 初始化最大高度差
  let maxHeightDifference: any = 0
  // 遍历线段中的每个点，找到最大高度差
  for (let i = 0; i < tempArr.length - 1; i++) {
    const startPoint = tempArr[i]
    const endPoint = tempArr[i + 1]
    // 获取起点和终点的高度
    const startHeight = startPoint.z
    const endHeight = endPoint.z
    // 计算高度差
    const heightDifference = Math.abs(endHeight - startHeight)
    // 更新最大高度差
    maxHeightDifference = Number(Math.max(maxHeightDifference, heightDifference)).toFixed(1)
  }
  return {
    horizontalDistance: horizontalDistance, // 水平
    straightLineDistance: straightLineDistance, // 直线
    maxHeightDifference: maxHeightDifference// 高度
  }
}
// 计算多边形
export function computedPolygon(positionArr: any) {
  const carPositonArr: any = []// 存储转换后的顶点位置
  const distanceArr = []// 存储距离
  // 计算水平周长
  positionArr.forEach((item: any) => {
    const startTemp = Cesium.Cartesian3.fromDegrees(item[0], item[1], item[2])
    carPositonArr.push(startTemp)
  })
  for (let i = 0; i < carPositonArr.length; i++) {
    const sum = Cesium.Cartesian2.distance(
      new Cesium.Cartesian2(carPositonArr[i].x, carPositonArr[i].y),
      new Cesium.Cartesian2(carPositonArr[(i + 1) % carPositonArr.length].x, carPositonArr[(i + 1) % carPositonArr.length].y)
    ).toFixed(1) // 取余操作确保最后一个元素与第一个元素相加
    distanceArr.push(Number(sum))
  }
  const distance = distanceArr.reduce((total, current) => total + current, 0)
  console.log('水平周长', distance)
  // 计算水平面积
  const area = 100
  console.log('水平面积', area)

  // 计算高度差
  // 初始化最大高度差
  let maxHeightDifference: any = 0
  // 遍历线段中的每个点，找到最大高度差
  for (let i = 0; i < carPositonArr.length - 1; i++) {
    const startPoint = carPositonArr[i]
    const endPoint = carPositonArr[i + 1]
    // 获取起点和终点的高度
    const startHeight = startPoint.z
    const endHeight = endPoint.z
    // 计算高度差
    const heightDifference = Math.abs(endHeight - startHeight)
    // 更新最大高度差
    maxHeightDifference = Number(Math.max(maxHeightDifference, heightDifference)).toFixed(1)
  }
  console.log('最大高度差', maxHeightDifference)
  return {
    maxHeightDifference: maxHeightDifference,
    area: area,
    distance: distance
  }
}
// 多选框选择绘制完成后添加点击事件
function drawOff(stores: any) {
  console.log('多选框选择绘制完成后添加点击事件')
  const handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas)
  handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK)
  handler.setInputAction((click: any) => {
    const pickedObject = viewer.scene.pick(click.position)

    // 判断是否有实体被选中
    if (Cesium.defined(pickedObject) && pickedObject.id instanceof Cesium.Entity && !stores.state.mouseToolOpen) {
      // 获取点击的实体
      const selectedEntity = pickedObject.id
      if (selectedEntity._properties._type._value === 'Point') {
        const position = selectedEntity.position.getValue(viewer.clock.currentTime)
        const cartographicPosition = Cesium.Cartographic.fromCartesian(position)
        const longitude = Cesium.Math.toDegrees(cartographicPosition.longitude)
        const latitude = Cesium.Math.toDegrees(cartographicPosition.latitude)
        const height = cartographicPosition.height// 海拔高度

        const msg = {
          // longitude: longitude,
          // latitude: latitude,
          longitude: selectedEntity._properties._position._value[0],
          latitude: selectedEntity._properties._position._value[1],
          // AGL: selectedEntity._properties._AGL._value,
          ASL: selectedEntity._properties._ASL._value,
          AGL: selectedEntity._properties._AGL ? selectedEntity._properties._AGL._value : 0,
          height: height,
          color: selectedEntity._properties._color._value,
          name: selectedEntity._properties._name._value,
          type: selectedEntity._properties._type._value,
          id: selectedEntity._properties._id._value,
          groupId: selectedEntity._properties._groupId._value,
          user_name: selectedEntity._properties._user_name._value
        }
        console.log(msg, '要点击了')

        bus.emit('pointClick', { msg: msg, entity: selectedEntity })
      } else if (selectedEntity._properties._type._value === 'LineString') {
        // const startPoint = selectedEntity.polyline.positions._value[0]
        // const endPoint = selectedEntity.polyline.positions._value[selectedEntity.polyline.positions._value.length - 1]

        // const lineState = computedLine(startPoint, endPoint, selectedEntity.polyline.positions._value, true)
        const startPoint = selectedEntity._properties._position._value[0]
        const endPoint = selectedEntity._properties._position._value[selectedEntity._properties._position._value.length - 1]

        const lineState = computedLine(startPoint, endPoint, selectedEntity._properties._position._value)
        console.log('多选框绘制', lineState)

        const msg = {
          position: selectedEntity._properties._position._value,
          longitude: selectedEntity._properties._position._value[0][0],
          latitude: selectedEntity._properties._position._value[0][1],
          maxHeightDifference: lineState.maxHeightDifference,
          horizontalDistance: lineState.horizontalDistance,
          straightLineDistance: lineState.straightLineDistance,
          color: selectedEntity._properties._color._value,
          name: selectedEntity._properties._name._value,
          type: selectedEntity._properties._type._value,
          id: selectedEntity._properties._id._value,
          groupId: selectedEntity._properties._groupId._value,
          user_name: selectedEntity._properties._user_name._value
        }

        bus.emit('pointClick', { msg: msg, entity: selectedEntity })
      } else if (selectedEntity._properties._type._value === 'Polygon') {
        console.log(selectedEntity._properties, '多边形')
        const polyState = computedPolygon(selectedEntity._properties._position._value[0])
        const msg = {
          position: selectedEntity._properties._position._value,
          longitude: selectedEntity._properties._position._value[0][0][0],
          latitude: selectedEntity._properties._position._value[0][0][1],
          maxHeightDifference: polyState.maxHeightDifference,
          area: polyState.area,
          distance: polyState.distance,
          color: selectedEntity._properties._color._value,
          name: selectedEntity._properties._name._value,
          type: selectedEntity._properties._type._value,
          id: selectedEntity._properties._id._value,
          groupId: selectedEntity._properties._groupId._value,
          user_name: selectedEntity._properties._user_name._value
        }
        bus.emit('pointClick', { msg: msg, entity: selectedEntity })
      }
    } else {
      console.log('No entity selected')
    }
  }, Cesium.ScreenSpaceEventType.LEFT_CLICK)
}
// 标注绘制
export function positionDraw(type: string, msg: any, last?: boolean, stores?: any) {
  switch (type) {
    case 'Point':
      positionDrawPin(msg, type)
      if (last) {
        drawOff(stores)
      }
      break
    case 'Polygon':
      positionDrawPolygon(msg, type)
      if (last) {
        drawOff(stores)
      }
      break
    case 'LineString':
      positionDrawLineString(msg, type)
      if (last) {
        drawOff(stores)
      }
      break
    default:
      console.log(type, '其他绘制')
      break
  }
}
// 标点跳转事件
export function flyToPoint(entityCoordinates: any) {
  console.log('跳转坐标', entityCoordinates)

  const cameraHeight = 700 // 设置视角高度，单位为米
  const destination = Cesium.Cartesian3.fromDegrees(Number(entityCoordinates[0]), Number(entityCoordinates[1]), entityCoordinates[2] + cameraHeight)

  viewer.camera.flyTo({ // 跳转视角
    destination: destination,
    orientation: {
      heading: Cesium.Math.toRadians(0),
      pitch: Cesium.Math.toRadians(-90),
      roll: 0
    },
    duration: 2 // 飞行持续时间，单位为秒
  })
  // 调用 viewer 的 flyTo 方法，将相机飞行到目标位置
  // viewer.flyTo(destination)
}
// 修改坐标点海拔高度
export function changePointHeight(id: any, height: any, name: any, layerState: any) {
  const entities = viewer.entities.values
  let targetEntity: any = null

  for (let i = 0; i < entities.length; i++) {
    const entity = entities[i]
    if (entity._properties && entity._properties._id._value !== undefined) {
      console.log(entity._properties._id._value, id)

      if (entity._properties._id._value === id) { targetEntity = entity }
    }
  }

  if (targetEntity !== null) {
    // 找到匹配的实体
    // console.log('找到匹配的实体：', targetEntity)
    // console.log('修改的海拔', height)
    // console.log('相对高度', Number(layerState.AGL))
    console.log(targetEntity.properties, '修改的实体属性')

    const position = targetEntity.position.getValue(viewer.clock.currentTime)

    const cartographic = Cesium.Cartographic.fromCartesian(position)

    const realNewCartographic = Cesium.Cartographic.fromDegrees(Number(layerState.longitude), Number(layerState.latitude), Number(height))

    const newCartographic = new Cesium.Cartographic(cartographic.longitude, cartographic.latitude, Number(height))
    const newPosition = viewer.scene.globe.ellipsoid.cartographicToCartesian(realNewCartographic)
    targetEntity.properties.name = name// 修改名称
    targetEntity.properties.ASL = Number(height) - Number(layerState.AGL)// 修改ASL
    targetEntity.properties.AGL = Number(layerState.AGL)// 修改AGL
    targetEntity.properties.color = layerState.color// 修改color
    targetEntity.position.setValue(newPosition)
  } else {
    // 未找到匹配的实体
    console.log('未找到匹配的实体')
  }
}
// 修改线段信息
export function changeLineMsg(id: any, name: any) {
  console.log('修改线段实体信息')

  const entities = viewer.entities.values
  let targetEntity: any = null

  for (let i = 0; i < entities.length; i++) {
    const entity = entities[i]
    if (entity._properties && entity._properties._id._value !== undefined) {
      console.log(entity._properties._id._value, id)

      if (entity._properties._id._value === id) { targetEntity = entity }
    }
  }

  if (targetEntity !== null) {
    // 找到匹配的实体

    targetEntity.properties.name = name// 修改名称
  } else {
    // 未找到匹配的实体
    console.log('未找到匹配的实体')
  }
}

// 暴露cesium实例
export function getViewer(): any {
  return viewer
}
// 导出小窗口实例
export function getViewereye(): any {
  return viewereye
}
// 导出摄像机
export function getcameraeye() {
  return spotLightCameraeye
}
export function getcamera() {
  return spotLightCamera
}
