import { reactive } from 'vue'
import { MapDoodleType } from '/@/constants/map'
import { MapDoodleEnum } from '/@/types/map-enum'
import * as Cesium from 'cesium'
import { init3DMap, getViewer, computedLine, computedPolygon } from '../hooks/use-cesium-map'
import { postElementsReq } from '/@/api/layer'
import bus from '/@/utils/mitt'
import { useMyStore } from '/@/store'
import { message } from 'ant-design-vue'
import pointBlue from '/@/assets/waylines/pointBlue.png'
import pointPurple from '/@/assets/waylines/pointPurple.png'
import pointYellow from '/@/assets/waylines/pointYellow.png'
import pointRed from '/@/assets/waylines/pointRed.png'
import pointGreen from '/@/assets/waylines/pointGreen.png'
const store = null
let isRequestOk = true
export function useMouseTool () {
  const state = reactive({
    pinNum: 0,
    polylineNum: 0,
    PolygonNum: 0,
    currentType: '',
  })

  const viewer = getViewer()
  const handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas)
  let longitude:any = null
  let latitude:any = null
  let height:any = null

  // 绘制鼠标点击标点
  async function drawPin (type:MapDoodleType, stores:any) {
    handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK)
    stores.commit('SET_MOUSETOOLOPEN', true)// 用于标记图形处于绘制中，就无法触发实体点击事件
    // root?.$mouseTool.marker({
    //   title: type + state.pinNum,
    //   icon: pin2d8cf0,
    // })
    // state.pinNum++
    // root?.$mouseTool.on('draw', getDrawCallback)
    handler.setInputAction(function (click:any) {
      if (isRequestOk) { // 请求是否完毕
        isRequestOk = false

        const pickRay = viewer.camera.getPickRay(click.position)
        const intersection = viewer.scene.globe.pick(pickRay, viewer.scene)// 查找射线与渲染的地球表面之间的交点。射线必须以世界坐标给出。

        // 获取点击位置处的对象
        const pickedObject = viewer.scene.pick(click.position)
        // 如果点击到了实体
        if (Cesium.defined(pickedObject) && pickedObject.id instanceof Cesium.Entity) {
          console.log('存在实体')
        } else {
          if (Cesium.defined(intersection)) { // 如果定义了对象，则返回 true，否则返回 false。
            const cartographicPosition = viewer.scene.globe.ellipsoid.cartesianToCartographic(intersection)// 将提供的笛卡尔坐标转换为制图表示
            longitude = Cesium.Math.toDegrees(cartographicPosition.longitude)
            latitude = Cesium.Math.toDegrees(cartographicPosition.latitude)
            height = cartographicPosition.height

            bus.on('sendReq', async (obj:any) => { // 在请求完成获取id后在地图上画点
              const result = await postElementsReq(obj.shareId, obj.req)// 请求增加标注
              const msg = {
                longitude: longitude,
                latitude: latitude,
                height: height,
                color: obj.req.resource.content.properties.color,
                name: obj.req.name,
                type: obj.req.resource.content.geometry.type,
                id: result.data.id,
                groupId: obj.shareId,
                ASL: height,
                position: [longitude, latitude, 0],
                user_name: result.data.userName
              // position: [longitude, latitude, height]
              }

              const color = Cesium.Color.fromCssColorString(obj.req.resource.content.properties.color)
              console.log(obj.req.resource.content.properties.color, color, '标注颜色')

              const entitys = viewer.entities.add({
                position: Cesium.Cartesian3.fromDegrees(longitude, latitude, height),
                // point: {
                //   pixelSize: 10,
                //   color: color,
                //   // color: Cesium.Color.RED,
                //   // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                //   // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
                //   heightReference: Cesium.HeightReference.NONE,

                // },
                billboard: {
                  image: pointBlue,
                  // width 和 height 分别指定图片的宽度和高度，单位为像素
                  width: 50,
                  height: 50,
                  // scaleByDistance 控制随距离变化的尺寸缩放
                  heightReference: Cesium.HeightReference.NONE,
                  horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                  verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                  pixelOffset: new Cesium.Cartesian2(-10, 5),
                  scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1, 1.5e7, 0.01),
                },
                id: msg.id,
                properties: msg
              })

              bus.emit('setLayers', { id: result.data.id, user_name: result.data.userName })
              bus.off('sendReq')// 执行后销毁事件总线
              isRequestOk = true
            })
            bus.emit('draw', [longitude, latitude, height])
          }
        }
      } else {
        message.error('绘制中，请慢一点')
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

    // // 右键取消标点
    // handler.setInputAction(() => {
    //   const entities = viewer.entities
    //   const length = entities.values.length
    //   entities.remove(entities.values[length - 1])
    // }, Cesium.ScreenSpaceEventType.RIGHT_CLICK)
  }
  let isDrawLinePolygon = false// 代表不处于绘画中
  let positions:any = []// 存放位置数组
  let addPosition:any = []// 存放传递数组位置
  // 绘制鼠标点击线段
  function drawPolyline (type:MapDoodleType, stores:any) {
    handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK,)
    stores.commit('SET_MOUSETOOLOPEN', true)// 用于标记图形处于绘制中，就无法触发实体点击事件
    // root?.$mouseTool.polyline({
    //   strokeColor: '#2d8cf0',
    //   strokeOpacity: 1,
    //   strokeWeight: 2,
    //   strokeStyle: 'solid',
    //   draggable: true,
    //   title: type + state.polylineNum++
    // })
    // root?.$mouseTool.on('draw', getDrawCallback)
    positions = []
    addPosition = []
    handler.setInputAction((movement:any) => {
      const pickRay = viewer.camera.getPickRay(movement.position)
      const intersection = viewer.scene.globe.pick(pickRay, viewer.scene)// 查找射线与渲染的地球表面之间的交点。射线必须以世界坐标给出。
      const cartographicPosition = viewer.scene.globe.ellipsoid.cartesianToCartographic(intersection)// 将提供的笛卡尔坐标转换为制图表示。笛卡尔在椭圆体的中心未定义。
      const longitude = Cesium.Math.toDegrees(cartographicPosition.longitude)
      const latitude = Cesium.Math.toDegrees(cartographicPosition.latitude)
      const height = cartographicPosition.height
      // 获取点击位置处的对象
      const pickedObject = viewer.scene.pick(movement.position)
      // 如果点击到了实体
      if (Cesium.defined(pickedObject) && pickedObject.id instanceof Cesium.Entity) {
        // const selectedEntity = pickedObject.id
        // console.log(selectedEntity, 'selectedEntity点击的实体')
        // 先删除点和线段
        const entityCollection = viewer.entities
        for (let i = 0; i < positions.length; i++) {
          const entityId1 = i + 'point'
          const entityId2 = i + 'line'

          const entity1 = entityCollection.getById(entityId1)
          const entity2 = entityCollection.getById(entityId2)
          const lineArr = []
          if (entity1) {
            entityCollection.remove(entity1)
          }
          if (entity2) {
            lineArr.push(entity2)
          }
          if (i === positions.length - 1) {
            lineArr.push(entityCollection.getById(positions.length + 'line'))
            lineArr.forEach((entity: any) => {
              entityCollection.remove(entity)
            })
          }
        }
        // 删除完毕后发送创建请求
        if (positions.length >= 2) { // 位置数组中包含至少 2 个点
          bus.on('sendReq', async (obj:any) => { // 在请求完成获取id后在地图上画点
            console.log(obj, '线段中的')
            // 先计算额外属性
            const startPoint = obj.req.resource.content.geometry.coordinates[0]
            const endPoint = obj.req.resource.content.geometry.coordinates[obj.req.resource.content.geometry.coordinates.length - 1]
            const lineState = computedLine(startPoint, endPoint, obj.req.resource.content.geometry.coordinates)
            const result = await postElementsReq(obj.shareId, obj.req)// 请求增加标注
            const msg = {
              position: addPosition,
              longitude: longitude,
              latitude: latitude,
              height: height,
              maxHeightDifference: lineState.maxHeightDifference,
              horizontalDistance: lineState.horizontalDistance,
              straightLineDistance: lineState.straightLineDistance,
              color: obj.req.resource.content.properties.color,
              name: obj.req.name,
              type: obj.req.resource.content.geometry.type,
              id: result.data.id,
              groupId: obj.shareId,
              user_name: result.data.userName
            }
            const color = Cesium.Color.fromCssColorString(obj.req.resource.content.properties.color)
            // 创建多段线实体
            console.log('创建实体', positions)

            const entitys = viewer.entities.add({
              polyline: {
                positions: positions,
                width: 5,
                material: color,
                clampToGround: true, // 设置 clampToGround 为 true，实现贴地
              },
              id: msg.id,
              properties: msg
            })
            isDrawLinePolygon = false // 绘画完成
            positions = []// 存放位置数组
            addPosition = []// 存放传递数组位置
            bus.emit('setLayers', { id: result.data.id, user_name: result.data.userName })
            bus.off('sendReq')// 执行后销毁事件总线
          })

          bus.emit('draw', addPosition)
        }
      } else {
        isDrawLinePolygon = true // 在绘画中
        // 标点同时画线
        const entitys = viewer.entities.add({
          position: Cesium.Cartesian3.fromDegrees(longitude, latitude, height),
          point: {
            pixelSize: 10,
            color: Cesium.Color.fromCssColorString('#1890FF'),
            heightReference: Cesium.HeightReference.NONE,
          },
          id: positions.length + 'point'
        })
        addPosition.push([longitude, latitude, height])
        positions.push(intersection.clone()) // 添加当前位置点到位置数组
        if (positions.length >= 2) {
          console.log(positions.length + 'line', '画线')

          // 创建多段线实体
          const entitysLine = viewer.entities.add({
            polyline: {
              positions: positions,
              width: 5,
              material: Cesium.Color.fromCssColorString('#1890FF'),
              clampToGround: true, // 设置 clampToGround 为 true，实现贴地
            },
            id: positions.length + 'line'
          })
        }
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK)
  }
  // 平面多边形
  function drawPolygon (type:MapDoodleType, stores:any) {
    handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK,)
    stores.commit('SET_MOUSETOOLOPEN', true)// 用于标记图形处于绘制中，就无法触发实体点击事件

    // root?.$mouseTool.polygon({
    //   strokeColor: '#2d8cf0',
    //   strokeOpacity: 1,
    //   strokeWeight: 2,
    //   fillColor: '#1791fc',
    //   fillOpacity: 0.4,
    //   draggable: true,
    //   title: type + state.PolygonNum++
    // })
    // root?.$mouseTool.on('draw', getDrawCallback)
    const tempPol:any = null
    positions = []// 存放位置数组
    addPosition = [[]]// 存放传递数组位置
    const normalPosition:any = []// 存放正常坐标
    handler.setInputAction((movement:any) => {
      const pickRay = viewer.camera.getPickRay(movement.position)
      const intersection = viewer.scene.globe.pick(pickRay, viewer.scene)// 查找射线与渲染的地球表面之间的交点。射线必须以世界坐标给出。
      const cartographicPosition = viewer.scene.globe.ellipsoid.cartesianToCartographic(intersection)// 将提供的笛卡尔坐标转换为制图表示。笛卡尔在椭圆体的中心未定义。
      const longitude = Cesium.Math.toDegrees(cartographicPosition.longitude)
      const latitude = Cesium.Math.toDegrees(cartographicPosition.latitude)
      const height = cartographicPosition.height
      addPosition[0].push([longitude, latitude, height])
      positions.push(intersection.clone()) // 添加当前位置点到位置数组

      // 获取点击位置处的对象
      const pickedObject = viewer.scene.pick(movement.position)
      // 如果点击到了实体
      if (Cesium.defined(pickedObject) && pickedObject.id instanceof Cesium.Entity) {
        // 先删除点和多边形
        const entityCollection = viewer.entities
        for (let i = 0; i < positions.length; i++) {
          const entityId1 = i + 'point'
          const entityId2 = i + 'polygon'

          const entity1 = entityCollection.getById(entityId1)
          const entity2 = entityCollection.getById(entityId2)
          const lineArr = []
          if (entity1) {
            entityCollection.remove(entity1)
          }
          if (entity2) {
            lineArr.push(entity2)
          }
          if (i === positions.length - 1) {
            lineArr.push(entityCollection.getById(positions.length + 'line'))
            lineArr.forEach((entity: any) => {
              entityCollection.remove(entity)
            })
          }
        }
        // 删除完毕后发送请求
        if (positions.length >= 3) {
          positions.pop()
          console.log(positions, '多边形位置')
          viewer.entities.remove(tempPol)
          bus.on('sendReq', async (obj:any) => { // 在请求完成获取id后在地图上画点
            const result = await postElementsReq(obj.shareId, obj.req)// 请求增加标注
            // 计算额外属性
            const lineState = computedPolygon(obj.req.resource.content.geometry.coordinates[0])
            console.log(obj.req.resource.content.geometry.coordinates[0], 'obj.req.resource.content.geometry.coordinates[0]')

            const msg = {
              position: addPosition,
              longitude: longitude,
              latitude: latitude,
              height: height,
              maxHeightDifference: lineState.maxHeightDifference,
              area: lineState.area,
              distance: lineState.distance,
              color: obj.req.resource.content.properties.color,
              name: obj.req.name,
              type: obj.req.resource.content.geometry.type,
              id: result.data.id,
              groupId: obj.shareId,
              user_name: result.data.userName
            }
            const color = Cesium.Color.fromCssColorString(obj.req.resource.content.properties.color)
            console.log(color, 4444444)

            // // 创建多边形实体
            const entitys = viewer.entities.add({
              polygon: {
                hierarchy: positions,
                // extrudedHeight: 0,
                material: color,
                clampToGround: true,
                outline: false,
                outlineColor: Cesium.Color.MAGENTA,
                arcType: Cesium.ArcType.RHUMB,
              },
              properties: msg,
              id: msg.id
            })
            isDrawLinePolygon = false // 绘画完成
            positions = []// 存放位置数组
            addPosition = [[]]// 存放传递数组位置
            bus.off('sendReq')// 执行后销毁事件总线
            bus.emit('setLayers', { id: result.data.id, user_name: result.data.userName })
          })
          addPosition[0].pop()
          bus.emit('draw', addPosition)
        }
      } else {
        isDrawLinePolygon = true // 在绘画中
        // 标点同时画线
        const entitys = viewer.entities.add({
          position: Cesium.Cartesian3.fromDegrees(longitude, latitude, height),
          point: {
            pixelSize: 10,
            color: Cesium.Color.fromCssColorString('#1890FF'),
            heightReference: Cesium.HeightReference.NONE,
          },
          id: positions.length + 'point'
        })
        // addPosition[0][0].push([longitude, latitude, height])

        // positions.push(intersection.clone()) // 添加当前位置点到位置数组
        if (positions.length >= 3) {
          // // 创建多边形实体
          const entitys = viewer.entities.add({
            polygon: {
              hierarchy: positions,
              // extrudedHeight: 0,
              material: Cesium.Color.fromCssColorString('#1890FF8A'),
              clampToGround: true,
              outline: false,
              outlineColor: Cesium.Color.MAGENTA,
              arcType: Cesium.ArcType.RHUMB,
            },
            id: positions.length + 'polygon'
          })
        }
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK)
  }
  // 使用鼠标绘制后添加点击事件
  function drawOff (type:MapDoodleType, stores:any) {
    if (isDrawLinePolygon) {
      // 先删除点和线段
      const entityCollection = viewer.entities
      for (let i = 0; i < positions.length; i++) {
        const entityId1 = i + 'point'
        const entityId2 = i + 'line'
        const entityId3 = i + 'polygon'
        const entity1 = entityCollection.getById(entityId1)
        const entity2 = entityCollection.getById(entityId2)
        const entity3 = entityCollection.getById(entityId2)
        const lineArr1 = []
        const lineArr2 = []
        if (entity1) {
          entityCollection.remove(entity1)
        }
        if (entity2) {
          lineArr1.push(entity2)
        }
        if (entity3) {
          lineArr2.push(entity3)
        }
        if (i === positions.length - 1) {
          lineArr1.push(entityCollection.getById(positions.length + 'line'))
          lineArr1.forEach((entity: any) => {
            entityCollection.remove(entity)
          })
          lineArr2.push(entityCollection.getById(positions.length + 'polygon'))
          lineArr2.forEach((entity: any) => {
            entityCollection.remove(entity)
          })
        }
      }
    }
    stores.commit('SET_MOUSETOOLOPEN', false)// 绘制完毕，可以触发

    handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK)
    handler.setInputAction((click:any) => {
      console.log('鼠标绘制后添加点击事件')

      // 获取点击位置处的对象
      const pickedObject = viewer.scene.pick(click.position)
      if (Cesium.defined(pickedObject) && pickedObject.id instanceof Cesium.Entity && !stores.state.mouseToolOpen) {
        const selectedEntity = pickedObject.id
        // console.log(selectedEntity, 'selectedEntity点击的实体')
        if (selectedEntity._properties._type._value === 'Point') {
          const position = selectedEntity.position.getValue(viewer.clock.currentTime)
          const cartographicPosition = Cesium.Cartographic.fromCartesian(position)
          const longitude = Cesium.Math.toDegrees(cartographicPosition.longitude)
          const latitude = Cesium.Math.toDegrees(cartographicPosition.latitude)
          const height = cartographicPosition.height
          const msg = {
            longitude: longitude,
            latitude: latitude,
            // longitude: selectedEntity._properties._position._value[0],
            // latitude: selectedEntity._properties._position._value[0],
            height: height,
            ASL: selectedEntity._properties._ASL._value,
            AGL: selectedEntity._properties._AGL ? selectedEntity._properties._AGL._value : 0,
            color: selectedEntity._properties._color._value,
            name: selectedEntity._properties._name._value,
            type: selectedEntity._properties._type._value,
            id: selectedEntity._properties._id._value,
            groupId: selectedEntity._properties._groupId._value,
            user_name: selectedEntity._properties._user_name._value,
          }
          console.log('pointClick之前的msg', msg)

          bus.emit('pointClick', { msg: msg, entity: selectedEntity })
        } else if (selectedEntity._properties._type._value === 'LineString') {
          const startPoint = selectedEntity.polyline.positions._value[0]
          const endPoint = selectedEntity.polyline.positions._value[selectedEntity.polyline.positions._value.length - 1]
          const lineState = computedLine(startPoint, endPoint, selectedEntity.polyline.positions._value, true)

          const msg = {
            positon: selectedEntity._properties._position._value,
            longitude: selectedEntity._properties._longitude._value,
            latitude: selectedEntity._properties._latitude._value,
            maxHeightDifference: lineState.maxHeightDifference,
            horizontalDistance: lineState.horizontalDistance,
            straightLineDistance: lineState.straightLineDistance,
            color: selectedEntity._properties._color._value,
            name: selectedEntity._properties._name._value,
            type: selectedEntity._properties._type._value,
            id: selectedEntity._properties._id._value,
            groupId: selectedEntity._properties._groupId._value,
            user_name: selectedEntity._properties._user_name._value,
          }
          console.log('pointClick之前的msg', msg)
          bus.emit('pointClick', { msg: msg, entity: selectedEntity })
        } else if (selectedEntity._properties._type._value === 'Polygon') {
          console.log(selectedEntity._properties, '多边形')
          const msg = {
            position: selectedEntity._properties._position._value,
            longitude: selectedEntity._properties._position._value[0][0][0],
            latitude: selectedEntity._properties._position._value[0][0][1],
            maxHeightDifference: selectedEntity._properties._maxHeightDifference._value,
            area: selectedEntity._properties._area._value,
            distance: selectedEntity._properties._distance._value,
            color: selectedEntity._properties._color._value,
            name: selectedEntity._properties._name._value,
            type: selectedEntity._properties._type._value,
            id: selectedEntity._properties._id._value,
            groupId: selectedEntity._properties._groupId._value,
            user_name: selectedEntity._properties._user_name._value,
          }
          bus.emit('pointClick', { msg: msg, entity: selectedEntity })
        }
      } else {
        console.log('No entity selected')
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

    // root?.$mouseTool.close()
    // root?.$mouseTool.off('draw')
  }

  function mouseTool (type: MapDoodleType, stores:any) {
    state.currentType = type

    switch (type) {
      case MapDoodleEnum.PIN:
        drawPin(type, stores)
        break
      case MapDoodleEnum.POLYLINE:
        drawPolyline(type, stores)
        break
      case MapDoodleEnum.POLYGON:
        drawPolygon(type, stores)
        break
      case MapDoodleEnum.Close:
        drawOff(type, stores)
        break
    }
  }

  return {
    mouseTool
  }
}
