import AMapLoader from '@amap/amap-jsapi-loader'
import { App, reactive } from 'vue'
import { AMapConfig } from '/@/constants/index'
import { getApp, getRoot } from '/@/root'
import { useMyStore } from '/@/store'
import marker1 from '/@/assets/icons/markers.png'
import bus from '/@/utils/mitt'
import { getProjectInfo, updateProjectInfo } from '/@/api/manage'
import Cesium from 'cesium'

export function useGMapManage () {
  const state = reactive({
    aMap: null, // Map类
    map: null, // 地图对象
    mouseTool: null,
  })
  const root = getRoot()
  const store = useMyStore()

  // getProjectInfo().then(res => {
  //   console.log(res, '最开始的时候')
  //   store.commit('SET_PROJECT', res.data)
  // })
  async function initMap (container: string, app:App) {
    // window._AMapSecurityConfig = {
    //   serviceHost: 'http://test.zszc-xsm.com/_AMapService',
    //   // 例如 ：serviceHost:'http://1.1.1.1:80/_AMapService',
    // }
    window._AMapSecurityConfig = {
      securityJsCode: '4fc7ac85af6b7be0bf7cb4c51e2e2e66',
    }
    AMapLoader.load({
      ...AMapConfig
    }).then((AMap) => {
      console.log('挂载高德地图api')

      state.aMap = AMap
      state.map = new AMap.Map(container, {
        pitch: 75,
        viewMode: '2D', // 地图模式
        // terrain: true, // 开启地形图
        layers: [new AMap.TileLayer.Satellite(), new AMap.TileLayer.RoadNet()],
        zoom: 15,
        center: [store.state.projectMsg.longitude, store.state.projectMsg.latitude],
        // center: [113.943225499, 22.577673716],

      })

      // 挂在到全局
      app.config.globalProperties.$aMap = state.aMap
      app.config.globalProperties.$map = state.map
      app.config.globalProperties.$mouseTool = state.mouseTool
      const root = getRoot()
      const AMaps = root.$aMap

      // 创建航线绘图
      // state.map.on('click', (e) => {
      //   if (root.$route.name === 'create-wayline') {
      //     const coordinates = [e.lnglat.lng, e.lnglat.lat, 0]
      //     const map = root.$map
      //     const AMap = root.$aMap

      //     const markerContent = '' +
      //     '<div class="content-markerrr">' +
      //     `   <img src="${marker1}">` +
      //     `   <div class="${(store.state.waylineIndex + 1) > 9 ? 'text-markerrrs' : 'text-markerrr'}">${store.state.waylineIndex + 1}</div>` +
      //     '</div>'
      //     const marker = new AMap.Marker({
      //       position: coordinates,
      //       map: map,
      //       content: markerContent,
      //       offset: new AMap.Pixel(-13, -30),
      //     })

      //     store.commit('PUSH_POTIN', [coordinates, marker])// 录入信息
      //     const path:any = []
      //     store.state.waylineCoordinates.forEach((item:any) => {
      //       path.push(new AMap.LngLat(item[0], item[1]))
      //     })
      //     console.log(path)

      //     const polyline = new AMap.Polyline({
      //       path: path,
      //       borderWeight: 10, // 线条宽度，默认为 1
      //       strokeColor: 'red', // 线条颜色
      //       lineJoin: 'round' // 折线拐点连接处样式
      //     })
      //     map.add(polyline)

      //     store.commit('ADD_POINTINDEX')// 录入信息
      //     marker.on('click', (e) => {
      //       const event = e
      //       bus.emit('click', { event: event, test: coordinates, index: e._amap_id })
      //     })
      //   }
      // })

      // state.mouseTool = new AMap.MouseTool(state.map)
    }).catch(e => {
      console.log(e)
    })
  }

  // function initPage (PathSimplifier) {
  //   // 创建组件实例
  //   const pathSimplifierIns = new PathSimplifier({
  //     zIndex: 100,
  //     map: map, // 所属的地图实例
  //     getPath: function (pathData, pathIndex) {
  //       // 返回轨迹数据中的节点坐标信息，[AMap.LngLat, AMap.LngLat...] 或者 [[lng|number,lat|number],...]
  //       return pathData.path
  //     },
  //     getHoverTitle: function (pathData, pathIndex, pointIndex) {
  //       // 返回鼠标悬停时显示的信息
  //       if (pointIndex >= 0) {
  //         // 鼠标悬停在某个轨迹节点上
  //         return pathData.name + '，点:' + pointIndex + '/' + pathData.path.length
  //       }
  //       // 鼠标悬停在节点之间的连线上
  //       return pathData.name + '，点数量' + pathData.path.length
  //     },
  //     renderOptions: {
  //       // 轨迹线的样式
  //       pathLineStyle: {
  //         strokeStyle: 'red',
  //         lineWidth: 6,
  //         dirArrowStyle: true
  //       }
  //     }
  //   })

  //   // 这里构建两条简单的轨迹，仅作示例
  //   pathSimplifierIns.setData([{
  //     name: '轨迹0',
  //     path: [
  //       [100.340417, 27.376994],
  //       [108.426354, 37.827452],
  //       [113.392174, 31.208439],
  //       [124.905846, 42.232876]
  //     ]
  //   }, {
  //     name: '大地线',
  //     // 创建一条包括500个插值点的大地线
  //     path: PathSimplifier.getGeodesicPath([116.405289, 39.904987], [87.61792, 43.793308], 500)
  //   }])

  //   // 创建一个巡航器
  //   const navg0 = pathSimplifierIns.createPathNavigator(0, // 关联第1条轨迹
  //     {
  //       loop: true, // 循环播放
  //       speed: 1000000
  //     })

  //   navg0.start()
  // }

  function globalPropertiesConfig (app:App) {
    console.log('初始化高德方法')

    // initMap('g-container', app)
    initMap('g-container2', app)
  }

  return {
    globalPropertiesConfig,
  }
}
