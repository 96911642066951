<template>
  <div class="g-map-wrapper">
    <!-- 地图区域 -->
    <div id="content">
      <!--width: calc(100% - 449px )  -->
      <div style="position: relative; height: 100%; width: 100%;">
        <div id="g-container" :style="{ height: '100%', overflow: 'hidden' }">

        </div>
        <div id="g-container2" :style="{ height: '0%', width: '0%', overflow: 'hidden' }"></div>

        <div v-show="showplan" class="infoplan">
          <div class="pll">ASL:-- m</div>
          <div class="pll">HAE:-- m</div>
          <img class="xz" :src="xz" alt="">
          <div class="plr"> WGS84</div>
          <div class="pll">
            <div class="yuan"></div> 百分百
          </div>
          <div v-if="barWidth" class="scale-bar" :style="{ width: barWidth + 'px' }"></div>
          <div class="pll">{{ distanceLabel || "out of range" }}</div>

        </div>
        <div class="tb">
          <div class="tbt" @click="flydock"></div>
          <div class="tbb">
            <div class="goo" @mousedown.native="caforward"></div>
            <div class="goo" @mousedown.native="cabackward"></div>
          </div>
        </div>
      </div>

      <div v-show="root.$route.name === ERouterName.CREATE_WAYLINE" id="right">
        <div class="infoboxs">
          <div class="infobox">
            <h5>航线长度</h5>
            <h5>{{ waylineLength }} m</h5>
          </div>
          <div class="infobox">
            <h5>预计执行时间</h5>
            <h5>0 s</h5>
          </div>
          <div class="infobox">
            <h5>航点数</h5>
            <h5>{{ pointNum }}</h5>
          </div>
          <div class="infobox">
            <h5>媒体数</h5>
            <h5>{{ mediaNum }}</h5>
          </div>
        </div>
        <div class="hdj">
          <div v-for="(item) in store.state.waylinePointMsg" :key="item.Point.longitude" class="hd">
            <div class="hd-header">
              <div>
                <img style="cursor: pointer" @click="gotoPoint(item)" :src="markers" alt="">
                航点{{ item.index + 1 }}
              </div>
              <img class="ff" style="cursor: pointer;" :src="pointDelete" alt="" @click="deletePoint(item)" />

            </div>
            <!-- {{ item.action.length }} -->
            <div class="hd-imgs">
              <div class="imgs-item" v-for="items in item.action" :key="items">
                <span v-if="items['wpml:actionActuatorFunc'] === 'orientedShoot'">
                  <img :src="paizhao" alt="">
                </span>
                <span v-else-if="items['wpml:actionActuatorFunc'] === 'startRecord'">
                  <img :src="shexiang" alt="">
                </span>
                <span v-else-if="items['wpml:actionActuatorFunc'] === 'stopRecord'">
                  <img :src="shexiang" alt="">
                </span>
              </div>
            </div>

          </div>
        </div>
        <div v-show="showplan" id="credit"></div>
        <!-- <div v-show="showplan" id="eye"></div> -->
        <!-- <div v-show="showplan" id="pic">
          <img :src="picurl" alt="">
        </div> -->
      </div>
      <div v-show="showplan" class="makeplan">

        <div class="planbody">
          <div class="planlt">
            <div class="modeltitle">无人机控制</div>
            <div class="planbtns">
              <div class="ptl">
                <div class="planbtn">
                  <span>Q</span>
                  <div @mousedown.native="moudown('KeyQ')" @mouseup.native="mouup"
                    :class="{ 'down': clickbtn == 'KeyQ' }">
                    <img :src="leftrotation" alt="">
                  </div>
                </div>
                <div class="planbtn">
                  <span>W</span>
                  <div @mousedown.native="moudown('KeyW')" @mouseup.native="mouup"
                    :class="{ 'down': clickbtn == 'KeyW' }">
                    <img :src="forward" alt="">
                  </div>
                </div>
                <div class="planbtn">
                  <span>E</span>
                  <div @mousedown.native="moudown('KeyE')" @mouseup.native="mouup"
                    :class="{ 'down': clickbtn == 'KeyE' }">
                    <img :src="rightrotation" alt="">
                  </div>
                </div>
                <div class="planbtn">

                  <div @mousedown.native="moudown('KeyA')" @mouseup.native="mouup"
                    :class="{ 'down': clickbtn == 'KeyA' }">
                    <img :src="left" alt="">
                  </div>
                  <span>A</span>
                </div>
                <div class="planbtn">

                  <div @mousedown.native="moudown('KeyS')" @mouseup.native="mouup"
                    :class="{ 'down': clickbtn == 'KeyS' }">
                    <img :src="back" alt="">
                  </div>
                  <span>S</span>
                </div>
                <div class="planbtn">

                  <div @mousedown.native="moudown('KeyD')" @mouseup.native="mouup"
                    :class="{ 'down': clickbtn == 'KeyD' }">
                    <img :src="right" alt="">
                  </div>
                  <span>D</span>
                </div>

              </div>
              <div class="plline"></div>
              <div class="ptl" style="width: 30px;">
                <div class="planbtn">
                  <span>C</span>
                  <div style="background-color: #3A63F3;" @mousedown.native="moudown('KeyC')" @mouseup.native="mouup"
                    :class="{ 'down': clickbtn == 'KeyC' }">
                    升
                  </div>
                </div>

                <div class="planbtn">
                  <div style="background-color: #3A63F3;" @mousedown.native="moudown('KeyZ')" @mouseup.native="mouup"
                    :class="{ 'down': clickbtn == 'KeyZ' }">
                    降
                  </div>
                  <span>Z</span>
                </div>
              </div>

            </div>
            <div @mousedown.native="moudown('Space')" @mouseup.native="mouup" :class="{ 'kg kgd': clickbtn == 'Space' }"
              class="kg">
              创建航点（Space）
            </div>
          </div>
          <div class="planlt ll">
            <div class="modeltitle">模拟飞行</div>
            <div class="bp">
              <h5>{{ flyhead }}°</h5>
              <span>NW</span>
              <div>
                <img :src="jt" alt="">

              </div>
            </div>
          </div>
          <div class="planlt rr">
            <div class="modeltitle">镜头控制</div>
            <div class="carposs">
              <div class="carpos">
                <div class="posbtns" :class="{ 'btd posbtns': clickbtn == 'ArrowUp' }"
                  @mousedown.native="moudown('ArrowUp')" @mouseup.native="mouup"><img :src="forward" alt=""></div>
                <div class="posbot">
                  <div class="posbtns" :class="{ 'btd posbtns': clickbtn == 'ArrowLeft' }"
                    @mousedown.native="moudown('ArrowLeft')" @mouseup.native="mouup"><img :src="left" alt=""></div>
                  <div class="posbtns" :class="{ 'btd posbtns': clickbtn == 'ArrowDown' }"
                    @mousedown.native="moudown('ArrowDown')" @mouseup.native="mouup"><img :src="back" alt=""></div>
                  <div class="posbtns" :class="{ 'btd posbtns': clickbtn == 'ArrowRight' }"
                    @mousedown.native="moudown('ArrowRight')" @mouseup.native="mouup"><img :src="right" alt=""></div>
                </div>
              </div>
              <div class="carmr">
                <div class="carmh">
                  <h6>R</h6>
                  <img :src="sx" alt="">
                </div>
                <div class="carmh">
                  <h6>F</h6>
                  <img :src="carm" alt="">
                </div>
              </div>
            </div>
            <div class="carboo">

              <div @click="nextPoint('pre')" class="godbox">
                上一个航点
              </div>

              <div @click="nextPoint('next')" class="godbox">
                下一个航点
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- 绘制面板   toolshow   -->
    <div v-if="root.$route.name === ERouterName.LAYER" class="g-action-panel"
      :style="{ right: drawVisible ? '30px' : createWaylineVisible === '1' ? '30px' : createWaylineVisible === '2' ? '610px' : '16px' }">
      <div :class="state.currentType === 'pin' ? 'g-action-item selection' : 'g-action-item'" @click="draw('pin', true)">
        <a><a-image :src="pin" :preview="false" /></a>
      </div>
      <div :class="state.currentType === 'polyline' ? 'g-action-item selection' : 'g-action-item'"
        @click="draw('polyline', true)">
        <a>
          <LineOutlined :rotate="135" class="fz20" />
        </a>
      </div>
      <div :class="state.currentType === 'polygon' ? 'g-action-item selection' : 'g-action-item'"
        @click="draw('polygon', true)">
        <a>
          <BorderOutlined class="fz18" />
        </a>
      </div>
      <div v-if="mouseMode" class="g-action-item" @click="draw('off', false)">
        <a style="color: red;">
          <CloseOutlined />
        </a>
      </div>
    </div>
    <!-- 飞机OSD -->
    <!-- <div v-if="osdVisible.visible && !osdVisible.is_dock" class="osd-panel fz12">
      <div class="pl5 pr5 flex-align-center flex-row flex-justify-between"
        style="border-bottom: 1px solid #515151; height: 18%;">
        <span>{{ osdVisible.callsign }}</span>
        <span><a class="fz16" style="color: white;" @click="() => osdVisible.visible = false">
            <CloseOutlined />
          </a></span>
      </div>
      <div style="height: 82%;">
        <div class="flex-column flex-align-center flex-justify-center"
          style="margin-top: -5px; padding-top: 25px; float: left; width: 60px; background: #2d2d2d;">
          <a-tooltip :title="osdVisible.model">
            <div style="width: 90%;" class="flex-column flex-align-center flex-justify-center">
              <span><a-image :src="M30" :preview="false" /></span>
              <span>{{ osdVisible.model }}</span>
            </div>
          </a-tooltip>
        </div>
        <div class="osd">
          <a-row>
            <a-col span="16"
              :style="deviceInfo.device.mode_code === EModeCode.Disconnected ? 'color: red; font-weight: 700;' : 'color: rgb(25,190,107)'">{{
                EModeCode[deviceInfo.device.mode_code] }}</a-col>
          </a-row>
          <a-row>
            <a-col span="6">
              <a-tooltip title="Signal strength">
                <span>HD</span>
                <span class="ml10">{{ deviceInfo.gateway?.transmission_signal_quality }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="RC Battery Level">
                <span>
                  <ThunderboltOutlined class="fz14" />
                </span>
                <span class="ml10">{{ deviceInfo.gateway && deviceInfo.gateway.capacity_percent !== str ?
                  deviceInfo.gateway.capacity_percent + ' %' : deviceInfo.gateway.capacity_percent }}</span>
              </a-tooltip>
            </a-col>

            <a-col span="6">
              <a-tooltip title="Drone Battery Level">
                <span>
                  <ThunderboltOutlined class="fz14" />
                </span>
                <span class="ml10">{{ deviceInfo.device.battery.capacity_percent !== str ?
                  deviceInfo.device.battery.capacity_percent + ' %' : deviceInfo.device.battery.capacity_percent }}</span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row>
            <a-tooltip title="RTK Fixed">
              <a-col span="6" class="flex-row flex-align-center flex-justify-start">
                <span>Fixed</span>
                <span class="ml10 circle"
                  :style="deviceInfo.device.position_state.is_fixed === 1 ? 'backgroud: rgb(25,190,107);' : ' background: red;'"></span>
              </a-col>
            </a-tooltip>
            <a-col span="6">
              <a-tooltip title="GPS">
                <span>GPS</span>
                <span class="ml10">{{ deviceInfo.device.position_state.gps_number }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="RTK">
                <span>
                  <TrademarkOutlined class="fz14" />
                </span>
                <span class="ml10">{{ deviceInfo.device.position_state.rtk_number }}</span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="6">
              <a-tooltip title="Flight Mode">
                <span>
                  <ControlOutlined class="fz16" />
                </span>
                <span class="ml10">{{ EGear[deviceInfo.device.gear] }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Altitude above sea level">
                <span>ASL</span>
                <span class="ml10">{{ deviceInfo.device.height === str ? str : deviceInfo.device.height.toFixed(2) +
                  'm' }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Altitude above takeoff level">
                <span>ALT</span>
                <span class="ml10">{{ deviceInfo.device.elevation === str ? str : deviceInfo.device.elevation.toFixed(2) +
                  ' m' }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Distance to Home Point">
                <span>H</span>
                <span class="ml10">{{ deviceInfo.device.home_distance === str ? str :
                  deviceInfo.device.home_distance.toFixed(2) + ' m' }}</span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="6">
              <a-tooltip title="Horizontal Speed">
                <span>H.S</span>
                <span class="ml10">{{ deviceInfo.device.horizontal_speed === str ? str :
                  deviceInfo.device.horizontal_speed.toFixed(2) + ' m/s' }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Vertical Speed">
                <span>V.S</span>
                <span class="ml10">{{ deviceInfo.device.vertical_speed === str ? str :
                  deviceInfo.device.vertical_speed.toFixed(2) + ' m/s' }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Wind Speed">
                <span>W.S</span>
                <span class="ml10">{{ deviceInfo.device.wind_speed === str ? str : (deviceInfo.device.wind_speed /
                  10).toFixed(2) + ' m/s' }}</span>
              </a-tooltip>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="battery-slide" v-if="deviceInfo.device.battery.remain_flight_time !== 0">
        <div style="background: #535759;" class="width-100"></div>
        <div class="capacity-percent" :style="{ width: deviceInfo.device.battery.capacity_percent + '%' }"></div>
        <div class="return-home" :style="{ width: deviceInfo.device.battery.return_home_power + '%' }"></div>
        <div class="landing" :style="{ width: deviceInfo.device.battery.landing_power + '%' }"></div>
        <div class="white-point" :style="{ left: deviceInfo.device.battery.landing_power + '%' }"></div>
        <div class="battery" :style="{ left: deviceInfo.device.battery.capacity_percent + '%' }">
          {{ Math.floor(deviceInfo.device.battery.remain_flight_time / 60) }}:
          {{ 10 > (deviceInfo.device.battery.remain_flight_time % 60) ? '0' :
            '' }}{{ deviceInfo.device.battery.remain_flight_time % 60 }}
        </div>
      </div>
    </div> -->
    <!-- 机场OSD -->
    <div v-if="false" class="osd-panel fz12">
      <div class="fz16 pl5 pr5 flex-align-center flex-row flex-justify-between"
        style="border-bottom: 1px solid #515151; height: 10%;">
        <span>{{ osdVisible.gateway_callsign }}</span>
        <span><a style="color: white;" @click="() => osdVisible.visible = false">
            <CloseOutlined />
          </a></span>
      </div>
      <!-- 机场  -->
      <div class="flex-display" style="border-bottom: 1px solid #515151;">
        <div class="flex-column flex-align-stretch flex-justify-center" style="width: 60px; background: #2d2d2d;">
          <a-tooltip :title="osdVisible.model">
            <div class="flex-column  flex-align-center flex-justify-center" style="width: 90%;">
              <span>
                <RobotFilled style="font-size: 48px;" />
              </span>
              <span class="mt10">Dock</span>
            </div>
          </a-tooltip>
        </div>
        <div class="osd flex-1" style="flex: 1">
          <a-row>
            <a-col span="16"
              :style="deviceInfo.dock.basic_osd?.mode_code === EDockModeCode.Disconnected ? 'color: red; font-weight: 700;' : 'color: rgb(25,190,107)'">
              {{ EDockModeCode[deviceInfo.dock.basic_osd?.mode_code] }}</a-col>
          </a-row>
          <a-row>
            <a-col span="12">
              <a-tooltip title="Accumulated Running Time">
                <span>
                  <HistoryOutlined />
                </span>
                <span class="ml10">
                  <span v-if="deviceInfo.dock.work_osd?.acc_time >= 2592000"> {{
                    Math.floor(deviceInfo.dock.work_osd?.acc_time / 2592000) }}m </span>
                  <span v-if="(deviceInfo.dock.work_osd?.acc_time % 2592000) >= 86400"> {{
                    Math.floor((deviceInfo.dock.work_osd?.acc_time % 2592000) / 86400) }}d </span>
                  <span v-if="(deviceInfo.dock.work_osd?.acc_time % 2592000 % 86400) >= 3600"> {{
                    Math.floor((deviceInfo.dock.work_osd?.acc_time % 2592000 % 86400) / 3600) }}h </span>
                  <span v-if="(deviceInfo.dock.work_osd?.acc_time % 2592000 % 86400 % 3600) >= 60"> {{
                    Math.floor((deviceInfo.dock.work_osd?.acc_time % 2592000 % 86400 % 3600) / 60) }}min </span>
                  <span>{{ Math.floor(deviceInfo.dock.work_osd?.acc_time % 2592000 % 86400 % 3600 % 60) }} s</span>
                </span>
              </a-tooltip>
            </a-col>
            <a-col span="12">
              <a-tooltip title="Activation time">
                <span>
                  <FieldTimeOutlined />
                </span>
                <span class="ml10">{{ new Date((deviceInfo.dock.work_osd?.activation_time ?? 0) * 1000).toLocaleString()
                }}
                </span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="6">
              <a-tooltip title="Network State">
                <span
                  :style="deviceInfo.dock.basic_osd?.network_state?.type === NetworkStateTypeEnum.ETHERNET || deviceInfo.dock.basic_osd?.network_state?.quality === NetworkStateQualityEnum.GOOD ?
                      'color: #00ee8b' : deviceInfo.dock.basic_osd?.network_state?.quality === NetworkStateQualityEnum.MEDIUM ? 'color: yellow' : 'color: red'">
                  <span v-if="deviceInfo.dock.basic_osd?.network_state?.type === NetworkStateTypeEnum.FOUR_G">
                    <SignalFilled />
                  </span>
                  <span v-else>
                    <GlobalOutlined />
                  </span>
                </span>
                <span class="ml10">{{ deviceInfo.dock.basic_osd?.network_state?.rate }} kb/s</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="The total number of times the dock has performed missions.">
                <span>
                  <CarryOutOutlined />
                </span>
                <span class="ml10">{{ deviceInfo.dock.work_osd?.job_number }} </span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Media File Remain Upload">
                <span>
                  <CloudUploadOutlined class="fz14" />
                </span>
                <span class="ml10">{{ deviceInfo.dock.link_osd?.media_file_detail?.remain_upload }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip>
                <template #title>
                  <p>total: {{ deviceInfo.dock.basic_osd?.storage?.total }}</p>
                  <p>used: {{ deviceInfo.dock.basic_osd?.storage?.used }}</p>
                </template>
                <span>
                  <FolderOpenOutlined />
                </span>
                <span class="ml10" v-if="deviceInfo.dock.basic_osd?.storage?.total > 0">
                  <a-progress type="circle" :width="20"
                    :percent="deviceInfo.dock.basic_osd?.storage?.used * 100 / deviceInfo.dock.basic_osd?.storage?.total"
                    :strokeWidth="20" :showInfo="false"
                    :strokeColor="deviceInfo.dock.basic_osd?.storage?.used * 100 / deviceInfo.dock.basic_osd?.storage?.total > 80 ? 'red' : '#00ee8b'" />
                </span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="6">
              <a-tooltip title="Wind Speed">
                <span>W.S</span>
                <span class="ml10">{{ (deviceInfo.dock.basic_osd?.wind_speed ?? str) + ' m/s' }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Rainfall">
                <span>🌧</span>
                <span class="ml10">{{ RainfallEnum[deviceInfo.dock.basic_osd?.rainfall] }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Environment Temperature">
                <span>°C</span>
                <span class="ml10">{{ deviceInfo.dock.basic_osd?.environment_temperature }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Dock Temperature">
                <span>°C</span>
                <span class="ml10">{{ deviceInfo.dock.basic_osd?.temperature }}</span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="6">
              <a-tooltip title="Dock Humidity">
                <span>💦</span>
                <span class="ml10">{{ deviceInfo.dock.basic_osd?.humidity }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Working Voltage">
                <span
                  style="border: 1px solid; border-radius: 50%; width: 18px; height: 18px; line-height: 16px; text-align: center; float: left;">V</span>
                <span class="ml10">{{ (deviceInfo.dock.work_osd?.working_voltage ?? str) + ' mV' }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Working Current">
                <span
                  style="border: 1px solid; border-radius: 50%; width: 18px; height: 18px; line-height: 15px; text-align: center; float: left;">A</span>
                <span class="ml10">{{ (deviceInfo.dock.work_osd?.working_current ?? str) + ' mA' }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Drone in dock">
                <span>
                  <RocketOutlined />
                </span>
                <span class="ml10">{{ DroneInDockEnum[deviceInfo.dock.basic_osd?.drone_in_dock] }}</span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row class="p5">
            <a-col span="24">
              <a-button type="primary" :disabled="dockControlPanelVisible" size="small"
                @click="setDockControlPanelVisible(true)">
                Actions
              </a-button>
            </a-col>
          </a-row>
          <!-- 机场控制面板 -->
          <DockControlPanel v-if="dockControlPanelVisible" :sn="osdVisible.gateway_sn" :deviceInfo="deviceInfo"
            @close-control-panel="onCloseControlPanel">
          </DockControlPanel>
        </div>
      </div>
      <!--  飞机-->
      <div class="flex-display">
        <div class="flex-column flex-align-stretch flex-justify-center" style="width: 60px;  background: #2d2d2d;">
          <a-tooltip :title="osdVisible.model">
            <div style="width: 90%;" class="flex-column flex-align-center flex-justify-center">
              <span><a-image :src="M30" :preview="false" /></span>
              <span>M30</span>
            </div>
          </a-tooltip>
        </div>
        <div class="osd flex-1">
          <a-row>
            <a-col span="16"
              :style="!deviceInfo.device || deviceInfo.device?.mode_code === EModeCode.Disconnected ? 'color: red; font-weight: 700;' : 'color: rgb(25,190,107)'">
              {{ !deviceInfo.device ? EModeCode[EModeCode.Disconnected] : EModeCode[deviceInfo.device?.mode_code]
              }}</a-col>
          </a-row>
          <a-row>
            <a-col span="6">
              <a-tooltip title="Upward Quality">
                <span>
                  <SignalFilled />
                  <ArrowUpOutlined style="font-size: 9px; vertical-align: top;" />
                </span>
                <span class="ml10">{{ deviceInfo.dock.link_osd?.sdr?.up_quality }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Downward Quality">
                <span>
                  <SignalFilled />
                  <ArrowDownOutlined style="font-size: 9px; vertical-align: top;" />
                </span>
                <span class="ml10">{{ deviceInfo.dock.link_osd?.sdr?.down_quality }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Drone Battery Level">
                <span>
                  <ThunderboltOutlined class="fz14" />
                </span>
                <span class="ml10">{{ deviceInfo.device && deviceInfo.device.battery.capacity_percent !== str ?
                  deviceInfo.device?.battery.capacity_percent + ' %' : str }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip>
                <template #title>
                  <p>total: {{ deviceInfo.device?.storage?.total }}</p>
                  <p>used: {{ deviceInfo.device?.storage?.used }}</p>
                </template>
                <span>
                  <FolderOpenOutlined />
                </span>
                <span class="ml10" v-if="deviceInfo.device?.storage?.total > 0">
                  <a-progress type="circle" :width="20"
                    :percent="deviceInfo.device?.storage?.used * 100 / deviceInfo.device?.storage?.total"
                    :strokeWidth="20" :showInfo="false"
                    :strokeColor="deviceInfo.device?.storage?.used * 100 / deviceInfo.device?.storage?.total > 80 ? 'red' : '#00ee8b'" />
                </span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row>
            <a-tooltip title="RTK Fixed">
              <a-col span="6" class="flex-row flex-align-center flex-justify-start">
                <span>Fixed</span>
                <span class="ml10 circle"
                  :style="deviceInfo.device?.position_state.is_fixed === 1 ? 'backgroud: rgb(25,190,107);' : ' background: red;'"></span>
              </a-col>
            </a-tooltip>
            <a-col span="6">
              <a-tooltip title="GPS">
                <span>GPS</span>
                <span class="ml10">{{ deviceInfo.device ? deviceInfo.device.position_state.gps_number : str }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="RTK">
                <span>
                  <TrademarkOutlined class="fz14" />
                </span>
                <span class="ml10">{{ deviceInfo.device ? deviceInfo.device.position_state.rtk_number : str }}</span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="6">
              <a-tooltip title="Flight Mode">
                <span>
                  <ControlOutlined class="fz16" />
                </span>
                <span class="ml10">{{ deviceInfo.device ? EGear[deviceInfo.device?.gear] : str }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Altitude above sea level">
                <span>ASL</span>
                <span class="ml10">{{ !deviceInfo.device || deviceInfo.device.height === str ? str :
                  deviceInfo.device?.height.toFixed(2) + ' m' }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Altitude above takeoff level">
                <span>ALT</span>
                <span class="ml10">{{ !deviceInfo.device || deviceInfo.device.elevation === str ? str :
                  deviceInfo.device?.elevation.toFixed(2) + ' m' }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Distance to Home Point">
                <span
                  style="border: 1px solid; border-radius: 50%; width: 18px; height: 18px; line-height: 15px; text-align: center;  display: block; float: left;">H</span>
                <span class="ml10">{{ !deviceInfo.device || deviceInfo.device.home_distance === str ? str :
                  deviceInfo.device?.home_distance.toFixed(2) + ' m' }}</span>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="6">
              <a-tooltip title="Horizontal Speed">
                <span>H.S</span>
                <span class="ml10">{{ !deviceInfo.device || deviceInfo.device?.horizontal_speed === str ? str :
                  deviceInfo.device?.horizontal_speed.toFixed(2) + ' m/s' }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Vertical Speed">
                <span>V.S</span>
                <span class="ml10">{{ !deviceInfo.device || deviceInfo.device.vertical_speed === str ? str :
                  deviceInfo.device?.vertical_speed.toFixed(2) + ' m/s' }}</span>
              </a-tooltip>
            </a-col>
            <a-col span="6">
              <a-tooltip title="Wind Speed">
                <span>W.S</span>
                <span class="ml10">{{ !deviceInfo.device || deviceInfo.device.wind_speed === str ? str :
                  (deviceInfo.device?.wind_speed / 10).toFixed(2) + ' m/s' }}</span>
              </a-tooltip>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="battery-slide" v-if="deviceInfo.device && deviceInfo.device.battery.remain_flight_time !== 0"
        style="border: 1px solid red">
        <div style="background: #535759;" class="width-100"></div>
        <div class="capacity-percent" :style="{ width: deviceInfo.device.battery.capacity_percent + '%' }"></div>
        <div class="return-home" :style="{ width: deviceInfo.device.battery.return_home_power + '%' }"></div>
        <div class="landing" :style="{ width: deviceInfo.device.battery.landing_power + '%' }"></div>
        <div class="white-point" :style="{ left: deviceInfo.device.battery.landing_power + '%' }"></div>
        <div class="battery" :style="{ left: deviceInfo.device.battery.capacity_percent + '%' }">
          {{ Math.floor(deviceInfo.device.battery.remain_flight_time / 60) }}:
          {{ 10 > (deviceInfo.device.battery.remain_flight_time % 60) ? '0' :
            '' }}{{ deviceInfo.device.battery.remain_flight_time % 60 }}
        </div>
      </div>
      <!-- 飞行指令 -->
      <DroneControlPanel :sn="osdVisible.gateway_sn" :deviceInfo="deviceInfo" :payloads="osdVisible.payloads">
      </DroneControlPanel>
    </div>
    <!--  项目页:无人机操作面板 -->
    <!-- 手动操作  -->
    <newDroneControlPanel v-if="osdVisible.visible
      && osdVisible.is_dock
      && root.$route.name === ERouterName.ITEM" :sn="osdVisible.gateway_sn" :deviceInfo="deviceInfo"
      :payloads="osdVisible.payloads">
    </newDroneControlPanel>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from 'vue'
import type { CSSProperties } from 'vue'
import {
  generateLineContent,
  generatePointContent,
  generatePolyContent
} from '../utils/map-layer-utils'
import { postElementsReq } from '/@/api/layer'
import { MapDoodleType, MapElementEnum } from '/@/constants/map'
import { useGMapManage } from '/@/hooks/use-g-map'
import { useGMapCover } from '/@/hooks/use-g-map-cover'
import { useMouseTool } from '/@/hooks/use-mouse-tool'
import { getApp, getRoot } from '/@/root'
import { useMyStore } from '/@/store'
import { GeojsonCoordinate } from '/@/types/map'
import { MapDoodleEnum } from '/@/types/map-enum'
import { PostElementsBody } from '/@/types/mapLayer'
import { uuidv4 } from '/@/utils/uuid'
import { gcj02towgs84, wgs84togcj02 } from '/@/vendors/coordtransform'
import { deviceTsaUpdate } from '/@/hooks/use-g-map-tsa'
import * as Cesium from 'cesium'
import {
  DeviceOsd, DeviceStatus, DockOsd, EGear, EModeCode, GatewayOsd, EDockModeCode,
  NetworkStateQualityEnum, NetworkStateTypeEnum, RainfallEnum, DroneInDockEnum
} from '/@/types/device'
import pin from '/@/assets/icons/pin-2d8cf0.svg'
import M30 from '/@/assets/icons/m30.png'
import markers from '/@/assets/icons/markers.png'
import leftrotation from '/@/assets/waylines/lfd.png'
import rightrotation from '/@/assets/waylines/rdr.png'
import paizhao from '/@/assets/waylines/paizhao.png'
import shexiang from '/@/assets/waylines/shexiang.png'
import wenhao from '/@/assets/waylines/wenhao.png'
import pointDelete from '/@/assets/waylines/pointDelete.png'
import forward from '/@/assets/waylines/up.png'
import left from '/@/assets/waylines/left.png'
import back from '/@/assets/waylines/dwon.png'
import right from '/@/assets/waylines/right.png'
import up from '/@/assets/icons/up.svg'
import caup from '/@/assets/icons/caup.svg'
import down from '/@/assets/icons/down.svg'
import cadown from '/@/assets/icons/cadown.svg'
import carm from '/@/assets/waylines/camr.png'
import sx from '/@/assets/waylines/radi.png'
import jt from '/@/assets/waylines/三角形@2x.png'
import xz from '/@/assets/waylines/形状@2x.png'

import { message } from 'ant-design-vue'
import {
  BorderOutlined, LineOutlined, CloseOutlined, ControlOutlined, TrademarkOutlined, ArrowDownOutlined,
  ThunderboltOutlined, SignalFilled, GlobalOutlined, HistoryOutlined, CloudUploadOutlined, RocketOutlined,
  FieldTimeOutlined, CloudOutlined, CloudFilled, FolderOpenOutlined, RobotFilled, ArrowUpOutlined, CarryOutOutlined,
} from '@ant-design/icons-vue'
import { EDeviceTypeName, ERouterName } from '../types'
import DockControlPanel from './g-map/DockControlPanel.vue'
import { useDockControl } from './g-map/use-dock-control'
import DroneControlPanel from './g-map/DroneControlPanel.vue'
import newDroneControlPanel from './g-map/newDroneControlPanel.vue'
import { useConnectMqtt2 } from './g-map/use-connect-mqtt'
import { useRoute } from 'vue-router'
import { init3DMap, getViewer, showair, cone, addCone, changedir, spin, pitch, addpointline, showairline, setflypos, showdock } from '../hooks/use-cesium-map'
import bus from '/@/utils/mitt'
import html2canvas from 'html2canvas'
import { flyToPoint, deleteWaylinePoint, manager, getcameraeye, getcamera } from '/@/hooks/use-cesium-map'
import { getProjectInfo } from '../api/manage'

export default defineComponent({
  components: {
    BorderOutlined,
    LineOutlined,
    CloseOutlined,
    ControlOutlined,
    TrademarkOutlined,
    ThunderboltOutlined,
    SignalFilled,
    GlobalOutlined,
    HistoryOutlined,
    CloudUploadOutlined,
    FieldTimeOutlined,
    CloudOutlined,
    CloudFilled,
    FolderOpenOutlined,
    RobotFilled,
    ArrowUpOutlined,
    ArrowDownOutlined,
    DockControlPanel,
    DroneControlPanel,
    newDroneControlPanel,
    CarryOutOutlined,
    RocketOutlined
  },
  name: 'GMap',
  props: {},
  setup() {
    const changelist = ['KeyC', 'KeyZ', 'KeyA', 'KeyD', 'KeyW', 'KeyS']
    let useMouseToolHook: any = null
    const useGMapManageHook = useGMapManage() // 挂载地图
    const deviceTsaUpdateHook = deviceTsaUpdate()
    const root = getRoot()

    const mouseMode = ref(false)
    const store = useMyStore()
    const state = reactive({
      currentType: '',
      coverIndex: 0
    })
    interface Mark {
      style: CSSProperties
      label: string
    }
    const barWidth = ref()
    const distanceLabel = ref()
    let viewer: any = null
    type Marks = Record<number, Mark | string>
    const marks = reactive<Marks>({
      0: '2X',
      8: '8°C',
      37: '37°C',
      50: {
        style: {
          color: '#1989FA',
        },
        label: '50%',
      },
    })

    const clickbtn = ref('')
    const focal = ref(0)
    let mouset: any = null
    const lat: any = ref('')
    const lng: any = ref('')
    const flyheadCss = ref('rotate(-45deg)')
    const flyhead = ref('')
    const carpitch = ref('')
    const carpitchtop = ref('')
    const alt = ref('')
    const showplan = ref(false)
    const str: string = '--'
    const picurl = ref('')
    const flyHeight = reactive({
      outHeight: 100, // 安全离场高度
      workHeight: 100, // 飞行作业高度
      returnHeight: 100, // 返航高度
    })
    const deviceInfo = reactive({
      gateway: {
        capacity_percent: str,
        transmission_signal_quality: str,
      } as GatewayOsd,
      dock: {} as DockOsd,
      device: {
        gear: -1,
        mode_code: EModeCode.Disconnected,
        height: str,
        home_distance: str,
        horizontal_speed: str,
        vertical_speed: str,
        wind_speed: str,
        wind_direction: str,
        elevation: str,
        position_state: {
          gps_number: str,
          is_fixed: 0,
          rtk_number: str
        },
        battery: {
          capacity_percent: str,
          landing_power: str,
          remain_flight_time: 0,
          return_home_power: str,
        },
        latitude: 0,
        longitude: 0,
      } as DeviceOsd
    })
    const currentLastPointIndex = computed(() => { // 当前航点组中最后一个航点index
      return store.state.waylinePointMsg[store.state.waylinePointMsg.length - 1]?.index
    })
    const lastPointIndexArr = computed(() => { // 当前航点组中最后一个航点index
      const tempArr = []
      store.state.waylinePointMsg.forEach((item: any) => {
        tempArr.push(item.index)
      })
      return tempArr
    })
    const folderId = computed(() => {
      return store.state.folderId
    })
    const shareId = computed(() => {
      return store.state.layerBaseInfo.share
    })
    const defaultId = computed(() => {
      return store.state.layerBaseInfo.default
    })
    const drawVisible = computed(() => {
      return store.state.drawVisible
    })
    const osdVisible = computed(() => {
      return store.state.osdVisible
    })
    const createWaylineVisible = computed(() => {
      return store.state.createWaylineVisible
    })
    const toolShow = computed(() => {
      return !(root.$route.name === ERouterName.CREATE_WAYLINE)
    })
    const pointNum = computed(() => { // 航点数量
      return store.state.waylinePointMsg.length
    })
    const waylineLength = computed(() => { // 航线长度m
      // Cartographic对象
      let positionCartographic = null
      // Cartesian3对象
      let positionCartesian = null
      // Cartesian3对象数组
      const positionCartesianArr = []
      // 距离
      let distance = 0
      let distanceAll = 0
      if (store.state.waylinePointMsg.length > 1) {
        for (let i = 0; i < store.state.waylinePointMsg.length; i++) { // 计算每一个标点的笛卡尔坐标并存储到数组中
          // 将经纬度转换为Cartographic对象
          positionCartographic = Cesium.Cartographic.fromDegrees(store.state.waylinePointMsg[i].Point.longitude, store.state.waylinePointMsg[i].Point.latitude, store.state.waylinePointMsg[i].height)
          // 将Cartographic对象为Cartesian3对象
          positionCartesian = Cesium.Ellipsoid.WGS84.cartographicToCartesian(positionCartographic)
          positionCartesianArr.push(positionCartesian)
        }
        for (let i = 0; i < positionCartesianArr.length; i++) {
          // 计算两个实体之间的直线距离
          if (i === (positionCartesianArr.length - 1)) {
            console.log(123)
          } else {
            distance = Cesium.Cartesian3.distance(positionCartesianArr[i], positionCartesianArr[i + 1])
            distanceAll = distance + distanceAll
            console.log(distanceAll.toFixed(1), '距离')
          }
        }

        return distanceAll.toFixed(1)
      } else {
        return 0
      }
    })
    const waypointArr = computed(() => { // 航点数组
      return store.state.waylinePointMsg
    })
    const mediaNum = computed(() => { // 媒体数量
      let sum = 0
      store.state.waylinePointMsg.forEach((item: any) => {
        sum += item.action.length
      })
      return sum
    })
    watch(() => store.state.deviceStatusEvent,
      data => {
        if (Object.keys(data.deviceOnline).length !== 0) {
          deviceTsaUpdateHook.initMarker(data.deviceOnline.domain, data.deviceOnline.device_callsign, data.deviceOnline.sn)
          store.state.deviceStatusEvent.deviceOnline = {} as DeviceStatus
        }
        if (Object.keys(data.deviceOffline).length !== 0) {
          deviceTsaUpdateHook.removeMarker(data.deviceOffline.sn)
          if ((data.deviceOffline.sn === osdVisible.value.sn) || (osdVisible.value.is_dock && data.deviceOffline.sn === osdVisible.value.gateway_sn)) {
            osdVisible.value.visible = false
            store.commit('', osdVisible)
          }
          store.state.deviceStatusEvent.deviceOffline = {}
        }
      },
      {
        deep: true
      }
    )

    watch(() => store.state.deviceState, data => {
      if (data.currentType === EDeviceTypeName.Gateway && data.gatewayInfo[data.currentSn]) {
        deviceTsaUpdateHook.moveTo(data.currentSn, data.gatewayInfo[data.currentSn].longitude, data.gatewayInfo[data.currentSn].latitude)
        if (osdVisible.value.visible && osdVisible.value.gateway_sn !== '') {
          deviceInfo.gateway = data.gatewayInfo[osdVisible.value.gateway_sn]
        }
      }
      if (data.currentType === EDeviceTypeName.Aircraft && data.deviceInfo[data.currentSn]) {
        deviceTsaUpdateHook.moveTo(data.currentSn, data.deviceInfo[data.currentSn].longitude, data.deviceInfo[data.currentSn].latitude)
        if (osdVisible.value.visible && osdVisible.value.sn !== '') {
          deviceInfo.device = data.deviceInfo[osdVisible.value.sn]
        }
      }
      if (data.currentType === EDeviceTypeName.Dock && data.dockInfo[data.currentSn]) {
        deviceTsaUpdateHook.initMarker(EDeviceTypeName.Dock, [EDeviceTypeName.Dock], data.currentSn, data.dockInfo[data.currentSn].basic_osd?.longitude, data.dockInfo[data.currentSn].basic_osd?.latitude)
        if (osdVisible.value.visible && osdVisible.value.is_dock && osdVisible.value.gateway_sn !== '') {
          deviceInfo.dock = data.dockInfo[osdVisible.value.gateway_sn]
          deviceInfo.device = data.deviceInfo[deviceInfo.dock.basic_osd.sub_device?.device_sn ?? osdVisible.value.sn]
        }
      }
    }, {
      deep: true
    })

    // watch(
    //   () => store.state.wsEvent,
    //   newData => {
    //     const useGMapCoverHook = useGMapCover()
    //     const event = newData
    //     let exist = false
    //     if (Object.keys(event.mapElementCreat).length !== 0) {
    //       console.log(event.mapElementCreat, '创建地图元素')

    //       const ele = event.mapElementCreat
    //       store.state.Layers.forEach(layer => {
    //         layer.elements.forEach(e => {
    //           if (e.id === ele.id) {
    //             exist = true
    //             console.log('true')
    //           }
    //         })
    //       })
    //       if (exist === false) {
    //         setLayers({
    //           id: ele.id,
    //           name: ele.name,
    //           resource: ele.resource
    //         })

    //         updateCoordinates('wgs84-gcj02', ele)
    //         console.log('log印记111')

    //         // useGMapCoverHook.init2DPin(
    //         //   ele.name,
    //         //   ele.resource.content.geometry.coordinates,
    //         //   ele.resource.content.properties.color,
    //         //   {
    //         //     id: ele.id,
    //         //     name: ele.name
    //         //   }
    //         // )
    //       }

    //       store.state.wsEvent.mapElementCreat = {}
    //     }
    //     if (Object.keys(event.mapElementUpdate).length !== 0) {
    //       console.log(event.mapElementUpdate)
    //       console.log('该功能还未实现，请开发商自己增加')
    //       store.state.wsEvent.mapElementUpdate = {}
    //     }
    //     if (Object.keys(event.mapElementDelete).length !== 0) {
    //       console.log(event.mapElementDelete)
    //       console.log('该功能还未实现，请开发商自己增加')
    //       store.state.wsEvent.mapElementDelete = {}
    //     }
    //   },
    //   {
    //     deep: true
    //   }
    // )

    // dock 控制面板
    const {
      dockControlPanelVisible,
      setDockControlPanelVisible,
      onCloseControlPanel,
    } = useDockControl()

    // 鼠标控制模拟飞机
    function moudown(e: string) {
      clickbtn.value = e
      if (e === 'Space') {
        const res: any = addpointline(store)
        if (res === 0) {
          message.error('飞机处于航点上，无法新增航点。')
        } else {
          console.log(res, '标点的数据')

          // lat.value = res.lat
          // lng.value = res.lng
        }
      } else {
        mouset = setInterval(() => {
          if (changelist.includes(e)) {
            alt.value = changedir(e).alt
          } else if (e === 'KeyQ') {
            const res = spin(e)
            flyheadCss.value = res.flyheadCss
            flyhead.value = res.flyhead
          } else if (e === 'KeyE') {
            const res = spin(e)
            flyheadCss.value = res.flyheadCss
            flyhead.value = res.flyhead
          } else if (e === 'ArrowUp') {
            const res = pitch(e)
            carpitch.value = res.carpitch
            carpitchtop.value = res.carpitchtop
          } else if (e === 'ArrowDown') {
            const res = pitch(e)
            carpitch.value = res.carpitch
            carpitchtop.value = res.carpitchtop
          } else if (e === 'ArrowRight') {
            const res = spin(e)
            flyheadCss.value = res.flyheadCss
            flyhead.value = res.flyhead
          } else if (e === 'ArrowLeft') {
            const res = spin(e)
            flyheadCss.value = res.flyheadCss
            flyhead.value = res.flyhead
          }
        }, 100)
      }
    }
    const mouup = () => {
      clickbtn.value = ''
      clearInterval(mouset)
    }
    // 鼠标控制真实飞机
    function moudownTrue(e: string) {
      clickbtn.value = e
      console.log(clickbtn.value)

      mouset = setInterval(() => {
        if (changelist.includes(e)) {
          console.log('上下左右')
        } else if (e === 'KeyQ') {
          console.log('左旋')
        } else if (e === 'KeyE') {
          console.log('右旋')
        } else if (e === 'Space') {
          console.log('空格')
        }
      }, 100)
    }
    // 键盘控制真实飞机
    function maposTrue(e: any) {
      if (changelist.includes(e.code)) {
        console.log('上下左右')
      } else if (e.code === 'KeyQ') {
        console.log('左旋')
      } else if (e.code === 'KeyE') {
        console.log('右旋')
      }
    }
    // 控制真实飞机按键抬起
    function keyupUpTrue(e: any) {
      clickbtn.value = ''
    }

    // 当前所在标点index
    let currentPointIndex = currentLastPointIndex.value
    // 监听最后一个航点，如果变化，当前航点也变
    watch(currentLastPointIndex, (data) => {
      currentPointIndex = data
      console.log(currentPointIndex, '监听')
    }, { deep: true, immediate: true })

    // 切换航点
    const nextPoint = (name: string) => {
      const spotLightCameraeye = getcameraeye()
      const spotLightCamera = getcamera()
      console.log(currentPointIndex, '当前航点')

      if (name === 'pre') { // 上一个航点
        for (let i = 0; i < lastPointIndexArr.value.length; i++) {
          if (lastPointIndexArr.value[i] === currentPointIndex) {
            currentPointIndex = lastPointIndexArr.value[i - 1]
            console.log(currentPointIndex, '下一个')
            if (currentPointIndex === undefined) {
              currentPointIndex = lastPointIndexArr.value[lastPointIndexArr.value.length - 1]
            }
            break
          }
        }
      } else
        if (name === 'next') { // 下一个航点
          for (let i = 0; i < lastPointIndexArr.value.length; i++) {
            if (lastPointIndexArr.value[i] === currentPointIndex) {
              currentPointIndex = lastPointIndexArr.value[i + 1]
              console.log(currentPointIndex, '下一个')
              if (currentPointIndex === undefined) {
                currentPointIndex = lastPointIndexArr.value[0]
              }
              break
            }
          }
        }

      // 遍历实体列表
      viewer.entities.values.forEach((item: any) => {
        console.log(item._id, 'id')

        if (item._id === currentPointIndex) {
          console.log(currentPointIndex, '找到标点开始移动')

          viewer.entities.getById('airport').position = item.position._value // 转移飞机模型

          const entityinfo = viewer.entities.getById('info' + item._id)
          entityinfo.show = true
          // 锥体相关
          const deleteArr: any = []
          manager._primitives.forEach((item2: any) => { // 遍历锥体manager
            if (item2._instanceIds[0] === 'priX' || item2._instanceIds[0] === 'pri0X') {
              deleteArr.push(item2)
            }
          })
          deleteArr.forEach((item3: any) => {
            manager.remove(item3)
          })
          // 移动视角
          // 将 Cartesian3 坐标转换为 Cartographic 坐标
          const cartographicPosition = Cesium.Cartographic.fromCartesian(item.position._value, Cesium.Ellipsoid.WGS84, new Cesium.Cartographic())
          // 获取经度、纬度和高度
          const longitude = Cesium.Math.toDegrees(cartographicPosition.longitude)
          const latitude = Cesium.Math.toDegrees(cartographicPosition.latitude)
          const height = cartographicPosition.height
          spotLightCamera.setView({
            destination: item.position._value,
            orientation: {
              heading: spotLightCamera.heading,
              pitch: spotLightCamera.pitch,
              roll: spotLightCamera.roll
            }
          })
          spotLightCameraeye.setView({
            destination: item.position._value,
            orientation: {
              heading: spotLightCamera.heading,
              pitch: spotLightCamera.pitch,
              roll: spotLightCamera.roll
            }
          })

          viewer.camera.setView({
            destination: Cesium.Cartesian3.fromDegrees(longitude, latitude, 1500.0),

          })
        }
      })
    }
    // 视角机场
    const flydock = () => {
      const lo = store.state.deviceState.dockInfo[store.state.deviceState.currentSn].basic_osd.alternate_land_point.longitude
      const la = store.state.deviceState.dockInfo[store.state.deviceState.currentSn].basic_osd.alternate_land_point.latitude
      viewer.camera.flyTo({ // 跳转视角
        destination: Cesium.Cartesian3.fromDegrees(lo, la, 1500),
        orientation: {
          heading: Cesium.Math.toRadians(0),
          pitch: Cesium.Math.toRadians(-90),
          roll: 0
        },
        duration: 2 // 飞行持续时间，单位为秒
      })
    }

    // 拍照
    const takephoto = () => {
      const mapDom: any = document.getElementById('eye')
      html2canvas(mapDom, {
        backgroundColor: null,
        useCORS: true, // 如果截图的内容里有图片,可能会有跨域的情况,加上这个参数,解决文件跨域问题
        logging: true // 日志开关，便于查看html2canvas的内部执行流程
      }).then(canvas => {
        // document.body.appendChild(canvas)
        // console.log(canvas.toDataURL())
        picurl.value = canvas.toDataURL()
        // canvas.toBlob(blob => {
        //   // 以时间戳作为文件名 实时区分不同文件 按需求自己定义就好
        //   const filename = `${new Date().getTime()}.jpg`
        //   // 转换canvas图片数据格式为formData
        //   const file2 = new File([blob], filename, { type: 'image/jpg' })
        //   console.log(file2)
        // })
      })
    }
    // 飞机移动
    function mapos(e: any) {
      console.log(e, '飞机移动E')

      if (changelist.includes(e.code)) {
        alt.value = changedir(e.code).alt
        console.log(alt.value, '高度')
      } else if (e.code === 'KeyQ') {
        const res = spin(e.code)
        flyheadCss.value = res.flyheadCss
        flyhead.value = res.flyhead
      } else if (e.code === 'KeyE') {
        const res = spin(e.code)
        flyheadCss.value = res.flyheadCss
        flyhead.value = res.flyhead
      } else if (e.code === 'ArrowUp') {
        const res = pitch(e.code)
        carpitch.value = res.carpitch
        carpitchtop.value = res.carpitchtop
      } else if (e.code === 'ArrowDown') {
        const res = pitch(e.code)
        carpitch.value = res.carpitch
        carpitchtop.value = res.carpitchtop
      } else if (e.code === 'ArrowRight') {
        const res = spin(e.code)
        flyheadCss.value = res.flyheadCss
        flyhead.value = res.flyhead
      } else if (e.code === 'ArrowLeft') {
        const res = spin(e.code)
        flyheadCss.value = res.flyheadCss
        flyhead.value = res.flyhead
      }
    }
    // 点击空格画点
    function clickSpace() {
      const res = addpointline(store)
      if (res === 0) {
        message.error('飞机处于航点上，无法新增航点。')
      }
    }
    // 飞机空格标点、拍照
    function setPoint(e: any) {
      clickbtn.value = e.code
      if (keyflag) { // 飞机按键抬起
        if (e.code === 'Space') {
          const res: any = addpointline(store)
          if (res === 0) {
            message.error('飞机处于航点上，无法新增航点。')
          } else {
            console.log(res, '返回值')

            // lat.value = res.lat
            // lng.value = res.lng
          }
        } else if (e.code === 'KeyF') { // 飞机拍照
          const res = addpointline(store, 'photo')// 在设置标点信息后再拍照

          bus.emit('changeZhuitiId', store.state.waylinePointMsg.length)// 将pointId传给锥体

          // lat.value = res.lat
          // lng.value = res.lng
          // 画标点、存储标点信息
        } else if (e.code === 'KeyR') { // 飞机录像
          // takephoto1()
          // startfly()
          const res = addpointline(store, 'video')
          bus.emit('changeZhuitiId', store.state.waylinePointMsg.length)//
        }
        keyflag = false
      }
    }

    // 飞机按键抬起
    function keyupUp(e: any) {
      clickbtn.value = ''
      keyflag = true
      viewer.trackedEntity = undefined
    }
    //
    // if (root.$route.name === 'create-wayline') {

    // }

    let keyflag = true

    // 点击航线标点事件
    function handlePointClick() {
      console.log('航线点击')
      const spotLightCameraeye = getcameraeye()
      const spotLightCamera = getcamera()
      const handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas)
      handler.setInputAction(function (click: any) {
        const pick = viewer.scene.pick(click.position) // 从鼠标点击的屏幕位置获取场景中的物体

        if (pick && pick.id) { // 如果点击到了实体
          viewer.entities.values.forEach((item: any) => { // 遍历实体列表
            // const positions = item.position.getValue(Cesium.JulianDate.now())

            if (pick.id.id && (item.id === pick.id.id)) { // 如果实体列表有被点击到了的实体
              if (typeof item.id === 'number') { // 设置当前所在实体index
                currentPointIndex = item.id
              }

              lat.value = Cesium.Cartographic.fromCartesian(pick.primitive._position).latitude
              lng.value = Cesium.Cartographic.fromCartesian(pick.primitive._position).longitude
              // 标签显示相关
              viewer.entities.values.forEach((item1: any) => { // 遍历实体，让所有label不显示
                if (item1.name === 'infobox') {
                  viewer.entities.getById(item1.id).show = false
                }
                if (item1.id === 'airport') { // 转移飞机模型
                  viewer.entities.getById(item1.id).position = item.position._value
                }
              })
              const entityinfo = viewer.entities.getById('info' + item.id)
              entityinfo.show = true
              // 锥体相关
              const deleteArr: any = []
              manager._primitives.forEach((item2: any) => { // 遍历锥体manager
                if (item2._instanceIds[0] === 'priX' || item2._instanceIds[0] === 'pri0X') {
                  deleteArr.push(item2)
                }
              })
              deleteArr.forEach((item3: any) => {
                manager.remove(item3)
              })
              // 移动视角
              // 将 Cartesian3 坐标转换为 Cartographic 坐标
              const cartographicPosition = Cesium.Cartographic.fromCartesian(item.position._value, Cesium.Ellipsoid.WGS84, new Cesium.Cartographic())
              // 获取经度、纬度和高度
              const longitude = Cesium.Math.toDegrees(cartographicPosition.longitude)
              const latitude = Cesium.Math.toDegrees(cartographicPosition.latitude)
              const height = cartographicPosition.height
              spotLightCamera.setView({
                destination: item.position._value,
                orientation: {
                  heading: spotLightCamera.heading,
                  pitch: spotLightCamera.pitch,
                  roll: spotLightCamera.roll
                }
              })
              spotLightCameraeye.setView({
                destination: item.position._value,
                orientation: {
                  heading: spotLightCamera.heading,
                  pitch: spotLightCamera.pitch,
                  roll: spotLightCamera.roll
                }
              })

              viewer.camera.setView({
                destination: Cesium.Cartesian3.fromDegrees(longitude, latitude, 1500.0),

              })
              console.log(spotLightCamera.position, '位置')

              // cone()
            }
          })
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK)
      viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK)// 去除双击放大地图效果
    }

    // 取屏幕上两个像素点之间对应的地理距离，并根据这个距离计算出比例尺的大小
    function getpix() {
      // postRender获取将在场景渲染后立即引发的事件
      viewer.scene.postRender.addEventListener(function () {
        const geodesic = new Cesium.EllipsoidGeodesic()

        const distances = [
          1,
          2,
          3,
          5,
          10,
          20,
          30,
          50,
          100,
          200,
          300,
          500,
          1000,
          2000,
          3000,
          5000,
          10000,
          20000,
          30000,
          50000,
          100000,
          200000,
          300000,
          500000,
          1000000,
          2000000,
          3000000,
          5000000,
          10000000,
          20000000,
          30000000,
          50000000
        ]
        // 查找屏幕底部中心的两个像素之间的距离
        const scene = viewer.scene
        const width = scene.canvas.clientWidth
        const height = scene.canvas.clientHeight

        const left = scene.camera.getPickRay(
          new Cesium.Cartesian2((width / 2) | 0, height - 1)
        )
        const right = scene.camera.getPickRay(
          new Cesium.Cartesian2((1 + width / 2) | 0, height - 1)
        )

        const globe = scene.globe
        const leftPosition = globe.pick(left, scene)
        const rightPosition = globe.pick(right, scene)

        if (!Cesium.defined(leftPosition) || !Cesium.defined(rightPosition)) {
          barWidth.value = undefined // 比例尺
          distanceLabel.value = undefined
          return
        }

        const leftCartographic = globe.ellipsoid.cartesianToCartographic(
          leftPosition
        )
        const rightCartographic = globe.ellipsoid.cartesianToCartographic(
          rightPosition
        )

        geodesic.setEndPoints(leftCartographic, rightCartographic)
        const pixelDistance = geodesic.surfaceDistance

        // 找到使比例尺小于100像素的第一个距离。
        const maxBarWidth = 100
        let distance
        for (
          let i = distances.length - 1;
          !Cesium.defined(distance) && i >= 0;
          --i
        ) {
          if (distances[i] / pixelDistance < maxBarWidth) {
            distance = distances[i]
          }
        }

        if (Cesium.defined(distance)) {
          const label =
            distance >= 1000
              ? (distance / 1000).toString() + ' km'
              : distance.toString() + ' m'
          barWidth.value = (distance / pixelDistance) | 0// 比例尺
          distanceLabel.value = label
        } else {
          barWidth.value = undefined
          distanceLabel.value = undefined
        }
      }
      )
    }
    // 跳转至航点
    function gotoPoint(item: any) {
      flyToPoint([item.Point.longitude, item.Point.latitude, item.height])
    }
    // 删除指定航点
    function deletePoint(item: any) {
      // console.log(item.index, '删除')
      // console.log('所有点', store.state.waylinePointMsg)
      deleteWaylinePoint({ point: item.Point, id: item.index, height: item.height }, store)
    }

    watch(() => root.$route.name, (newValue) => {
      if (newValue === ERouterName.CREATE_WAYLINE && !store.state.waylineIsEdit.bool) {
        handlePointClick()
        setflypos(true, store)// 代表不是编辑
      } else if (newValue === ERouterName.CREATE_WAYLINE && store.state.waylineIsEdit.bool) {
        handlePointClick()
        setflypos(false, store)// 代表是编辑
      } else {
        bus.emit('clearfly')
      }
    }, {
      deep: true
    })
    function caforward() { // 缩放镜头
      viewer.camera.moveForward(400)
    }
    function cabackward() {
      viewer.camera.moveBackward(400)
    }
    onMounted(() => {
      // 初始化amap方法
      const app = getApp()
      useGMapManageHook.globalPropertiesConfig(app)
      let entity: any = null
      // 连接或断开drc，飞控入口
      // useConnectMqtt()
      // useConnectMqtt2()
      getProjectInfo().then(res => {
        store.commit('SET_PROJECT', res.data)// 存储项目信息
        init3DMap('g-container', [res.data.latitude, res.data.longitude])// 初始化地图中心
        viewer = getViewer()
        // if (root.$route.name === ERouterName.CREATE_WAYLINE) { // 进入航线新建、编辑
        //   handlePointClick()

        //   setflypos()
        // }
        useMouseToolHook = useMouseTool()// 画图
        setTimeout(() => {
          getpix() // 比例尺
          showdock(store)// 显示机场
        }, 1000)

        // 若鼠标移动到实体，指针变为可点击
        // 屏幕空间事件处理程序,是与鼠标和键盘输入相关的事件处理程序
        const handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas)
        handler.setInputAction(function (movement: any) {
          const pickedObject = viewer.scene.pick(movement.endPosition)
          if (Cesium.defined(pickedObject)) {
            viewer._container.style.cursor = 'pointer'
          } else {
            viewer._container.style.cursor = 'default'
          }
        }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)

        // 添加事件总线，显示无人机操作界面
        bus.on('takepos', () => {
          showplan.value = true
          // 键盘控制移动旋转时间
          document.addEventListener('keydown', mapos)
          document.addEventListener('keydown', setPoint)
          document.addEventListener('keyup', keyupUp)
        })
        // 添加事件总线，关闭无人机操作界面
        bus.on('closeShow', () => {
          document.removeEventListener('keydown', mapos)
          document.removeEventListener('keydown', setPoint)
          document.removeEventListener('keyup', keyupUp)
          showplan.value = false
          bus.emit('setFlypos')// 重置数据
        })
      })
      console.log(osdVisible.value.visible, 'visible')
      console.log(osdVisible.value.is_dock, 'is_dock')

      // 添加对真实飞机位置的监听
      watch(() => deviceInfo.device, data => {
        const entityCollection = viewer.entities
        if (entityCollection.getById('airportOnline')) {
          // 如果有，则先删除之前飞机模型
          viewer.entities.removeById('airportOnline')// 飞机模型
        }
        // 加载飞机模型
        entity = viewer.entities.add({
          //   //加载飞机模型
          id: 'airportOnline',
          model: {
            uri: 'http://uav.zszc-xsm.com/assets/CM-20.glb',
            // uri: '@/../../src/assets/CM-20.glb',
            minimumPixelSize: 64,
          },
          position: Cesium.Cartesian3.fromDegrees(deviceInfo.device.longitude, deviceInfo.device.latitude, Number(deviceInfo.device.height)),
          orientation: Cesium.Transforms.headingPitchRollQuaternion(
            Cesium.Cartesian3.fromDegrees(deviceInfo.device.longitude, deviceInfo.device.latitude, Number(deviceInfo.device.height)),
            new Cesium.HeadingPitchRoll(
              Cesium.Math.toRadians(-90), // 设置这个属性即可（顺时针旋转的角度值）
              Cesium.Math.toRadians(0),
              Cesium.Math.toRadians(0)
            )
          )
        })
      }, {
        deep: true
      })
    })
    onUnmounted(() => {
      document.removeEventListener('keydown', maposTrue)
      document.removeEventListener('keydown', mapos)
      document.removeEventListener('keyup', keyupUp)
      document.removeEventListener('keydown', setPoint)
      document.removeEventListener('keyup', keyupUpTrue)
      bus.off('takepos')
    })

    function draw(type: MapDoodleType, bool: boolean) {
      console.log('在这里画图')
      if (folderId.value === '0') {
        message.error('请点击选择文件夹')
      } else {
        state.currentType = type

        useMouseToolHook.mouseTool(type, store) // 画图

        mouseMode.value = bool // 绘制面板上的叉叉
      }
    }
    bus.on('draw', (arr) => {
      getDrawCallback(arr)
    })
    function getDrawCallback(arr: any) {
      switch (state.currentType) {
        case MapDoodleEnum.PIN:
          sendAddPoint(arr)
          break
        case MapDoodleEnum.POLYLINE:
          sendAddPolyline(arr)
          break
        case MapDoodleEnum.POLYGON:
          sendAddPolygon(arr)
          break
        default:
          break
      }
    }
    // 发送增加标点的请求
    async function sendAddPoint(arr) {
      let req = {
        name: 'point1ssssss',
        resource: {
          type: 0,
          content: {
            type: 'Feature',
            properties: {
              color: '#2D8CF0FF'
            },
            geometry: {
              type: 'Point',
              coordinates: [arr[0], arr[1], 0.00],
            }
          }
        }
      }

      bus.on('setLayers', (obj: any) => {
        console.log('setLayers的req', req)

        req = { ...req, id: obj.id, user_name: obj.user_name }
        req.resource.user_name = obj.user_name
        setLayers(req)
        bus.off('setLayers')
      })
      bus.emit('sendReq', { req: req, shareId: folderId.value })// 发送req数据

      // const result = await postElementsReq(shareId.value, req)// 请求增加标注
      // console.log(result, 'ss')
    }
    // 发送增加线段的请求
    async function sendAddPolyline(arr) {
      console.log(arr, '线段')

      let req = {
        name: 'polyline123',
        resource: {
          type: 1,
          content: {
            type: 'Feature',
            properties: {
              color: '#2D8CF0FF'
            },
            geometry: {
              type: 'LineString',
              coordinates: arr,
            }
          }
        }
      }
      bus.on('setLayers', (obj: any) => {
        req = { ...req, id: obj.id, user_name: obj.user_name }
        req.resource.user_name = obj.user_name
        setLayers(req)
        bus.off('setLayers')
      })
      bus.emit('sendReq', { req: req, shareId: folderId.value })// 发送req数据
      // const result = await postElementsReq(shareId.value, req)// 请求增加标注
    }

    // 发送增加多边形的请求
    async function sendAddPolygon(arr) {
      let req = {
        name: 'Polygon123',
        resource: {
          type: 2,
          content: {
            type: 'Feature',
            properties: {
              color: '#2D8CF08A'
            },
            geometry: {
              type: 'Polygon',
              coordinates: arr,
            }
          }
        }
      }
      bus.on('setLayers', (obj: any) => {
        req = { ...req, id: obj.id, user_name: obj.user_name }
        req.resource.user_name = obj.user_name
        setLayers(req)
        bus.off('setLayers')
      })

      bus.emit('sendReq', { req: req, shareId: folderId.value })// 发送req数据

      // const result = await postElementsReq(shareId.value, req)// 请求增加标注
      // console.log(result, 'ss')
    }

    // 设置图层信息,将新增的标注存入相应文件夹
    function setLayers(resource: PostElementsBody) {
      console.log('录入文件夹', resource)
      const layers = store.state.Layers // store.state.Layers放的是图层文件夹
      // layer相当于单个文件夹
      const layer = layers.find(item => item.id === (folderId.value))// shareId.value是新创建的点的文件夹的id
      // layer.id = 'private_layer' + uuidv4()
      // layer?.elements.push(resource)
      if (layer?.elements) {
        (layer?.elements as any[]).push(resource)
      }
      store.commit('SET_LAYER_INFO', layers)// store.state.Layers = info，重新在store保存文件夹内容
    }
    function updateCoordinates(transformType: string, element: any) {
      console.log('更新印记')
      const geoType = element.resource?.content.geometry.type
      const type = element.resource?.type as number
      if (element.resource) {
        if (MapElementEnum.PIN === type) {
          const coordinates = element.resource?.content.geometry
            .coordinates as GeojsonCoordinate
          if (transformType === 'wgs84-gcj02') {
            const transResult = wgs84togcj02(
              coordinates[0],
              coordinates[1]
            ) as GeojsonCoordinate
            element.resource.content.geometry.coordinates = transResult
          } else if (transformType === 'gcj02-wgs84') {
            const transResult = gcj02towgs84(
              coordinates[0],
              coordinates[1]
            ) as GeojsonCoordinate
            element.resource.content.geometry.coordinates = transResult
            console.log(element, 'element')
          }
        } else if (MapElementEnum.LINE === type && geoType === 'LineString') {
          const coordinates = element.resource?.content.geometry
            .coordinates as GeojsonCoordinate[]
          if (transformType === 'wgs84-gcj02') {
            coordinates.forEach(coordinate => {
              coordinate = wgs84togcj02(
                coordinate[0],
                coordinate[1]
              ) as GeojsonCoordinate
            })
          } else if (transformType === 'gcj02-wgs84') {
            coordinates.forEach(coordinate => {
              coordinate = gcj02towgs84(
                coordinate[0],
                coordinate[1]
              ) as GeojsonCoordinate
            })
          }
          element.resource.content.geometry.coordinates = coordinates
        } else if (MapElementEnum.LINE === type && geoType === 'Polygon') {
          const coordinates = element.resource?.content.geometry
            .coordinates[0] as GeojsonCoordinate[]

          if (transformType === 'wgs84-gcj02') {
            coordinates.forEach(coordinate => {
              coordinate = wgs84togcj02(
                coordinate[0],
                coordinate[1]
              ) as GeojsonCoordinate
            })
          } else if (transformType === 'gcj02-wgs84') {
            coordinates.forEach(coordinate => {
              coordinate = gcj02towgs84(
                coordinate[0],
                coordinate[1]
              ) as GeojsonCoordinate
            })
          }
          element.resource.content.geometry.coordinates = [coordinates]
        }
      }
    }
    return {
      store,
      moudownTrue,
      flyHeight,
      markers,
      gotoPoint,
      createWaylineVisible,
      toolShow,
      draw,
      mouseMode,
      drawVisible,
      osdVisible,
      pin,
      state,
      deletePoint,
      M30,
      deviceInfo,
      EGear,
      EModeCode,
      str,
      EDockModeCode,
      dockControlPanelVisible,
      setDockControlPanelVisible,
      onCloseControlPanel,
      NetworkStateTypeEnum,
      NetworkStateQualityEnum,
      RainfallEnum,
      DroneInDockEnum,
      moudown,
      mouup,
      clickbtn,
      addpointline,
      clickSpace,
      focal,
      nextPoint,
      pointNum,
      waylineLength,
      mediaNum,
      waypointArr,
      marks,
      lat,
      lng,
      carpitch,
      carpitchtop,
      flyhead,
      alt,
      flyheadCss,
      picurl,
      leftrotation,
      forward,
      rightrotation,
      left,
      back,
      right,
      up,
      caup,
      down,
      cadown,
      showplan,
      root,
      ERouterName,
      carm,
      sx,
      barWidth,
      distanceLabel,
      jt,
      xz,
      caforward,
      cabackward,
      flydock,
      paizhao,
      shexiang,
      wenhao,
      pointDelete
    }
  }
})
</script>

<style lang="scss" >
#g-container {
  border-radius: 6px;
}

.g-map-wrapper {
  height: 100%;
  width: 100%;

  .g-action-panel {
    position: absolute;
    top: 30px;

    // right: 16px;
    .g-action-item {
      width: 28px;
      height: 28px;
      background: white;
      color: $primary;
      border-radius: 2px;
      line-height: 28px;
      text-align: center;
      margin-bottom: 2px;
    }

    .g-action-item:hover {
      border: 1px solid $primary;
      border-radius: 2px;
    }
  }

  .selection {
    border: 1px solid $primary;
    border-radius: 2px;
  }

  // antd button 光晕
  &:deep(.ant-btn) {
    &::after {
      display: none;
    }
  }
}

.osd-panel {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 480px;
  background: #000;
  color: #fff;
  border-radius: 2px;
  opacity: 0.8;
}

.osd>div:not(.dock-control-panel) {
  margin-top: 5px;
  padding-left: 5px;
}

.circle {
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.battery-slide {
  .capacity-percent {
    background: #00ee8b;
  }

  .return-home {
    background: #ff9f0a;
  }

  .landing {
    background: #f5222d;
  }

  .white-point {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: white;
    bottom: -0.5px;
  }

  .battery {
    background: #141414;
    color: #00ee8b;
    margin-top: -10px;
    height: 20px;
    width: auto;
    border-left: 1px solid #00ee8b;
    padding: 0 5px;
  }
}

.battery-slide>div {
  position: absolute;
  min-height: 2px;
  border-radius: 2px;
}

.content-markerrr {
  width: 15px;
  height: 15px;
  font-size: 12px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 15px;
  position: relative;

  .text-markerrr {
    position: absolute;
    top: 3px;
    right: 0;
  }

  .text-markerrrs {
    position: absolute;
    top: 3px;
    right: -3px;
  }
}

#content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  .makeplan {
    position: absolute;
    bottom: 70px;
    left: calc(50% - 600px);
    width: 790px;
    height: 287px;
    // background: rgba(0, 0, 0, 0.3);
    background: rgba(27, 27, 27, 0.95);
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    padding: 10px;

    .planbody {
      display: flex;
      justify-content: space-between;

      .planlt {
        width: 250px;
        background: #2A2A2A;
        opacity: 0.5;
        height: 267px;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        flex-wrap: wrap;
        padding: 14px 34px;

        .bp {
          margin-top: 39px;
          width: 144px;
          height: 144px;
          background: #1B1B1B;
          background-image: url('../assets/waylines/bg.png');
          background-size: contain;
          border-radius: 72px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          position: relative;

          h5 {
            font-size: 20px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #3A63F3;
            margin: 0;
          }

          span {
            font-size: 17px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #FFFFFF;
          }

          div {
            width: 45%;
            height: 45%;
            position: absolute;
            top: 40px;
            left: 40px;
            display: flex;

            transform: v-bind(flyheadCss);

            img {
              position: absolute;
              top: 0;
              right: 0;
              width: 12px;
              height: 12px;
            }

          }
        }

        .modeltitle {
          height: 33px;
          background: #1B1B1B;
          border-radius: 5px;
          font-size: 12px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #818386;
          text-align: center;
          line-height: 33px;
          padding: 0 16px;
        }

        .planbtns {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: center;
          width: 100%;
          margin-top: 10px;

          .plline {
            width: 1px;
            height: 92px;
            opacity: 0.19;
            border: 1px solid #979797;
          }

          .ptl {
            width: 102px;
            display: flex;
            flex-wrap: wrap;

            .planbtn {
              margin-right: 6px;
              margin-bottom: 6px;

              &:nth-of-type(3n) {
                margin-right: 0px;
              }

              span {
                display: inline-block;
                width: 100%;
                height: 29px;
                font-size: 13px;
                text-align: center;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 29px;
              }

              div {
                width: 30px;
                height: 30px;
                background: #757575;
                border-radius: 5px 5px 5px 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-size: 12px;
                font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                font-weight: bold;
                color: #FFFFFF;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              .down {
                background-color: #3A63F3 !important;
              }

            }
          }

        }

        .kg {
          width: 185px;
          height: 46px;
          background: #505050;
          box-shadow: inset 0px 0px 7px 4px rgba(0, 0, 0, 0.5), inset 3px 3px 7px 0px rgba(255, 255, 255, 0.37);
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #fff;
          line-height: 44px;
          text-align: center;
          margin-top: 17px;
          cursor: pointer;
        }

        .kgd {
          background-color: #2b99ff !important;
        }

        .carposs {
          display: flex;
          text-align: center;
          justify-content: space-between;
          margin-top: 41px;
          width: 100%;

          .carpos {
            width: 102px;
            height: 66px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            // align-items: center;

            .posbtns {
              width: 30px;
              height: 30px;
              background: #757575;
              border-radius: 5px 5px 5px 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .btd {
              background-color: #2b99ff !important;
            }

            .posbot {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }

          .carmr {

            .carmh {
              cursor: pointer;
              display: flex;
              margin-bottom: 6px;

              h6 {
                font-size: 12px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #818386;
                line-height: 30px;
                margin: 0px;
              }

              img {
                margin-left: 12px;
                width: 43px;
                height: 30px;
              }
            }
          }
        }

        .carboo {
          width: 100%;
          display: flex;
          justify-content: space-between;
          height: 46px;
          margin-top: 42px;

          .godbox {
            width: 126px;
            height: 46px;
            background: #4D4D4D;
            box-shadow: inset 0px 0px 7px 4px rgba(72, 72, 72, 0.5), inset 3px 3px 7px 0px rgba(255, 255, 255, 0.37);
            cursor: pointer;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            text-align: center;
            line-height: 46px;
          }

        }
      }

      .rr {
        width: 306px;
        padding: 14px 20px;
      }

      .ll {
        width: 191px;
      }
    }
  }

  .tb {
    position: absolute;
    bottom: 15px;
    right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 126px;
    width: 38px;

    .tbt {
      width: 38px;
      height: 38px;
      cursor: pointer;
      background-image: url('../assets/waylines/suotou.png');
      background-size: contain;
    }

    .tbb {
      height: 75px;
      width: 38px;
      cursor: pointer;
      background-image: url('../assets/waylines/suofang.png');
      background-size: contain;

      .goo {
        height: 32.5px;

      }
    }
  }

  .infoplan {
    height: 70px;
    background: rgba(27, 27, 27, 0.5);
    backdrop-filter: blur(4px);
    position: absolute;
    bottom: 0;
    width: 100%;
    // width: 1000px;
    left: 0px;
    display: flex;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #FFFFFF;
    align-items: center;
    padding: 0 51px;

    .pll {
      margin-right: 57px;
      display: flex;
      align-items: center;

      .yuan {
        width: 16px;
        height: 16px;
        border: 5px solid #D4D4D4;
        border-radius: 16px;
        margin-right: 21px;
      }
    }

    .xz {
      margin-right: 60px;
    }

    .plr {
      margin-right: 130px;
    }

    img {
      width: 16px;
      height: 17px;
    }

    .scale-bar {
      position: relative;
      padding-top: 10px;
    }

    .scale-bar::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 10px;
      border: 1px solid #fff;
      border-top: none;
      left: 0;
      bottom: 0;
    }
  }

  .right_plan {
    position: absolute;
    top: 100px;
    right: 20%;
    height: 80%;
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .plan_cent {
      width: 100px;

      .dobox {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        .wz {
          margin-right: 8px;

          h5 {
            color: #fff;
            font-size: 12px;
            text-align: right;
            font-weight: 500;
          }
        }

        .doimg {
          width: 32px;
          height: 32px;
          background-color: #3c3c3c;
          border-radius: 2px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      h4 {
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        white-space: nowrap;
        color: #fff;
      }
    }
  }

  .plan {
    position: absolute;
    bottom: 10%;
    left: 15%;
    width: 50%;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .pitch {
      font-size: 16px;
      // line-height: 24px;
      font-weight: 600;
      color: #00ee8b;
      position: relative;

      img {
        position: absolute;
        width: 20px;
        height: 20px;
        top: v-bind(carpitchtop);
        left: -18px;
      }

      span {
        position: absolute;
        top: -25px;
        left: 0;
      }
    }

    .pitch_bar {
      width: 7px;
      height: 100px;
      color: hsla(0, 0%, 100%, .2);
      box-shadow: 0 0 0 0.4px rgba(0, 0, 0, .6);
      background: currentcolor;
      position: relative;

      .alt {
        position: absolute;
        bottom: -30px;
        left: -10px;
        color: #fff;
        font-size: 12px;
        line-height: 20px;
        font-weight: 600;
        white-space: nowrap;
      }

      .markcenter {
        width: 14px;
        height: 2px;
        left: 0;
        top: calc(50% - 1px);
        background-color: #fff;
        position: absolute;
        transform: translateY(-50%);
        box-shadow: 0 0 0 0.4px rgba(0, 0, 0, .6);
      }

      .markcenter1 {
        width: 14px;
        height: 2px;
        right: 0;
        top: calc(50% - 1px);
        background-color: #fff;
        position: absolute;
        transform: translateY(-50%);
        box-shadow: 0 0 0 0.4px rgba(0, 0, 0, .6);
      }

      .marktop {
        width: 7px;
        height: 2px;
        left: 0;
        top: calc(25% - 1px);
        background-color: #fff;
        position: absolute;
        transform: translateY(-50%);
        box-shadow: 0 0 0 0.4px rgba(0, 0, 0, .6);
      }

      .markbottom {
        width: 7px;
        height: 2px;
        left: 0;
        top: calc(75% - 1px);
        background-color: #fff;
        position: absolute;
        transform: translateY(-50%);
        box-shadow: 0 0 0 0.4px rgba(0, 0, 0, .6);
      }
    }

    .p_du {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .dubox {
        background-color: #232323;
        padding: 4px 8px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        color: #fff;

        div {
          border-color: #484848;
          background-color: #484848;
          color: hsla(0, 0%, 100%, .25);
          padding: 1px 8px;
          width: 133px;
          margin: 0 3px;
          overflow: hidden;
        }
      }
    }

    .cent {
      position: relative;

      h5 {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: #00ee8b;
        text-align: center;
        position: absolute;
        top: -25px;
        left: 70px;
      }

      .zn {
        width: 150px;
        height: 150px;
        border-radius: 75px;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          position: relative;
          width: 30px;
          z-index: 1;
        }
      }

      .zn::before {
        content: "";
        position: absolute;
        width: 150px;
        height: 150px;
        top: 0;
        left: 0;
        z-index: 0;
        background: url('../src/assets/icons/zn1.png') 0 0 no-repeat;
        background-size: contain;
        transform: v-bind(flyheadCss);

      }

    }

    .p_left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      h4 {
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        color: #00ee8b;
      }

      .btns {
        width: 100%;
        background: rgba(0, 0, 0, .65);
        border-radius: 8px;
        display: flex;
        padding: 4px 8px 8px;
        justify-content: space-between;
        align-items: center;

        .move_btn {
          width: 24px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          div {
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            background-color: #3c3c3c;
            border-radius: 2px;
            color: #fff;
            cursor: pointer;
            width: 100%;

            &:hover {
              background-color: #5d5f61;
            }
          }

          .down {
            background-color: #2b99ff !important;
          }

          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }

}

.operateUav {
  position: absolute;
  bottom: 70px;
  left: calc(50% - 500px);
  width: 811px;
  height: 186px;
  // background: rgba(0, 0, 0, 0.3);
  background: rgba(27, 27, 27, 0.95);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .operateUav-btn {
    height: 100%;
    width: 110px;
    // height: 146px;
    background-color: #404040;
    box-shadow: inset 0px 0px 7px 4px rgba(0, 0, 0, 0.5), inset 3px 3px 7px 0px rgba(255, 255, 255, 0.37);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .operateUav-btn-text1 {
      font-size: 16px;
      font-family: AppleSystemUIFont;
      color: #FFFFFF;
    }

    .operateUav-btn-text2 {
      font-size: 12px;
      font-family: SourceHanSansSC-Regular, SourceHanSansSC;
      font-weight: 400;
      color: #696969;
    }
  }

  .padding20 {
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 20px;
  }

  .operateUav-body {
    background: #2A2A2A;
    opacity: 0.5;
    width: 283px;
    height: 146px;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    padding: 14px 34px;

    .operateUav-body-msg {
      height: 100%;
      display: flex;
      flex-direction: column;

      .operateUav-body-msg-item {
        display: flex;
        flex: 1;
        align-items: center;

        .msg-item-left {
          width: 135px;
          height: 35px;
        }

        .msg-item-right {
          margin-left: 25px;

          color: #fff;
        }
      }
    }

    .operateUav-body-btns {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;

      .plline {
        width: 1px;
        height: 92px;
        opacity: 0.19;
        border: 1px solid #979797;
      }

      .ptl {
        width: 102px;
        display: flex;
        flex-wrap: wrap;

        .planbtn {
          margin-right: 6px;
          margin-bottom: 6px;

          &:nth-of-type(3n) {
            margin-right: 0px;
          }

          span {
            display: inline-block;
            width: 100%;
            height: 29px;
            font-size: 13px;
            text-align: center;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 29px;
          }

          div {
            width: 30px;
            height: 30px;
            background: #757575;
            border-radius: 5px 5px 5px 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 12px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #FFFFFF;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .down {
            background-color: #3A63F3 !important;
          }

        }
      }

    }
  }

}

#right {
  position: relative;
  width: 449px;
  height: 100%;
  overflow: hidden;
  background: #FDFDFD;
  margin: 0 15px;
  box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.16);
  border-radius: 5px;

  .infoboxs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-top: 90px;
    border-bottom: 1px solid #D8D8D8;
    padding: 0 15px;
    height: 110px;

    .infobox {
      h5 {
        text-align: center;
        height: 24px;
        font-size: 15px;
        font-weight: 500;
        color: #000000;
        line-height: 24px;
      }
    }
  }

  .hdj {
    padding: 18px 31px 0 31px;
    overflow: auto;
    height: calc(100% - 90px);

    .hd {
      background: #FFFFFF;
      border-radius: 5px 5px 5px 5px;
      opacity: 1;
      margin-bottom: 20px;
      background: #FFFFFF;
      border-radius: 5px;
      border: 1px solid #3A63F3;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #000000;

      .hd-header {
        display: flex;
        justify-content: space-between;
        margin-top: 18px;
        margin-left: 12px;

        img {
          width: 20px;
          height: 20px;
        }

        .ff {
          width: 34px;
          height: 34px;
          margin-right: 12px;
        }
      }

      .hd-imgs {
        display: flex;
        margin: 10px;
        flex-wrap: wrap;

        .imgs-item {
          margin: 0 5px;

          img {
            width: 23px;
            height: 16px;

          }
        }
      }
    }
  }
}

// #eye {
//   position: absolute;
//   width: 384px;
// height: 255px;

//   bottom: 0;
//   right: 0;
//   z-index: 1;
//   background: black;
// }

#credit {
  display: none;
}

// #pic {
//   width: 100%;
//   height: 40%;
//   background: black;
//   position: absolute;
//   top: 0;
//   right: 0;
// }
</style>
