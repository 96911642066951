<template>
  <div class="create-plan-wrapper">
    <div class="header">
      创建计划
    </div>
    <div class="content">
      <div class="box">
        <div class="box-text">
          <div class="df">
            <div class="dd"></div> 计划名称
          </div>
          <div></div>
        </div>
        <div class="box-input">
          <a-input style="width: 306px;height: 34px;background-color: #F2F2F2;;" placeholder="输入计划名称"
            v-model:value="planBody.name" />
        </div>
      </div>
      <div class="box">
        <div class="box-text">
          <div class="df">
            <div class="dd"></div> 飞行路线
          </div>
          <div class="gogo" @click="selectRoute">
            选择航线
          </div>
        </div>
        <div class="box_pro">
          <div class="waylist" v-if="nowwayline">
            <div class="cl">{{ nowwayline.name }}</div>
            <div class="ct">
              <div class="yy"></div>
              {{ nowwayline.user_name }}
            </div>
            <div class="tt">{{ timechange(nowwayline.update_time) }}</div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="box-text">
          <div class="df">
            <div class="dd"></div> 设备
          </div>
          <div class="gogo " @click="selectDevice">
            选择设备
          </div>
        </div>
        <div class="box_pro">
          <div class="docklist" v-if="nowdock">
            <div class="yy"></div>
            <div class="cl">{{ nowdock.gateway.model }} -- {{ nowdock.gateway.callsign }}</div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="box-text">
          <div class="df">
            <div class="dd"></div> RTH相对于机场高度（m）
          </div>
          <div>
          </div>
        </div>
        <div class="box-input">
          <a-input-number style="width: 306px;height: 34px;background-color: #F2F2F2;" :min="20" :max="1500"
            placeholder="20m-1500m" v-model:value="planBody.rth_altitude" />
        </div>
      </div>
      <div class="box">
        <div class="box-text">
          <div class="df">
            <div class="dd"></div> 失联操作
          </div>
          <div>
          </div>
        </div>
        <div class="box-input">
          <div class="bts">
            <div :class="{ 'bt': true, 'check': planBody.out_of_control_action == OutOfControlAction.ReturnToHome }"
              @click="planBody.out_of_control_action = OutOfControlAction.ReturnToHome">返航</div>
            <div :class="{ 'bt': true, 'check': planBody.out_of_control_action == OutOfControlAction.Hover }"
              @click="planBody.out_of_control_action = OutOfControlAction.Hover">悬停</div>
            <div :class="{ 'bt': true, 'check': planBody.out_of_control_action == OutOfControlAction.Land }"
              @click="planBody.out_of_control_action = OutOfControlAction.Land">降落</div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="box-text">
          <div class="df">
            <div class="dd"></div> 计划时间
          </div>
          <div>
          </div>
        </div>
        <div class="box-input">
          <div class="bts">
            <div :class="{ 'bt': true, 'check': planBody.task_type == TaskType.Immediate }"
              @click="planBody.task_type = TaskType.Immediate">即时飞行</div>
            <div :class="{ 'bt': true, 'check': planBody.task_type == TaskType.Timing }"
              @click="planBody.task_type = TaskType.Timing">定时飞行</div>
            <div :class="{ 'bt': true, 'check': planBody.task_type == TaskType.continuous }"
              @click="planBody.task_type = TaskType.continuous">重复定时</div>
          </div>

        </div>
        <div v-if="planBody.task_type == TaskType.continuous" class="btbox">
          <div class="btboxti">执行日期</div>
          <div>
            <a-range-picker :disabledDate="disabledDate" :locale="locale" @change="conChange" style="width: 306px;">
              <template #suffixIcon>
                <SmileOutlined />
              </template>
            </a-range-picker>
          </div>
        </div>
        <div v-if="planBody.task_type == TaskType.Timing" class="btbox">
          <div class="btboxti">执行时间</div>
          <div>
            <a-date-picker style="width: 306px;" @change="onChange" :locale="locale"
              v-model:value="planBody.select_execute_time" show-time placeholder="请选择时间" />
          </div>
        </div>
        <div v-if="planBody.task_type == TaskType.continuous" class="btbox">
          <div class="btboxti">执行时间</div>
          <div class="timebox" v-for="(i, index) in tims" :key="i.id">
            <a-time-picker @change="cgt()" v-model:value="tims[index].tm" style="width: 248px;height: 34px;"
              format="HH:mm:ss" placeholder="请选择时间" />
            <img :src="add" @click="addtim" alt="">
            <img v-if="i.id == 0" :src="subtarct" alt="">
            <img v-else :src="subtred" @click="subttim(i.id)" alt="">
          </div>

        </div>
        <div v-if="planBody.task_type == TaskType.continuous" class="btbox">
          <div class="btboxti">重复频率</div>
          <div class="nb">
            每<a-input-number @change="confun(frequency_label,frequency_num, condays, constarday)"
              :min="1" style="width: 136px;height: 34px;background-color: #F2F2F2;;"
              v-model:value="frequency_num" />
            <a-select ref="select" @change="confun(frequency_label, frequency_num, condays, constarday)"
              v-model:value="frequency_label" style="width: 136px;height: 34px;">
              <a-select-option v-for="item in frequencylist" :value="item.value">{{ item.label }}</a-select-option>
            </a-select>
          </div>
        </div>
        <div class="btbox" v-if="planBody.task_type == TaskType.continuous && frequency_label == '2'">
          <div class="btboxti">重复规则</div>
          <div class="nb">
            <a-select @change="confun(frequency_label, frequency_num, condays, constarday)"
              v-model:value="repetitive_rule" ref="select" style="width: 306px;height: 34px;">
              <a-select-option value="week">按星期</a-select-option>
              <a-select-option value="day">按天</a-select-option>
            </a-select>
          </div>
          <div class="nb" v-if="repetitive_rule == 'week'">
            <a-select @change="confun(frequency_label, frequency_num, condays, constarday)" ref="select"
              v-model:value="forweek" style="width: 148px;height: 34px;">
              <a-select-option :value="1">第一个</a-select-option>
              <a-select-option :value="2">第二个</a-select-option>
              <a-select-option :value="3">第三个</a-select-option>
              <a-select-option :value="4">第四个</a-select-option>
              <a-select-option :value="5">第五个</a-select-option>
            </a-select>
            <a-select ref="select" @change="confun(frequency_label, frequency_num, condays, constarday)"
              v-model:value="weeknums" style="width: 148px;height: 34px;">
              <a-select-option v-for="item in weeklist" :value="item.value">{{ item.label }}</a-select-option>
            </a-select>
          </div>
          <div class="rq" v-if="repetitive_rule == 'day'">
            <div :class="{ 'rqbox': true, 'cks': (days.indexOf(item) != -1) }" @click="takeday(item)" v-for="item in 31">
              {{
                item }}</div>
          </div>
        </div>
        <div class="weeks" v-if="planBody.task_type == TaskType.continuous && frequency_label == '1'">
          <div v-for="item in  weeklist " :class="{ 'weekbox': true, 'cks': (weeks.indexOf(item.value) != -1) }"
            @click="takeweek(item.value)">{{ item.label }}</div>
        </div>
      </div>

      <div class="box">
        <div class="box-text">
          <div class="df">
            <div class="dd"></div> 断点续飞
          </div>

          <a-switch v-model:checked="isrenew" />

        </div>
      </div>
    </div>
    <div class="foot">
      <a-button class="mr10" style="width: 110px; color:black;border:1px solid rgb(217, 217, 217)" @click="closePlan">取消
      </a-button>
      <a-button type="primary" style="width: 110px;" @click="onSubmit" :disabled="disabled">确定
      </a-button>
    </div>
  </div>
  <div v-if="drawerVisible" class="edia">
    <div class="diati">{{ dianame }}</div>
    <div v-if="routeName == 'Newdevices'">
      <!-- <router-view :name="routeName" /> -->

      <div v-for=" dock  in  onlineDocks.data " :key="dock.gateway.sn" class="dev" @click="makeDevice(dock)">
        <div class="deh">
          <div class="dd"></div>
          {{ dock.gateway.model }} -- {{ dock.gateway.callsign }}
        </div>
        <div class="deb">
          <div class="debx">
            <img :src="state1" alt="">
            无人机状态
            <a-divider type="vertical" />
            <div>{{ dockInfo[dock.gateway.sn] ?
              EDockModeCodeTranslate(EDockModeCode[dockInfo[dock.gateway.sn].basic_osd?.mode_code]) :
              EDockModeCodeTranslate(EDockModeCode[EDockModeCode.Disconnected]) }}</div>
          </div>
          <div class="debx">
            <img :src="state2" alt="">
            无人机运行状态
            <a-divider type="vertical" />
            <div>{{ deviceInfo[dock.sn] ? EDockModeCodeTranslate(EModeCode[deviceInfo[dock.sn].mode_code]) :
              EDockModeCodeTranslate(EModeCode[EModeCode.Disconnected]) }}</div>
          </div>

        </div>
      </div>

    </div>


    <div v-else-if="routeName == 'WaylinePanel'">
      <div class="dirs" v-for=" item  in  waylist " :key="item.id">
        <div class="dir" style="cursor:pointer" @click="item.show = !item.show">
          <div class="dirleft">
            <img src="../../assets/waylines/position.png" alt="">
            <div class="wainame">{{ item.folder_name }}</div>
          </div>
          <img v-if="!item.show" src="../../assets/waylines/zhankai.png" alt="">
          <img v-else src="../../assets/waylines/shouhui.png" alt="">
        </div>
        <div v-if="!(item.wayline_file_dtos.length == 0) && item.show" class="dirway">
          <div v-for=" i  in  item.wayline_file_dtos " @click="makewayline(i)" :key="i.id" class="waylist">
            <div :title="i.name" class="cl">{{ i.name }}</div>
            <div :title="i.user_name" class="ct">
              <div class="yy"></div>
              {{ i.user_name }}
            </div>
            <div class="tt">{{ timechange(i.update_time) }}</div>
          </div>
        </div>
      </div>
    </div>


    <div style="position: absolute; top: 16px; right: 23px;">
      <a style="color: #000; width: 16px;height: 16px;" @click="closePanel">
        <CloseOutlined />
      </a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, reactive, ref, toRaw, UnwrapRef } from 'vue'
import { CloseOutlined, SmileOutlined, CameraFilled, UserOutlined } from '@ant-design/icons-vue'
import { ELocalStorageKey, ERouterName, EDeviceTypeName } from '/@/types'
import { useMyStore } from '/@/store'
import { WaylineType, WaylineFile } from '/@/types/wayline'
import { createPlan, CreatePlan, getWaylineFolder } from '/@/api/wayline'
import { SelectTypes } from 'ant-design-vue/es/select'
import { getRoot } from '/@/root'
import { TaskType, OutOfControlActionOptions, OutOfControlAction } from '/@/types/task'
import moment, { Moment } from 'moment'
import { RuleObject } from 'ant-design-vue/es/form/interface'
import { getDeviceTopo, getUnreadDeviceHms, updateDeviceHms } from '/@/api/manage'
import { OnlineDevice, EModeCode, Device, OSDVisible, EDockModeCode, DeviceOsd } from '/@/types/device'

import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import state1 from '/@/assets/item/state1.png'
import state2 from '/@/assets/item/state2.png'
import add from '/@/assets/item/add.png'
import subtarct from '/@/assets/item/subtarct.png'
import subtred from '/@/assets/item/subtred.png'
const nowwayline = ref()
const nowdock = ref()
const root = getRoot()
const store = useMyStore()
const isrenew = ref(false)
const dianame = ref('航线选择')
const waylist = ref()
const deviceInfo = computed(() => store.state.deviceState.deviceInfo)// 设备信息
const dockInfo = computed(() => store.state.deviceState.dockInfo)// 机场信息
const workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId)!
const hmsVisible = new Map<string, boolean>()// 存储信息（无人机和机场sn）
const onlineDocks = reactive({ // 在线机场
  data: [] as OnlineDevice[]
})
const makewayline = (item: any) => {
  nowwayline.value = item

  planBody.file_id = item.id
}
const makeDevice = (item: any) => {
  nowdock.value = item
  planBody.dock_sn = item.gateway.sn
}
const wayline = computed<WaylineFile>(() => {
  return store.state.waylineInfo
})

const dock = computed<Device>(() => {
  return store.state.dockInfo
})
const getwayline = async () => {
  const res = await getWaylineFolder(workspaceId)

  waylist.value = res.data.map((item) => {
    return { ...item, show: false }
  })
}
function EDockModeCodeTranslate(str: string) { // 翻译机场状态
  console.log(str, 'str')

  switch (str) {
    case 'Idle':
      return '闲置'
    case 'Disconnected':
      return '连接已断开'
    case '-1':
      return '未连接'
  }
}
// 获取在线内容
function getOnlineTopo() {
  getDeviceTopo(workspaceId).then((res) => { // Get Device Topo 获取工作中联机设备的拓扑表
    if (res.code !== 0) {
      return
    }
    onlineDocks.data = []

    res.data.forEach((gateway: any) => { // 遍历机场
      const child = gateway.children// 机场的无人机
      const device: OnlineDevice = { // 无人机信息
        model: child?.device_name, // 无人机名称
        callsign: child?.nickname, // 无人机昵称
        sn: child?.device_sn, // 无人机sn
        mode: EModeCode.Disconnected, // 代表断开连接
        gateway: { // 网关
          model: gateway?.device_name, // 机场名称
          callsign: gateway?.nickname, // 机场昵称
          sn: gateway?.device_sn, // 机场sn
          domain: gateway?.domain// 领域？3
        },
        payload: []// 负载
      }
      child?.payloads_list.forEach((payload: any) => { // 遍历无人机的负载列表
        device.payload.push({ // 存放到无人机信息对象中
          index: payload.index,
          model: payload.model,
          payload_name: payload.payload_name, // 负载名称：xxx相机
          payload_sn: payload.payload_sn,
          control_source: payload.control_source, // ?
          payload_index: payload.payload_index// ?
        })
      })
      if (EDeviceTypeName.Dock === gateway.domain) { // 如果设备类型（机场）？==当前设备类型
        hmsVisible.set(device.sn, false)
        hmsVisible.set(device.gateway.sn, false)

        onlineDocks.data.push(device)
      }
    })
    console.log('在线机场', onlineDocks.data);

  })
}
//时间戳转换
const timechange = (timestamp: any) => {
  let date = new Date(parseInt(timestamp));
  let Year = date.getFullYear();
  let Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
  let Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
  let Hour = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
  let Minute = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
  let GMT = Year + '-' + Moth + '-' + Day + '   ' + Hour + ':' + Minute
  return GMT
}
const disabled = ref(false)
const frequency = ref('')
const frequencylist = ref<SelectTypes['options']>([
  {
    value: '0',
    label: '日',
  },
  {
    value: '1',
    label: '周',
  },
  {
    value: '2',
    label: '月',
  },

]);
const weeks = ref([])
const weeklist = ref([{ value: 0, label: '周日' },
{ value: 1, label: '周一' },
{ value: 2, label: '周二' },
{ value: 3, label: '周三' },
{ value: 4, label: '周四' },
{ value: 5, label: '周五' },
{ value: 6, label: '周六' },])
const takeweek = (val: any) => {
  let i = weeks.value.indexOf(val)
  if (i == -1) {
    weeks.value.push(val)
  } else {
    weeks.value.splice(i, 1)
  }
  confun(frequency_label.value, frequency_num.value, condays.value, constarday.value)
}
const days = ref([])

const routeName = ref('')
const planBody = reactive({
  name: '',
  file_id: '',
  dock_sn: '',
  task_type: TaskType.Immediate,
  out_of_control_action: OutOfControlAction.ReturnToHome,
  task_days: [],
  task_periods: [],
  select_execute_time: '',
  rth_altitude: 0,
  wayline_type: 0,

})
const repetitive_rule=ref('week');
const frequency_label=ref('0')
const frequency_num=ref(1)
const tims = ref([{ id: 0, tm: '' }])
const weeknums = ref(0)
const forweek = ref(1)
const condays = ref(0) //重复定时天数
const constarday = ref() //重复定时开始日期时间戳
const constarst = ref()//重复定时开始日期
const onChange = (value: Moment[], dateString: string[]) => {
  let dat = new Date(dateString)
  planBody.task_days = [dat.valueOf()]
  planBody.task_periods = [[dat.valueOf()]]
  planBody.select_execute_time = dateString
};
const addtim = () => {
  tims.value.push({ id: tims.value.length, tm: '' })
}
const subttim = (id: any) => {
  tims.value = tims.value.filter((item) => {
    return item.id != id
  })
  cgt()
}
const cgt = () => {
  planBody.task_periods = []
  tims.value.forEach((item) => {
    planBody.select_execute_time = constarst.value + 'T' + item.tm._i + '.979Z'
    // planBody.select_execute_time = '2023-08-30T08:27:56.979Z'
    planBody.task_periods.push([(new Date(constarst.value + ' ' + item.tm._i).valueOf() / 1000)])
  })
}
const conChange = (value: Moment[], dateString: string[]) => {
  let stdat = new Date(dateString[0] + ' 00:00:00').valueOf()
  let eddat = new Date(dateString[1] + ' 23:59:59').valueOf()
  condays.value = parseInt((eddat - stdat) / 86400000) + 1
  constarday.value = stdat
  constarst.value = dateString[0]

  confun(frequency_label.value, frequency_num.value, condays.value, constarday.value)
};
const confun = (frelab: any, frenum: any, condays: any, constarday: any) => {
  planBody.task_days = []
  console.log(condays,4444);
  if (frelab == '0') {
    for (let i = 0; i < condays; i++) {
      if (i % frenum == 0) {
        planBody.task_days.push((constarday + i * 86400000) / 1000)
      }
    }
  } else if (frelab == '1') {
    let t = 0
  
    console.log(condays,4444);
    
    for (let i = 0; i < condays; i++) {
      console.log(new Date(constarday + i * 86400000).getDay(),5555);
      if (weeks.value.includes(new Date(constarday + i * 86400000).getDay())) {
        if (t % frenum == 0) {
          planBody.task_days.push((constarday + i * 86400000) / 1000)
        }

      }
      if (new Date(constarday + i * 86400000).getDay() == 0) {
        t += 1
      }
    }
  } else if (frelab == '2') {
    let t = 0
    if (repetitive_rule.value == 'day') {
      for (let i = 0; i < condays; i++) {
        if (days.value.includes(new Date(constarday + i * 86400000).getDate())) {
          if (t % frenum == 0) {
            planBody.task_days.push((constarday + i * 86400000) / 1000)
          }
        }
        if (new Date(constarday + (i + 1) * 86400000).getDate() === 1) {
          t = t + 1
        }
      }
    } else {
      for (let i = 0; i < condays; i++) {
        if (weeknums.value == new Date(constarday + i * 86400000).getDay() && forweek.value == getWeekDayOfMonth(constarday + i * 86400000)) {
          if (t % frenum == 0) {
            planBody.task_days.push((constarday + i * 86400000) / 1000)
          }
        }
        if (new Date(constarday + (i + 1) * 86400000).getDate() === 1) {
          t = t + 1
        }
      }
    }
  }
  cgt()
  console.log(planBody.task_days);

}
//获取是一个月中的第几周
function getWeekDayOfMonth(date1) {
  var date = new Date(date1)
  let month = date.getMonth() + 1
  let year = date.getFullYear()
  var firstDay = new Date(year, month - 1, 1).getDay();

  var week = Math.ceil((date.getDate() + firstDay) / 7);
  if (date.getDay() <= firstDay) {
    week = week - 1
  }

  return week;
}
const takeday = (val: any) => {
  let i = days.value.indexOf(val)
  if (i == -1) {
    days.value.push(val)
  } else {
    days.value.splice(i, 1)
  }
  confun(frequency_label.value, frequency_num.value, condays.value, constarday.value)
}
const drawerVisible = ref(false)
const valueRef = ref()
const rules = {
  name: [
    { required: true, message: '请输入计划名称.' },
    { max: 20, message: '长度限定为1到20', trigger: 'blur' }
  ],
  file_id: [{ required: true, message: '请选择路线' }],
  dock_sn: [{ required: true, message: '请选择设备' }],
  select_execute_time: [{ required: true, message: '选择开始时间' }],
  rth_altitude: [
    {
      validator: async (rule: RuleObject, value: string) => {
        if (!/^[0-9]{1,}$/.test(value)) {
          throw new Error('RTH 相对高度需要填写数字')
        }
      },
    }
  ],
  out_of_control_action: [{ required: true, message: '选择失联操作' }],
}
const disabledDate = (current: Moment) => {
  // Can not select days before today and today
  return current && current < moment().endOf('day');
};
async function onSubmit() {
  console.log(planBody, 11111111111111111);
  let res = await createPlan(workspaceId, planBody)
  console.log(res, 'asdasdasdad');

  // valueRef.value.validate().then(() => {
  //   disabled.value = true
  //   const createPlanBody = { ...planBody } as unknown as CreatePlan
  //   if (planBody.select_execute_time) {
  //     createPlanBody.task_days = [moment(planBody.select_execute_time).unix()]
  //     createPlanBody.task_periods = [createPlanBody.task_days]
  //   }
  //   createPlanBody.rth_altitude = Number(createPlanBody.rth_altitude)
  //   if (wayline.value && wayline.value.template_types && wayline.value.template_types.length > 0) {
  //     createPlanBody.wayline_type = wayline.value.template_types[0]
  //   }
  //   // console.log('planBody', createPlanBody)
  //   createPlan(workspaceId, createPlanBody)
  //     .then(res => {
  //       setTimeout(() => {
  //         disabled.value = false
  //       }, 1500)
  //     }).finally(() => {
  //       closePlan()
  //     })
  // }).catch((e: any) => {
  //   console.log('validate err', e)
  // })
}

function closePlan() {
  root.$router.push('/' + ERouterName.TASK)
}

function closePanel() {
  drawerVisible.value = false
  routeName.value = ''
}

function selectRoute() {
  drawerVisible.value = true
  getwayline()
  routeName.value = 'WaylinePanel'
  dianame.value = '航线选择'
}

function selectDevice() {
  drawerVisible.value = true
  getOnlineTopo()
  routeName.value = 'Newdevices'
  dianame.value = '设备选择'
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:deep(.ant-form-horizontal .ant-form-item-control) {
  flex: 1;
}

.create-plan-wrapper {
  background-color: #FDFDFD;
  position: absolute;
  top: 90px;
  left: 120px;
  z-index: 100;
  // color: fff;
  border-radius: 5px;
  padding: 16px 23px;
  // height: 100vh;
  height: 800px;
  display: flex;
  flex-direction: column;
  width: 400px;

  .header {
    height: 52px;
    font-size: 18px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #000000;
  }

  .foot {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    height: calc(100% - 132px);
    overflow-y: auto;

    .box {
      background: #FDFDFD;
      box-shadow: 0px 2px 4px 0px rgba(172, 172, 172, 0.3);
      border-radius: 5px;
      margin-bottom: 14px;
      padding-bottom: 10px;

      .box-text {
        height: 42px;
        background: #FFFFFF;
        box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.03);
        font-size: 16px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #000000;
        box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.03);
        display: flex;
        align-items: center;
        padding: 0 21px 0 23px;
        justify-content: space-between;

        .gogo {
          width: 102px;
          height: 30px;
          background: rgba(58, 99, 243, 0.1);
          border: 1px solid #3A63F3;
          text-align: center;
          line-height: 30px;
          font-size: 14px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #3A63F3;
          cursor: pointer;
          border-radius: 5px;
        }

        .df {
          display: flex;
          align-items: center;

        }

        .dd {
          width: 8px;
          height: 8px;
          background: #00D9FF;
          margin: 0 17px 0 0px;
        }
      }

      .box-input {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        .bts {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 0 23px;

          .bt {
            width: 92px;
            height: 30px;
            background: #DFDFDF;

            border-radius: 5px;
            text-align: center;
            line-height: 30px;
            font-size: 14px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #7F7F7F;
            cursor: pointer;
          }

          .check {
            background: #3A63F3;
            color: #FFFFFF;
          }
        }
      }

      .box_pro {
        color: #545454;

        .waylist {
          width: 306px;
          height: 34px;
          background: #F2F2F2;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
          line-height: 34px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: pointer;
          margin: 0 auto;
          margin-top: 13px;
          display: flex;
          justify-content: space-around;
          font-size: 12px;

          .cl {
            width: 50px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 12px;
          }

          .ct {
            width: 80px;
            display: flex;
            align-items: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 12px;

            .yy {
              width: 8px;
              height: 8px;
              background: #3A63F3;
              border-radius: 8px;
              margin-right: 14px;
            }
          }

          .tt {
            width: 120px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 12px;
          }

        }

        .docklist {
          width: 306px;
          height: 34px;
          background: #F2F2F2;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
          line-height: 34px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: pointer;
          margin: 0 auto;
          margin-top: 13px;
          display: flex;
          align-items: center;

          justify-content: flex-start;
          font-size: 12px;

          .yy {
            margin: 0 10px;
            width: 8px;
            height: 8px;
            background: #3A63F3;
            border-radius: 8px;
            margin-right: 14px;
          }

          .cl {
            width: 300px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 12px;
          }


        }
      }

      .btbox {
        padding: 0px 22px;
        color: #000000;

        .nb {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 14px;
        }

        .rq {
          display: flex;
          flex-wrap: wrap;

          .rqbox {
            width: 34px;
            height: 34px;
            background: #F2F2F2;
            text-align: center;
            line-height: 34px;
            margin-right: 10px;
            margin-bottom: 10px;
            cursor: pointer;

            &:nth-of-type(7n) {
              margin-right: 0;
            }
          }

          .cks {
            background: #3A63F3;
            color: #fff
          }
        }

        .btboxti {
          font-size: 14px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #000000;
          margin-bottom: 10px;
        }

        .timebox {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          img {
            width: 20px;
            height: 20px;
            cursor: pointer;
          }
        }
      }

      .weeks {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 0px 22px;

        .weekbox {
          width: 45px;
          height: 36px;
          background: #F2F2F2;
          text-align: center;
          line-height: 36px;
          margin-right: 6px;
          margin-bottom: 10px;
          cursor: pointer;
          font-size: 14px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #000000;
          border-radius: 5px;

          &:nth-of-type(6) {
            margin-right: 0;
          }
        }

        .cks {
          background: #3A63F3;
          color: #fff
        }
      }
    }

    form {
      margin: 10px;
    }

    form label,
    input {
      background-color: #fff;
      color: black;
    }

    .ant-input-suffix {
      color: #fff;
    }

    .plan-timer-form-item {
      flex-direction: column;
      display: flex;
      align-items: flex-start;
      margin-bottom: 15px;

      .ant-radio-button-wrapper {
        background-color: #fff;
        color: black;

        &.ant-radio-button-wrapper-checked {
          background-color: #1890ff;
          color: #fff
        }
      }
    }

    .lostControl {
      .ant-radio-button-wrapper {
        background-color: #fff;
        color: black;
        width: 140px;
        text-align: center;

        &.ant-radio-button-wrapper-checked {
          background-color: #1890ff;
          color: #fff
        }
      }
    }
  }

  .footer {
    display: flex;
    padding: 10px 0;

    button {
      width: 45%;
      color: #fff;
      border: 0;
    }
  }

  .rths {
    display: flex;
    height: 30px;
    line-height: 30px;

    .rthbtn {
      background: #ACACAC;
      height: 30px;
      text-align: center;
      padding: 0 5px;
      line-height: 30px;
      margin-right: 5px;
    }
  }

  .title-box {
    display: flex;
    align-items: center;
    background-color: #fff;
    color: black;

    img {
      width: 10px;
      height: 10px;
    }

    .box-ball {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #1890FF;
      margin-right: 10px;
    }

    .box-text {
      font-size: 18px;
      font-weight: 500;
      color: #000000;
    }

    .box-textSpeed {
      display: flex;
      font-size: 18px;
      font-weight: 500;

      .textSpeed-right {
        margin-left: 90px;
      }
    }
  }
}

.wayline-panel {
  background: #f3f3f3;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  height: 90px;
  width: 95%;
  font-size: 13px;
  border-radius: 2px;
  cursor: pointer;

  .title {
    display: flex;
    color: #000;
    flex-direction: row;
    align-items: center;
    height: 30px;
    font-weight: bold;
    margin: 0px 10px 0 10px;
  }
}

.panel {
  background: #f3f3f3;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  height: 70px;
  width: 95%;
  font-size: 13px;
  border-radius: 2px;
  cursor: pointer;

  .title {
    display: flex;
    color: #000;
    flex-direction: row;
    align-items: center;
    height: 30px;
    font-weight: bold;
    margin: 0px 10px 0 10px;
  }
}

.gogo {
  width: 123px;
  height: 39px;
  opacity: 1;
  border: 2px solid #D9D9D9;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  color: #000000;
  line-height: 39px
}

.edia {
  position: absolute;
  border-radius: 5px;
  left: 530px;
  top: 90px;
  width: 350px;
  max-height: 500px;
  float: right;
  padding: 30px 15px;
  bottom: 0;
  z-index: 1000;
  background-color: #fff;
  color: #000;
  overflow-y: scroll;

  .diati {
    font-size: 18px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #000000;
    position: absolute;
    left: 23px;
    top: 16px;
  }

  .dev {
    background: #FDFDFD;
    box-shadow: 0px 2px 4px 0px rgba(172, 172, 172, 0.3);
    margin: 0 auto;
    margin-top: 18px;
    width: 306px;
    cursor: pointer;

    .deh {
      display: flex;
      height: 42px;
      background: #FFFFFF;
      box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.03);
      font-size: 16px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #000000;
      align-items: center;

      .dd {
        width: 7px;
        height: 8px;
        background: #00D9FF;
        border-radius: 1px;
        margin: 0 20px;
      }
    }

    .deb {
      height: 114px;
      padding: 16px 20px;

      .debx {
        width: 263px;
        height: 34px;
        background: #F2F2F2;
        box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.01);
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #545454;
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        img {
          width: 15px;
          height: 15px;
          margin: 0 15px;
        }
      }
    }
  }
}



.dirs {
  margin-top: 18px;

  img {
    width: 12px;
    height: 12px;
  }

  .dir {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;

    .dirleft {
      display: flex;
      align-items: center;

      img {
        width: 12px;
        height: 12px;
        margin-right: 8px;
      }

      .wainame {
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #000000;
      }
    }
  }

  .dirway {

    .waylist {
      width: 306px;
      height: 34px;
      background: #F2F2F2;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      line-height: 34px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      margin: 0 auto;
      margin-top: 13px;
      display: flex;
      justify-content: space-around;
      font-size: 12px;

      .cl {
        width: 50px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 12px;
      }

      .ct {
        width: 80px;
        display: flex;
        align-items: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 12px;

        .yy {
          width: 8px;
          height: 8px;
          background: #3A63F3;
          border-radius: 8px;
          margin-right: 14px;
        }
      }

      .tt {
        width: 120px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 12px;
      }

    }
  }
}
</style>
