import { message } from 'ant-design-vue'
import {
  postPayloadAuth,
  postPayloadCommands,
  PayloadCommandsEnum,
  PostCameraModeBody,
  PostCameraFocalLengthBody,
  PostGimbalResetBody,
  PostCameraAimBody,
} from '/@/api/drone-control/payload'
import { ControlSource } from '/@/types/device'

export function usePayloadControl() {
  function checkPayloadAuth(controlSource?: ControlSource) {
    if (controlSource !== ControlSource.A) {
      message.error('请先获取负载权限')
      return false
    }
    return true
  }
  // 负载权限
  async function authPayload(sn: string, payloadIndx: string) {
    const { code } = await postPayloadAuth(sn, {
      payload_index: payloadIndx
    })
    if (code === 0) {
      // message.success('Get Payload Control successfully')
      // message.success('成功获取负载控制权限')
      console.log('成功获取负载控制权限')

      return true
    }
    return false
  }

  async function resetGimbal(sn: string, data: PostGimbalResetBody) {
    const { code } = await postPayloadCommands(sn, {
      cmd: PayloadCommandsEnum.GimbalReset,
      data: data
    })
    if (code === 0) {
      message.success('Gimbal Reset successfully')
    }
  }

  async function switchCameraMode(sn: string, data: PostCameraModeBody) {
    const { code } = await postPayloadCommands(sn, {
      cmd: PayloadCommandsEnum.CameraModeSwitch,
      data: data
    })
    if (code === 0) {
      // message.success('Camera Mode Switch successfully')
      message.success('镜头模式切换成功')
    }
  }

  async function takeCameraPhoto(sn: string, payloadIndx: string) {
    const { code } = await postPayloadCommands(sn, {
      cmd: PayloadCommandsEnum.CameraPhotoTake,
      data: {
        payload_index: payloadIndx
      }
    })
    if (code === 0) {
      // message.success('Take Photo successfully')
      message.success('拍照成功')
    }
  }

  async function startCameraRecording(sn: string, payloadIndx: string) {
    const { code } = await postPayloadCommands(sn, {
      cmd: PayloadCommandsEnum.CameraRecordingStart,
      data: {
        payload_index: payloadIndx
      }
    })
    if (code === 0) {
      message.success('开始录像')
    }
  }

  async function stopCameraRecording(sn: string, payloadIndx: string) {
    const { code } = await postPayloadCommands(sn, {
      cmd: PayloadCommandsEnum.CameraRecordingStop,
      data: {
        payload_index: payloadIndx
      }
    })
    if (code === 0) {
      message.success('结束录像')
    }
  }

  async function changeCameraFocalLength(sn: string, data: PostCameraFocalLengthBody) {
    const { code } = await postPayloadCommands(sn, {
      cmd: PayloadCommandsEnum.CameraFocalLengthSet,
      data: data,
    })
    if (code === 0) {
      // message.success('Zoom successfully')
      message.success('变焦成功!')
    }
  }

  async function cameraAim(sn: string, data: PostCameraAimBody) {
    const { code } = await postPayloadCommands(sn, {
      cmd: PayloadCommandsEnum.CameraAim,
      data: data,
    })
    if (code === 0) {
      // message.success('Zoom Aim successfully')
    }
  }

  return {
    checkPayloadAuth,
    authPayload,
    resetGimbal,
    switchCameraMode,
    takeCameraPhoto,
    startCameraRecording,
    stopCameraRecording,
    changeCameraFocalLength,
    cameraAim,
  }
}
