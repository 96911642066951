<template>
  <div class="operateUav">
    <!-- 负载部分 -->
    <!-- <div class="operateUav-btn operateUav-btnBlue">
      <div @click="onAuthPayload">
        <div class="operateUav-btn-text1">获取</div>
        <div class="operateUav-btn-text1">负载</div>
        <div class="operateUav-btn-text1">控制</div>
        <div class="operateUav-btn-text1">权限</div>
      </div>
    </div>

    <div class="operateUav-payload">
      <div class="box">
        <div class="row">
          <Select class="row-select" v-model:value="payloadSelectInfo.value" :options="payloadSelectInfo.options"
            @change="handlePayloadChange" />
        </div>
        <div class="row">
          <div class="row-item">
            <DroneControlPopover :visible="gimbalResetPopoverData.visible" :loading="gimbalResetPopoverData.loading"
              @confirm="($event) => onGimbalResetConfirm(true)" @cancel="($event) => onGimbalResetConfirm(false)">
              <template #formContent>
                <div class="form-content">
                  <div>
                    <span class="form-label">复位模式:</span>
                    <a-select v-model:value="gimbalResetPopoverData.resetMode" style="width: 180px"
                      :options="GimbalResetModeOptions"></a-select>
                  </div>
                </div>
              </template>
<Button size="small" ghost @click="onShowGimbalResetPopover">
  <span>重置云台</span>
</Button>
</DroneControlPopover>
</div>
<div class="row-item">
  <Button size="small" ghost @click="onSwitchCameraMode">
    <span>切换相机模式</span>
  </Button>
</div>

</div>
<div class="row">
  <div class="row-item">
    <Button size="small" ghost @click="onStartCameraRecording">
      <span>开始录像</span>
    </Button>
  </div>
  <div class="row-item">
    <Button size="small" ghost @click="onStopCameraRecording">
      <span>停止录像</span>
    </Button>
  </div>

</div>
<div class="row">
  <div class="row-item">
    <Button size="small" ghost @click="onTakeCameraPhoto">
      <span>拍照</span>
    </Button>
  </div>
  <div class="row-item">
    <DroneControlPopover :visible="zoomFactorPopoverData.visible" :loading="zoomFactorPopoverData.loading"
      @confirm="($event) => onZoomFactorConfirm(true)" @cancel="($event) => onZoomFactorConfirm(false)">
      <template #formContent>
                <div class="form-content">
                  <div>
                    <span class="form-label">相机类型:</span>
                    <a-select v-model:value="zoomFactorPopoverData.cameraType" style="width: 120px"
                      :options="ZoomCameraTypeOptions"></a-select>
                  </div>
                  <div>
                    <span class="form-label">变焦系数:</span>
                    <a-input-number v-model:value="zoomFactorPopoverData.zoomFactor" :min="2" :max="200" />
                  </div>
                </div>
              </template>
      <Button size="small" ghost @click="($event) => onShowZoomFactorPopover()">
        <span class="word" @click=";">变焦</span>
      </Button>
    </DroneControlPopover>
  </div>
  <div class="row-item">
    <DroneControlPopover :visible="cameraAimPopoverData.visible" :loading="cameraAimPopoverData.loading"
      @confirm="($event) => onCameraAimConfirm(true)" @cancel="($event) => onCameraAimConfirm(false)">
      <template #formContent>
                <div class="form-content">
                  <div>
                    <span class="form-label">相机类型:</span>
                    <a-select v-model:value="cameraAimPopoverData.cameraType" style="width: 120px"
                      :options="CameraTypeOptions"></a-select>
                  </div>
                  <div>
                    <span class="form-label">锁定机身:</span>
                    <a-switch v-model:checked="cameraAimPopoverData.locked" />
                  </div>
                  <div>
                    <span class="form-label">x:</span>
                    <a-input-number v-model:value="cameraAimPopoverData.x" :min="0" :max="1" />
                  </div>
                  <div>
                    <span class="form-label">y:</span>
                    <a-input-number v-model:value="cameraAimPopoverData.y" :min="0" :max="1" />
                  </div>
                </div>
              </template>
      <Button size="small" ghost @click="($event) => onShowCameraAimPopover()">
        <span class="word">AIM</span>
      </Button>
    </DroneControlPopover>
  </div>

</div>
</div>
</div>
<div class="operateUav-line"></div> -->
    <!-- 无人机部分 -->

    <DroneControlPopover :visible="takeoffToPointPopoverData.visible" :loading="takeoffToPointPopoverData.loading"
      @confirm="($event) => onTakeoffToPointConfirm(true)" @cancel="($event) => onTakeoffToPointConfirm(false)">
      <template #formContent>
        <div class="form-content">
          <div>
            <span class="form-label">纬度:</span>
            <a-input-number v-model:value="takeoffToPointPopoverData.latitude" />
          </div>
          <div>
            <span class="form-label">经度:</span>
            <a-input-number v-model:value="takeoffToPointPopoverData.longitude" />
          </div>
          <div>
            <span class="form-label">高度(m):</span>
            <a-input-number v-model:value="takeoffToPointPopoverData.height" />
          </div>
          <div>
            <span class="form-label">安全起飞高度(m):</span>
            <a-input-number v-model:value="takeoffToPointPopoverData.securityTakeoffHeight" />
          </div>
          <div>
            <span class="form-label">返航高度(m):</span>
            <a-input-number v-model:value="takeoffToPointPopoverData.rthAltitude" />
          </div>
          <div>
            <span class="form-label">失控动作:</span>
            <a-select v-model:value="takeoffToPointPopoverData.rcLostAction" style="width: 120px"
              :options="LostControlActionInCommandFLightOptions"></a-select>
          </div>
          <div>
            <span class="form-label">航线失控动作:</span>
            <a-select v-model:value="takeoffToPointPopoverData.exitWaylineWhenRcLost" style="width: 120px"
              :options="WaylineLostControlActionInCommandFlightOptions"></a-select>
          </div>
        </div>
      </template>

      <!-- <span>立即起飞起飞</span> -->
      <!-- 暂时注释 -->
      <!-- <div class="operateUav-btn" @click="onShowTakeoffToPointPopover">
        <div>
          <div class="operateUav-btn-text1">Strat</div>
          <div class="operateUav-btn-text2">立即起飞</div>
        </div>
      </div> -->

    </DroneControlPopover>

    <DroneControlPopover :visible="flyToPointPopoverData.visible" :loading="flyToPointPopoverData.loading"
      @confirm="($event) => onFlyToConfirm(true)" @cancel="($event) => onFlyToConfirm(false)">
      <template #formContent>
        <div class="form-content">
          <div>
            <span class="form-label">纬度:</span>
            <a-input-number v-model:value="flyToPointPopoverData.latitude" />
          </div>
          <div>
            <span class="form-label">经度:</span>
            <a-input-number v-model:value="flyToPointPopoverData.longitude" />
          </div>
          <div>
            <span class="form-label">高度(m):</span>
            <a-input-number v-model:value="flyToPointPopoverData.height" />
          </div>
        </div>
      </template>
      <!-- 暂时注释 -->
      <!-- <div class="operateUav-btn" @click="onShowFlyToPopover">
        <div>
          <div class="operateUav-btn-text2">飞向</div>
        </div>
      </div> -->

    </DroneControlPopover>
    <!-- 暂时注释 -->
    <!-- <div class="operateUav-btn" @click="onStopFlyToPoint">
      <div>
        <div class="operateUav-btn-text2">停止飞向</div>
      </div>
    </div> -->
    <!-- <div class="operateUav-body padding20">
        <div class="operateUav-body-msg">
            <div class="operateUav-body-msg-item">
                <div class="msg-item-left">
                  <a-input  v-model:value="flyHeight.outHeight" />
                </div>
                <div class="msg-item-right">安全离场高度</div>
            </div>
            <div class="operateUav-body-msg-item">
                <div class="msg-item-left">
                  <a-input  v-model:value="flyHeight.workHeight" />
                </div>
                <div class="msg-item-right">飞行作业高度</div>
            </div>
            <div class="operateUav-body-msg-item">
                <div class="msg-item-left">
                  <a-input  v-model:value="flyHeight.returnHeight" />
                </div>
                <div class="msg-item-right">返航高度</div>
            </div>
        </div>
      </div> -->
    <div class="operateUav-btn operateUav-btnBlue" @click="onClickFightControl()">
      <div v-if="flightController">
        <div class="operateUav-btn-text1">退出</div>
        <div class="operateUav-btn-text1">飞控</div>
        <div class="operateUav-btn-text1">模式</div>
      </div>
      <div v-else>
        <div class="operateUav-btn-text1">获取</div>
        <div class="operateUav-btn-text1">飞行</div>
        <div class="operateUav-btn-text1">权限</div>
      </div>
    </div>
    <!-- 展示信息 -->
    <div class="operateUav-status">
      <div class="status-item">
        <div class="status-position">
          <img class="status-item-img" :src="msgLine" alt="">
          <div class="status-item-data">
            {{
      deviceMsg.deviceInfo[dockAndAir.air_sn] ?
        deviceMsg.deviceInfo[dockAndAir.air_sn].height.toFixed(0) + 'm' : '--'
    }}
          </div>
        </div>
        <div class="status-item-text">海拔高度</div>
      </div>
      <div class="status-item">
        <div class="status-position">
          <img class="status-item-img" :src="msgLine" alt="">
          <div class="status-item-data">{{
      deviceMsg.deviceInfo[dockAndAir.air_sn] ?
        deviceMsg.deviceInfo[dockAndAir.air_sn].elevation.toFixed(0) + 'm' : '--'
    }}</div>
        </div>
        <div class="status-item-text">绝对高度</div>
      </div>
      <div class="status-item">
        <div class="status-position">
          <img class="status-item-img" :src="msgLine" alt="">
          <div class="status-item-data">{{
      deviceMsg.deviceInfo[dockAndAir.air_sn] ?
        deviceMsg.deviceInfo[dockAndAir.air_sn].horizontal_speed.toFixed(0) + 'm/s' : '--'
    }}</div>
        </div>
        <div class="status-item-text">水平速度</div>
      </div>

    </div>
    <!-- WASD方向面板 -->
    <div class="operateUav-body">
      <div class="operateUav-body-btns">
        <div class="ptl">
          <div class="planbtn">
            <span>Q</span>
            <div @mousedown="onMouseDown(KeyCode.KEY_Q)" @onmouseup="onMouseUp">
              <img :src="leftrotation" alt="">
            </div>
          </div>
          <div class="planbtn">
            <span>W</span>
            <div @mousedown="onMouseDown(KeyCode.KEY_W)" @onmouseup="onMouseUp" :class="{ 'down': clickbtn == 'KeyW' }">
              <img :src="forward" alt="">
            </div>
          </div>
          <div class="planbtn">
            <span>E</span>
            <div @mousedown="onMouseDown(KeyCode.KEY_E)" @onmouseup="onMouseUp" :class="{ 'down': clickbtn == 'KeyE' }">
              <img :src="rightrotation" alt="">
            </div>
          </div>
          <div class="planbtn">

            <div @mousedown="onMouseDown(KeyCode.KEY_A)" @onmouseup="onMouseUp" :class="{ 'down': clickbtn == 'KeyA' }">
              <img :src="left" alt="">
            </div>
            <span>A</span>
          </div>
          <div class="planbtn">

            <div @mousedown="onMouseDown(KeyCode.KEY_S)" @onmouseup="onMouseUp" :class="{ 'down': clickbtn == 'KeyS' }">
              <img :src="back" alt="">
            </div>
            <span>S</span>
          </div>
          <div class="planbtn">

            <div @mousedown="onMouseDown(KeyCode.KEY_D)" @onmouseup="onMouseUp" :class="{ 'down': clickbtn == 'KeyD' }">
              <img :src="right" alt="">
            </div>
            <span>D</span>
          </div>

        </div>
        <div class="plline"></div>
        <div class="ptl" style="width: 30px;">
          <div class="planbtn">
            <span>C</span>
            <div style="background-color: #3A63F3;" @mousedown="onMouseDown(KeyCode.ARROW_UP)" @onmouseup="onMouseUp"
              :class="{ 'down': clickbtn == 'KeyC' }">
              升
            </div>
          </div>

          <div class="planbtn">
            <div style="background-color: #3A63F3;" @mousedown="onMouseDown(KeyCode.ARROW_DOWN)" @onmouseup="onMouseUp"
              :class="{ 'down': clickbtn == 'KeyZ' }">
              降
            </div>
            <span>Z</span>
          </div>
        </div>

      </div>
    </div>

    <!-- <div class="operateUav-btn " @click="handleEmergencyStop" :class="{ 'down': clickbtn == 'Space' }">

      <div>
        <div class="operateUav-btn-text1">SPACE</div>
        <div class="operateUav-btn-text2">急停</div>
      </div>
    </div> -->
    <div class="operateUav-imgBtn " @click="handleEmergencyStop" :class="{ 'down': clickbtn == 'Space' }">

      <img :src="breakBtn" class="imgBtn-btn" alt="">
      <div class="imgBtn-text">
        <div class="imgBtn-text-t1">SPACE</div>
        <div class="imgBtn-text-t2">急停</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, ref, watch, computed, onMounted, watchEffect, onUnmounted } from 'vue'
import { Select, message, Button } from 'ant-design-vue'
import { PayloadInfo, DeviceInfoType, ControlSource, DeviceOsdCamera, DrcStateEnum } from '/@/types/device'
import { useMyStore } from '/@/store'
import { postDrcEnter, postDrcExit } from '/@/api/drc'
import { useMqtt, DeviceTopicInfo } from './use-mqtt'
import { DownOutlined, UpOutlined, LeftOutlined, RightOutlined, PauseCircleOutlined, UndoOutlined, RedoOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons-vue'
import { useManualControl, KeyCode } from './use-manual-control'
import { usePayloadControl } from './use-payload-control'
import { CameraMode, CameraType, CameraTypeOptions, ZoomCameraTypeOptions, CameraListItem } from '/@/types/live-stream'
import { useDroneControlWsEvent } from './use-drone-control-ws-event'
import { useDroneControlMqttEvent } from './use-drone-control-mqtt-event'
import { postFlightAuth, LostControlActionInCommandFLight, WaylineLostControlActionInCommandFlight } from '/@/api/drone-control/drone'
import { useDroneControl } from './use-drone-control'
import { GimbalResetMode, GimbalResetModeOptions, LostControlActionInCommandFLightOptions, WaylineLostControlActionInCommandFlightOptions } from '/@/types/drone-control'
import DroneControlPopover from './DroneControlPopover.vue'
import DroneControlInfoPanel from './DroneControlInfoPanel.vue'
import { noDebugCmdList as baseCmdList, DeviceCmdItem, DeviceCmd } from '/@/types/device-cmd'
import { useDockControl } from './use-dock-control'
import { getDockList, startFlyNow } from '/@/api/project'
import bus from '/@/utils/mitt'
import { ELocalStorageKey } from '/@/types/enums'
import breakBtn from '/@/assets/waylines/breakBtn.png'
import msgLine from '/@/assets/waylines/msgLine.png'
import rightrotation from '/@/assets/waylines/rdr.png'
import leftrotation from '/@/assets/waylines/lfd.png'
import forward from '/@/assets/waylines/up.png'
import left from '/@/assets/waylines/left.png'
import back from '/@/assets/waylines/dwon.png'
import right from '/@/assets/waylines/right.png'
import up from '/@/assets/icons/up.svg'

const props = defineProps<{
  sn: string,
  deviceInfo: DeviceInfoType,
  payloads: null | PayloadInfo[]
}>()
const workspaceId = ref(localStorage.getItem(ELocalStorageKey.WorkspaceId)!)
// 用于存放机场飞机相关数据
const dockAndAir = ref({
  air_sn: '',
  air_name: '',
  dock_name: '',
  dock_sn: '',
})
const clickbtn = ref('')
const mouset: any = null
const store = useMyStore()
const clientId = computed(() => {
  return store.state.clientId
})
const flyHeight = reactive({
  outHeight: 100, // 安全离场高度
  workHeight: 100, // 飞行作业高度
  returnHeight: 100, // 返航高度
})

const initCmdList = baseCmdList.find(item => item.cmdKey === DeviceCmd.ReturnHome)// 在指令集中寻找指令
const cmdItem = ref(initCmdList)
// 获取飞机真实数据参数
const deviceMsg = computed(() => {
  return store.state.deviceState
})
const {
  sendDockControlCmd
} = useDockControl()
const mouup = () => {
  clickbtn.value = ''
  clearInterval(mouset)
}

// 鼠标控制真实飞机
function moudownTrue(e: string) {
  clickbtn.value = e
  console.log(clickbtn.value)

  // mouset = setInterval(() => {
  //   if (changelist.includes(e)) {
  //     console.log('上下左右')
  //   } else if (e === 'KeyQ') {
  //     console.log('左旋')
  //   } else if (e === 'KeyE') {
  //     console.log('右旋')
  //   } else if (e === 'Space') {
  //     console.log('空格')
  //   }
  // }, 100)
}
// 键盘控制真实飞机
function maposTrue(e: any) {
  if (e.code === 'KeyA') {
    handleKeyup(KeyCode.KEY_A)
  } else
    if (e.code === 'KeyS') {
      handleKeyup(KeyCode.KEY_S)
    } else
      if (e.code === 'KeyD') {
        handleKeyup(KeyCode.KEY_D)
      } else
        if (e.code === 'KeyW') {
          handleKeyup(KeyCode.KEY_W)
        } else if (e.code === 'KeyQ') {
          handleKeyup(KeyCode.KEY_Q)
        } else if (e.code === 'KeyE') {
          handleKeyup(KeyCode.KEY_E)
        } else if (e.code === 'KeyC') {
          handleKeyup(KeyCode.ARROW_UP)
        } else if (e.code === 'KeyZ') {
          handleKeyup(KeyCode.ARROW_DOWN)
        } else if (e.code === 'Space') {
          console.log('空格')
        }
}
// 控制真实飞机按键抬起
function keyupUpTrue(e: any) {
  clickbtn.value = ''
}
// 返航及其他控制
async function sendControlCmd(cmdItem: DeviceCmdItem, index: number) {
  cmdItem.loading = true
  const result = await sendDockControlCmd({
    sn: props.sn,
    cmd: cmdItem.cmdKey,
    action: cmdItem.action
  }, false)
  if (result && flightController.value) {
    exitFlightCOntrol()
  }
  cmdItem.loading = false
}

const { flyToPoint, stopFlyToPoint, takeoffToPoint } = useDroneControl()
const MAX_SPEED = 14

const flyToPointPopoverData = reactive({
  visible: false,
  loading: false,
  latitude: null as null | number,
  longitude: null as null | number,
  height: null as null | number,
  maxSpeed: MAX_SPEED,
})

function onShowFlyToPopover() {
  flyToPointPopoverData.visible = !flyToPointPopoverData.visible
  flyToPointPopoverData.loading = false
  flyToPointPopoverData.latitude = null
  flyToPointPopoverData.longitude = null
  flyToPointPopoverData.height = null
}

async function onFlyToConfirm(confirm: boolean) {
  if (confirm) {
    if (!flyToPointPopoverData.height || !flyToPointPopoverData.latitude || !flyToPointPopoverData.longitude) {
      // message.error('Input error')
      return
    }
    try {
      await flyToPoint(props.sn, {
        max_speed: flyToPointPopoverData.maxSpeed,
        points: [
          {
            latitude: flyToPointPopoverData.latitude,
            longitude: flyToPointPopoverData.longitude,
            height: flyToPointPopoverData.height
          }
        ]
      })
    } catch (error) {
    }
  }
  flyToPointPopoverData.visible = false
}

async function onStopFlyToPoint() {
  await stopFlyToPoint(props.sn)
}

const takeoffToPointPopoverData = reactive({
  visible: false,
  loading: false,
  latitude: null as null | number,
  longitude: null as null | number,
  height: null as null | number,
  securityTakeoffHeight: null as null | number,
  maxSpeed: MAX_SPEED,
  rthAltitude: null as null | number,
  rcLostAction: LostControlActionInCommandFLight.RETURN_HOME,
  exitWaylineWhenRcLost: WaylineLostControlActionInCommandFlight.RETURN_HOME
})

function onShowTakeoffToPointPopover() {
  takeoffToPointPopoverData.visible = !takeoffToPointPopoverData.visible
  takeoffToPointPopoverData.loading = false
  takeoffToPointPopoverData.latitude = null
  takeoffToPointPopoverData.longitude = null
  takeoffToPointPopoverData.securityTakeoffHeight = null
  takeoffToPointPopoverData.rthAltitude = null
  takeoffToPointPopoverData.rcLostAction = LostControlActionInCommandFLight.RETURN_HOME
  takeoffToPointPopoverData.exitWaylineWhenRcLost = WaylineLostControlActionInCommandFlight.RETURN_HOME
}

async function onTakeoffToPointConfirm(confirm: boolean) {
  if (confirm) {
    if (!takeoffToPointPopoverData.height ||
      !takeoffToPointPopoverData.latitude ||
      !takeoffToPointPopoverData.longitude ||
      !takeoffToPointPopoverData.securityTakeoffHeight ||
      !takeoffToPointPopoverData.rthAltitude) {
      // message.error('Input error')
      return
    }
    try {
      await takeoffToPoint(props.sn, {
        target_latitude: takeoffToPointPopoverData.latitude,
        target_longitude: takeoffToPointPopoverData.longitude,
        target_height: takeoffToPointPopoverData.height,
        security_takeoff_height: takeoffToPointPopoverData.securityTakeoffHeight,
        rth_altitude: takeoffToPointPopoverData.rthAltitude,
        max_speed: takeoffToPointPopoverData.maxSpeed,
        rc_lost_action: takeoffToPointPopoverData.rcLostAction,
        exit_wayline_when_rc_lost: takeoffToPointPopoverData.exitWaylineWhenRcLost
      })
    } catch (error) {
    }
  }
  takeoffToPointPopoverData.visible = false
}

const deviceTopicInfo: DeviceTopicInfo = reactive({
  sn: props.sn,
  pubTopic: '',
  subTopic: ''
})

useMqtt(deviceTopicInfo)

// 飞行控制
const drcState = computed(() => {
  return store.state.deviceState?.dockInfo[props.sn]?.link_osd?.drc_state === DrcStateEnum.CONNECTED
})
const flightController = ref(false)
async function onClickFightControl() {
  console.log(flightController.value, '飞行控制相关')

  if (flightController.value) {
    exitFlightCOntrol()
    return
  }
  enterFlightControl()
}

// 进入飞行控制
async function enterFlightControl() {
  try {
    const { code, data } = await postDrcEnter({ // 获取飞行控制权
      client_id: clientId.value,
      dock_sn: props.sn, // 机场sn
    })
    if (code === 0) {
      flightController.value = true
      if (data.sub && data.sub.length > 0) {
        deviceTopicInfo.subTopic = data.sub[0]
      }
      if (data.pub && data.pub.length > 0) {
        deviceTopicInfo.pubTopic = data.pub[0]
      }
      // 获取飞行控制权
      if (droneControlSource.value !== ControlSource.A) {
        await postFlightAuth(props.sn)
      }

      message.success('成功获取飞行控制权！')
      // 获取权限后进行一次急停
      handleEmergencyStop()
    }
  } catch (error: any) {
  }
}

// 退出飞行控制
async function exitFlightCOntrol() {
  try {
    const { code } = await postDrcExit({
      client_id: clientId.value,
      dock_sn: props.sn,
    })
    if (code === 0) {
      flightController.value = false
      deviceTopicInfo.subTopic = ''
      deviceTopicInfo.pubTopic = ''
      message.success('结束飞行控制')
    }
  } catch (error: any) {
  }
}

// drc mqtt message
const { drcInfo } = useDroneControlMqttEvent(props.sn)

const {
  handleKeyup,
  handleEmergencyStop,
  resetControlState,
} = useManualControl(deviceTopicInfo, flightController)

function onMouseDown(type: KeyCode) {
  handleKeyup(type)
}

function onMouseUp() {
  resetControlState()
}

// 负载控制
const payloadSelectInfo = {
  value: null as any,
  controlSource: undefined as undefined | ControlSource,
  options: [] as any,
  payloadIndex: '' as string,
  camera: undefined as undefined | DeviceOsdCamera // 当前负载osd信息
}

const handlePayloadChange = (value: string) => {
  const payload = props.payloads?.find(item => item.payload_sn === value)
  if (payload) {
    payloadSelectInfo.payloadIndex = payload.payload_index || ''
    payloadSelectInfo.controlSource = payload.control_source
    payloadSelectInfo.camera = undefined
  }
}

// function getCurrentCamera (cameraList: CameraListItem[], cameraIndex?: string):CameraListItem | null {
//   let camera = null
//   cameraList.forEach(item => {
//     if (item.camera_index === cameraIndex) {
//       camera = item
//     }
//   })
//   return camera
// }

// const currentCamera = computed(() => {
//   return getCurrentCamera(props.deviceInfo.dock.basic_osd.live_capacity?.device_list[0]?.camera_list as CameraListItem[], camera_index)
// })
// 更新负载信息
watch(() => props.payloads, (payloads) => {
  if (payloads && payloads.length > 0) {
    payloadSelectInfo.value = payloads[0].payload_sn
    payloadSelectInfo.controlSource = payloads[0].control_source || ControlSource.B
    payloadSelectInfo.payloadIndex = payloads[0].payload_index || ''
    payloadSelectInfo.options = payloads.map(item => ({ label: item.payload_name, value: item.payload_sn }))
    payloadSelectInfo.camera = undefined
  } else {
    payloadSelectInfo.value = null
    payloadSelectInfo.controlSource = undefined
    payloadSelectInfo.options = []
    payloadSelectInfo.payloadIndex = ''
    payloadSelectInfo.camera = undefined
  }
}, {
  immediate: true,
  deep: true
})
watch(() => props.deviceInfo.device, (droneOsd) => {
  if (droneOsd && droneOsd.cameras) {
    payloadSelectInfo.camera = droneOsd.cameras.find(item => item.payload_index === payloadSelectInfo.payloadIndex)
  } else {
    payloadSelectInfo.camera = undefined
  }
}, {
  immediate: true,
  deep: true
})

// ws 消息通知
const { droneControlSource, payloadControlSource } = useDroneControlWsEvent(props.sn, payloadSelectInfo.value)
watch(() => payloadControlSource, (controlSource) => {
  payloadSelectInfo.controlSource = controlSource.value
}, {
  immediate: true,
  deep: true
})
const {
  checkPayloadAuth,
  authPayload,
  resetGimbal,
  switchCameraMode,
  takeCameraPhoto,
  startCameraRecording,
  stopCameraRecording,
  changeCameraFocalLength,
  cameraAim,
} = usePayloadControl()

async function onAuthPayload() {
  const result = await authPayload(props.sn, payloadSelectInfo.payloadIndex)
  if (result) {
    payloadControlSource.value = ControlSource.A
  }
}

const gimbalResetPopoverData = reactive({
  visible: false,
  loading: false,
  resetMode: null as null | GimbalResetMode,
})

function onShowGimbalResetPopover() {
  gimbalResetPopoverData.visible = !gimbalResetPopoverData.visible
  gimbalResetPopoverData.loading = false
  gimbalResetPopoverData.resetMode = null
}

async function onGimbalResetConfirm(confirm: boolean) {
  if (confirm) {
    if (gimbalResetPopoverData.resetMode === null) {
      // message.error('Please select reset mode')
      return
    }
    gimbalResetPopoverData.loading = true
    try {
      await resetGimbal(props.sn, {
        payload_index: payloadSelectInfo.payloadIndex,
        reset_mode: gimbalResetPopoverData.resetMode
      })
    } catch (err) {
    }
  }
  gimbalResetPopoverData.visible = false
}
// 切换相机模式：广角<=>变焦
async function onSwitchCameraMode() {
  if (!checkPayloadAuth(payloadSelectInfo.controlSource)) {
    return
  }
  const currentCameraMode = payloadSelectInfo.camera?.camera_mode
  await switchCameraMode(props.sn, {
    payload_index: payloadSelectInfo.payloadIndex,
    camera_mode: currentCameraMode === CameraMode.Photo ? CameraMode.Video : CameraMode.Photo
  })
}

async function onTakeCameraPhoto() {
  if (!checkPayloadAuth(payloadSelectInfo.controlSource)) {
    return
  }
  await takeCameraPhoto(props.sn, payloadSelectInfo.payloadIndex)
}

async function onStartCameraRecording() {
  if (!checkPayloadAuth(payloadSelectInfo.controlSource)) {
    return
  }
  await startCameraRecording(props.sn, payloadSelectInfo.payloadIndex)
}

async function onStopCameraRecording() {
  if (!checkPayloadAuth(payloadSelectInfo.controlSource)) {
    return
  }
  await stopCameraRecording(props.sn, payloadSelectInfo.payloadIndex)
}

const zoomFactorPopoverData = reactive({
  visible: false,
  loading: false,
  cameraType: null as null | CameraType,
  zoomFactor: null as null | number,
})

function onShowZoomFactorPopover() {
  zoomFactorPopoverData.visible = !zoomFactorPopoverData.visible
  zoomFactorPopoverData.loading = false
  zoomFactorPopoverData.cameraType = null
  zoomFactorPopoverData.zoomFactor = null
}

async function onZoomFactorConfirm(confirm: boolean) {
  if (confirm) {
    if (!zoomFactorPopoverData.zoomFactor || zoomFactorPopoverData.cameraType === null) {
      // message.error('Please input Zoom Factor')
      return
    }
    zoomFactorPopoverData.loading = true
    try {
      await changeCameraFocalLength(props.sn, {
        payload_index: payloadSelectInfo.payloadIndex,
        camera_type: zoomFactorPopoverData.cameraType,
        zoom_factor: zoomFactorPopoverData.zoomFactor
      })
    } catch (err) {
    }
  }
  zoomFactorPopoverData.visible = false
}

const cameraAimPopoverData = reactive({
  visible: false,
  loading: false,
  cameraType: null as null | CameraType,
  locked: false,
  x: null as null | number,
  y: null as null | number,
})

function onShowCameraAimPopover() {
  cameraAimPopoverData.visible = !cameraAimPopoverData.visible
  cameraAimPopoverData.loading = false
  cameraAimPopoverData.cameraType = null
  cameraAimPopoverData.locked = false
  cameraAimPopoverData.x = null
  cameraAimPopoverData.y = null
}

// 发送aim相关请求
async function onCameraAimConfirm(confirm: boolean) {
  if (confirm) {
    if (cameraAimPopoverData.cameraType === null || cameraAimPopoverData.x === null || cameraAimPopoverData.y === null) {
      // message.error('Input error')
      return
    }
    try {
      await cameraAim(props.sn, {
        payload_index: payloadSelectInfo.payloadIndex,
        camera_type: cameraAimPopoverData.cameraType,
        locked: cameraAimPopoverData.locked,
        x: cameraAimPopoverData.x,
        y: cameraAimPopoverData.y,
      })
    } catch (error) {
    }
  }
  cameraAimPopoverData.visible = false
}
// 获取机场数据
const getDockMsg = async () => {
  await getDockList(workspaceId.value).then((res) => {
    if (res.code !== 0) {
      return
    }
    dockAndAir.value = {
      air_sn: res.data[0].device_dto.child_device_sn,
      air_name: res.data[0].device_dto.children.device_name,
      dock_name: res.data[0].device_dto.capacity_device_dto.name,
      dock_sn: res.data[0].device_dto.capacity_device_dto.sn,
    }
  })
}

onMounted(() => {
  // bus.on('getFlyControl', () => {
  //   onClickFightControl()
  // })
  getDockMsg()
  store.commit('SET_IS_CONTROL_ON', 1)// 告诉item.vue页面已经生成
  // bus.on('toHover', () => {
  //   console.log('hover')
  // })
  // bus.on('toHome', () => {
  //   sendControlCmd(cmdItem, index)
  // })
  document.addEventListener('keydown', maposTrue)
  document.addEventListener('keyup', keyupUpTrue)
})
onUnmounted(() => {
  store.commit('SET_IS_CONTROL_ON', 0)
  document.removeEventListener('keydown', maposTrue)
  document.removeEventListener('keyup', keyupUpTrue)
  // bus.off('toHover')
  // bus.off('toHome')
})
</script>

<style lang='scss' scoped>
.operateUav {
  position: absolute;
  bottom: 20px;
  left: calc(50% - 120px);
  width: 811px;
  height: 186px;

  background: rgba(27, 27, 27, 0.95);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .operateUav-payload {
    //  background-color: #404040;
    width: 240px;
    margin-left: 15px;

    .box {
      .row {
        display: flex;

        .row-item {
          margin: 5px;
        }

        .row-select {
          width: 110px;
          margin-left: 5px;
        }
      }
    }
  }

  .operateUav-line {
    width: 2px;
    background-color: #979797;
    height: 140px;
    margin-right: 20px;
  }

  .operateUav-btn {
    height: 100%;
    width: 110px;
    //   width: 200px;
    // height: 100px;

    // height: 146px;
    background-color: #707070;
    box-shadow: inset 0px 0px 7px 4px rgba(0, 0, 0, 0.5), inset 3px 3px 7px 0px rgba(255, 255, 255, 0.37);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .operateUav-btn-text1 {
      font-size: 16px;
      font-family: AppleSystemUIFont;
      color: #FFFFFF;
    }

    .operateUav-btn-text2 {
      font-size: 12px;
      font-family: SourceHanSansSC-Regular, SourceHanSansSC;
      font-weight: 400;
      color: #696969;
      // color: #696969;
    }
  }

  .operateUav-imgBtn {
    position: relative;
    margin-left: 10px;

    .imgBtn-btn {
      width: 110px;
      height: 146px;
    }

    .imgBtn-text {
      position: absolute;
      top: 35%;
      left: 0;
      width: 100%;

      .imgBtn-text-t1 {
        font-size: 16px;
        font-family: AppleSystemUIFont;
        color: #FFFFFF;
        text-align: center;
      }

      .imgBtn-text-t2 {
        font-size: 12px;
        font-family: SourceHanSansSC, SourceHanSansSC;
        font-weight: 400;
        text-align: center;
        color: #FFFFFF;
      }
    }

  }

  .operateUav-btnBlue {
    background-color: #2f448c !important;
  }

  .operateUav-btnRed {
    background-color: #e68785 !important;
  }

  .padding20 {
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 20px;
  }

  .operateUav-status {
    background: #2A2A2A;
    opacity: 0.5;
    width: 283px;
    height: 146px;
    padding: 10px 0 10px 20px;
    margin: 0 10px;

    .status-item {
      display: flex;
      margin-bottom: 10px;

      .status-position {
        position: relative;

        .status-item-img {
          width: 135px;
          height: 35px;
        }

        .status-item-data {
          position: absolute;
          font-size: 22px;
          font-family: AppleSystemUIFont;
          color: #060810;
          top: 0px;
          left: 32px;
        }

      }

      .status-item-text {
        font-size: 13px;
        font-family: SourceHanSansSC, SourceHanSansSC;
        font-weight: 400;
        // color: #757575;
        color: #fff;
        line-height: 35px;
        margin-left: 26px;

      }

    }
  }

  .operateUav-body {
    background: #2A2A2A;
    opacity: 0.5;
    width: 237px;
    height: 146px;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    padding: 14px 34px;

    .operateUav-body-msg {
      height: 100%;
      display: flex;
      flex-direction: column;

      .operateUav-body-msg-item {
        display: flex;
        flex: 1;
        align-items: center;

        .msg-item-left {
          width: 135px;
          height: 35px;
        }

        .msg-item-right {
          margin-left: 25px;

          color: #fff;
        }
      }
    }

    .operateUav-body-btns {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;

      .plline {
        width: 1px;
        height: 92px;
        opacity: 0.19;
        border: 1px solid #979797;
      }

      .ptl {
        width: 102px;
        display: flex;
        flex-wrap: wrap;

        .planbtn {
          margin-right: 6px;
          margin-bottom: 6px;

          &:nth-of-type(3n) {
            margin-right: 0px;
          }

          span {
            display: inline-block;
            width: 100%;
            height: 29px;
            font-size: 13px;
            text-align: center;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 29px;
          }

          div {
            width: 30px;
            height: 30px;
            background: #757575;
            border-radius: 5px 5px 5px 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 12px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #FFFFFF;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .down {
            background-color: #3A63F3 !important;
          }

        }
      }

    }
  }

}
</style>
