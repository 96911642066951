<template>
  <div class="wayline">
    <a-spin wrapperClassName="wrap" :spinning="loading" :delay="394" tip="downloading" size="large">
      <div class="wayline-header">
        <div class="wayline-header-title">航线库</div>
        <div class="wayline-header-btn" v-if="importVisible">
          <a-tooltip title="航线导出">
            <img @click="daoru" :src="folderLeft" alt="">

          </a-tooltip>
          <a-tooltip title="航线导入
          ">
            <img @click="impdia = true; downdia = false" :src="folderRight" alt="">
          </a-tooltip>

          <div v-if="waylineRoute">
            <a-button style="background-color: #3A63F3;color: #fff;border-radius: 5px;" @click="addbox">新建航线</a-button>
          </div>

        </div>
      </div>

      <div class="wayline-body">
        <div class="wayline-body-top">
          <div class="wayline-body-top-item">
            <div class="wayline-body-top-text1">机场：</div>
            <a-select ref="select" v-model:value="value1">
              <a-select-option v-for="item in docklist" :key="item.device_sn" :value="item.device_sn">
                <a-tooltip>
                  <template #title>{{ item.nickname }}</template>
                  {{ item.nickname }}
                </a-tooltip>
              </a-select-option>
            </a-select>
            <a-tooltip title="新增文件夹" placement="right">
              <img class="wayline-body-top-img" @click="showaddf" :src="addFolder" alt="">
            </a-tooltip>
          </div>
          <div class="wayline-body-top-item">
            <div class="wayline-body-top-text1">无人机：</div>
            <a-select ref="select" v-model:value="value2">
              <a-select-option v-for="item in aircraftlist" :key="item.device_sn" :value="item.device_sn">
                <a-tooltip>
                  <template #title>{{ item.nickname }}</template>
                  {{ item.nickname }}
                </a-tooltip>
              </a-select-option>
            </a-select>
          </div>

          <!-- <div class="chk">
            <span>时间排序</span>
            <div class="chk-img">
              <img src="../../../assets/icons/drone.png" alt="">
            </div>

          </div> -->
        </div>
        <a-spin tip="数据加载中" wrapperClassName="spinClass" :spinning="spinning">
          <div id="data" class="uranus-scrollbar" v-if="waylinesData.data.length !== 0" @scroll="onScroll">
            <div class="wayline-body-dirs" v-for="(wayline, index) in waylinesData.data" :key="wayline.id">
              <div class="dirline">
                <img class="posicon" :src="positionIcon" alt="">
                <div v-show="!wayline.edit" class="wn">{{ wayline.name }}</div>
                <a-input @blur="updt(wayline.name, wayline.id)" v-show="wayline.edit" v-model:value="wayline.name" />
                <img style="margin-right: 8px;" :src="edit" @click="ed($event, index)" alt="">
                <img style="margin-right: 8px;" @click="del(wayline)" :src="dele" alt="">
                <!-- <img @click="wayline.show = !wayline.show" src="../../../assets/icons/drone.png" alt=""> -->
                <img class="zhankai" :src="shouhui" v-if="wayline.show" @click="wayline.show = !wayline.show" alt="">
                <img class="zhankai" :src="zhankai" v-else @click="wayline.show = !wayline.show" alt="">
                <!-- <DownOutlined class="arrows" />
              <UpOutlined class="arrows"  /> -->
              </div>
              <div v-if="wayline.children.length > 0 && wayline.show">
                <draggable :list="wayline.children" :group="state.groupB" :force-fallback="true" animation="300"
                  @start="onStart" @end="onEnd">
                  <template #item="ele">
                    <div class="drag-item" @click="showWayline(ele)">
                      <div class="text-item to width70">
                        <a-tooltip>
                          <template #title>{{ ele.element.name }}</template>
                          {{ ele.element.name }}
                        </a-tooltip>
                      </div>
                      <div class="text-item blueRadio"></div>
                      <div class="text-item ">{{ ele.element.user_name }}</div>
                      <div class="text-item theTime" :title="new Date(ele.element.update_time).toLocaleString()">
                        <!-- {{ (ele.element.update_time).toLocaleString() }} -->
                        {{ (transTime(ele.element.update_time)).toLocaleString() }}
                      </div>
                      <div class="fz20"><a-dropdown>
                          <a style="color: #000;">
                            <MoreOutlined />
                          </a>
                          <template #overlay>
                            <a-menu theme="dark" class="more" style="background: #8B8B8B;">
                              <!-- <a-menu-item @click="selectRoute(ele)">
                                <span>修改</span>
                              </a-menu-item> -->
                              <a-menu-item @click="downloadWayline(ele.element.id, ele.element.name)">
                                <span>下载</span>
                              </a-menu-item>
                              <!-- <a-menu-item>
                              <span>移动</span>
                            </a-menu-item> -->
                              <a-menu-item @click="showWaylineTip(ele.element.id)">
                                <span>删除</span>
                              </a-menu-item>
                            </a-menu>
                          </template>
                        </a-dropdown></div>

                    </div>
                  </template>
                </draggable>
              </div>
            </div>

          </div>
          <div v-else>
            <a-empty :image-style="{ height: '60px', marginTop: '60px' }" />
          </div>
        </a-spin>
        <a-modal v-model:visible="deleteTip" :closable="false" :maskClosable="false" centered
          :okButtonProps="{ danger: true }" @ok="deleteWayline">
          <p class="pt10 pl20" style="height: 50px;">航线文件不可恢复的一次删除，确定吗？</p>
          <template #title>
            <div class="flex-row flex-justify-center">
              <span>确认删除</span>
            </div>
          </template>
        </a-modal>
      </div>
      <!-- 暂时屏蔽，，， -->
      <!-- <div v-if="!waylineRoute" :style="{ height : height + 'px'}" class="scrollbar">
      <router-view />
  </div> -->
    </a-spin>
  </div>

  <div v-if="drawerVisible"
    style="position: absolute; left: 330px; width: 280px; height: 100vh; float: right; top: 0; z-index: 1000; color: white; background: #282828;">

    <div style="position: absolute; top: 15px; right: 10px;">
      <a style="color: white;" @click="closePanel">
        <CloseOutlined />
      </a>
    </div>
  </div>

  <!-- <div v-if="impdia" class="dia">
    <div class="diahed">导入航线</div>
    <div class="diabod">
      <div class="imp">
        <div class="imp-select">
          <div class="imp-select-text">选择文件夹:</div>
          <a-select v-model:value="selectFolder" style="width: 120px">
            <a-select-option v-for="item in waylinesData.data" :key="item.id" :value="item.id">{{ item.folder_name
            }}</a-select-option>
          </a-select>
        </div>

        <a-upload v-if="waylineRoute" name="file" :multiple="false" :before-upload="beforeUpload"
          :show-upload-list="false" :customRequest="uploadFile">
          <div class="btn">导入kmz文件</div>
        </a-upload>
        <span>kmz 中包含 wpmz/template.kml 及 wpmz/wayline.wpml 文件</span>
      </div>
      <div class="tabl">
        <a-table :pagination="false" :columns="upFolderColumns" :data-source="upFolderDatatable" bordered>

        </a-table>
      </div>
      <div class="diafoot">
        <div @click="impdia = false; upFolderDatatable = []">关闭</div>
      </div>
    </div>
  </div> -->
  <a-modal wrapClassName="outW" width="512px" v-model:visible="impdia" :cancel="dwonover" :closable="false" :title="null"
    :footer="null">
    <div class="outWaylineAll">
      <div class="outWaylineAll-title">航线导入</div>
      <div class="imp-select">
        <div class="imp-select-text">选择文件夹:</div>
        <a-select v-model:value="selectFolder" style="width: 120px">
          <a-select-option v-for="item in waylinesData.data" :key="item.id" :value="item.id">{{ item.folder_name
          }}</a-select-option>
        </a-select>
      </div>
      <div class="outWaylineAll-bo">
        <a-upload-dragger v-model:fileList="fileList" name="file" :multiple="true" :before-upload="beforeUpload"
          :show-upload-list="false" :customRequest="uploadFile">

          <p v-if="upFolderDatatable.length == 0" class="ant-upload-text">文件拖入此区域</p>
          <div class="flt" v-else>
            <div class="flb" v-for="item in upFolderDatatable" :key="item.key">
              <div class="flbl">
                <img :src="dwonfile" alt="">
                <div class="nam">{{ item.name }}</div>
              </div>
              <div class="flbr">{{ item.type }}</div>

            </div>
          </div>
        </a-upload-dragger>
      </div>
      <div class="outWaylineAll-footer">
        <a-button class="outWaylineAll-footer-btn" @click="dwonover">取消</a-button>
        <div class="outWaylineAll-footer-null"></div>
        <a-button class="outWaylineAll-footer-btn btnBlue" type="primary" @click="dwonover">完成</a-button>
      </div>
    </div>
  </a-modal>
  <!-- <div v-if="downdia" class="dia">
    <div class="diahed">下载航线</div>
    <div class="diabod">
      <div class="tabl">
        <div class="tahd">
          <div class="tahdbod">文件名称</div>
          <div class="tahdbod">下载进度</div>
          <div class="tahdbod">下载状态</div>
        </div>
        <div class="tablbd">
          <draggable class="drg" @change="change" :list="state.modules.arr1" :group="state.groupA" :force-fallback="true"
            animation="300" @start="onStart" @end="onEnd">
            <template #item="ele">
              <div class="drag-item">
                <div class="text-item">{{ ele.element.name }}</div>
                <div class="text-item">{{ ele.element.user_name }}</div>
                <div class="text-item">{{ ele.element.update_time }}</div>
              </div>
            </template>
          </draggable>
        </div>

      </div>
      <div class="diafoot">
        <div @click="downdia = false">关闭</div>
      </div>
    </div>
  </div> -->

  <a-modal wrapClassName="outW" width="700px" v-model:visible="outWayline" :closable="false" :title="null" :footer="null">

    <div class="outWaylineAll">
      <div class="outWaylineAll-title">航线导出</div>
      <div class="outWaylineAll-body">
        <div class="outWaylineAll-body-dirline" v-for="wayline in waylinesData.data" :key="wayline.id">
          <div class="outWaylineAll-body-dirline-title">
            <div class="title-left">
              <a-checkbox v-model:checked="wayline.checkAllfolder" :indeterminate="wayline.indeterminate"
                @change="onCheckAllChange(wayline)" />
              <img class="posicon" :src="positionIcon" alt="">
              <div v-show="!wayline.edit" class="wn">

                {{ wayline.name }}

              </div>
            </div>
            <img class="zhankai" :src="shouhui" v-if="wayline.dc" @click="wayline.dc = !wayline.dc" alt="">
            <img class="zhankai" :src="zhankai" v-else @click="wayline.dc = !wayline.dc" alt="">
          </div>
          <div class="outWaylineAll-body-dirline-body" v-if="wayline.children.length > 0 && wayline.dc">
            <div class="body-item" v-for="item in wayline.children" :key="item.id">
              <div class="body-item-check">
                <a-checkbox v-model:checked="item.checkThis" @change="onCheckChange(wayline)" />
              </div>
              <div class="body-item-name"> {{ item.name }}</div>
              <div class="body-item-icon"></div>
              <div class="body-item-name2">{{ item.user_name }}</div>
              <div class="body-item-time">{{ new Date(item.update_time).toLocaleString() }}</div>
            </div>
          </div>

        </div>
      </div>
      <div class="outWaylineAll-footer">
        <a-button class="outWaylineAll-footer-btn" @click="outWayline = !outWayline">取消</a-button>
        <div class="outWaylineAll-footer-null"></div>
        <a-button class="outWaylineAll-footer-btn btnBlue" type="primary" @click="downname = true">下载</a-button>
      </div>
    </div>
  </a-modal>
  <a-modal title="文件名" v-model:visible="downname" :confirm-loading="confirmLoading" @ok="handleOk">
    <a-input v-model:value="downss.name" />
  </a-modal>

  <div v-if="adddia" class="adddia">
    <div class="diahed">新建航线</div>
    <div class="diabod">
      <a-form ref="formRef" :rules="rules" :model="waylineForm">
        <a-form-item label="航线名称" name="name">
          <a-input v-model:value="waylineForm.name" />
        </a-form-item>
        <a-form-item label="选择文件夹" name="folder">
          <a-select v-model:value="waylineForm.folder">
            <a-select-option v-for="item in waylinesData.data" :key="item.id" :value="item.id">{{ item.name
            }}</a-select-option>
            <!-- <a-select-option value="beijing">Zone two</a-select-option> -->
          </a-select>
        </a-form-item>
        <a-form-item label="选择机场" name="uav">
          <a-select @change="takeair" v-model:value="waylineForm.uav">
            <a-select-option v-for="item in docklist" :key="item.device_sn" :value="item.device_sn">{{ item.nickname
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <!-- <a-form-item label="选择无人机" name="airplan">
          <a-select disabled v-model:value="waylineForm.airplan">
            <a-select-option value="shanghai">M30</a-select-option>
            <a-select-option value="beijing">Zone two</a-select-option>
          </a-select>
        </a-form-item> -->
      </a-form>
      <div class="diafoot">
        <div class="btn btn1" @click="adddia = false">取消</div>
        <!-- <router-link  :to="{ name: ERouterName.CREATE_WAYLINE}"> -->
        <div class="btn btn2" @click="rotgo">完成</div>
        <!-- </router-link> -->

      </div>

    </div>

  </div>
</template>

<script lang="ts" setup>

import draggable from 'vuedraggable'
import { reactive } from '@vue/reactivity'
import { message } from 'ant-design-vue'
import {
  PlusOutlined,
  CloseOutlined,
  DownOutlined,
  UpOutlined,
  MoreOutlined
} from '@ant-design/icons-vue'
import { onMounted, onUpdated, ref, UnwrapRef, toRaw, computed } from 'vue'
import { deleteWaylineFile, downloadWaylineFile, getWaylineFiles, detailWayline, importKmzFile, getWaylineMSG, getWaylineFolder, updateWaylineFolder, delWaylineFolder, insertWaylineFolder, downloadWaylineFolder } from '/@/api/wayline'
import { EDeviceTypeName, ELocalStorageKey, ERouterName } from '/@/types'
import { useRoute } from 'vue-router'
import { Device, EDeviceType } from '/@/types/device'
import { useMyStore } from '/@/store'
import { WaylineFile } from '/@/types/wayline'
import { downloadFile } from '/@/utils/common'
import { IPage } from '/@/api/http/type'
import { CURRENT_CONFIG } from '/@/api/http/config'
import { load } from '@amap/amap-jsapi-loader'
import { getRoot } from '/@/root'
import router from '/@/router'
import dele from '/@/assets/waylines/wayDelete.png'
import edit from '/@/assets/waylines/wayEdit.png'
import dwonfile from '/@/assets/waylines/dwonfile.png'
import zhankai from '/@/assets/waylines/zhankai.png'
import shouhui from '/@/assets/waylines/shouhui.png'
import positionIcon from '/@/assets/waylines/position.png'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import addFolder from '/@/assets/waylines/addFolder.png'
import folderLeft from '/@/assets/waylines/folderLefts2.png'
import folderRight from '/@/assets/waylines/folderRight2.png'
import { getBindingDevices } from '/@/api/manage'
import { drawWaylinePoint, drawWaylinePointDelete, clearStart } from '/@/hooks/use-cesium-map'
const confirmLoading = ref<boolean>(false)
const pointDrawer = ref(false)
const loading = ref(false)
const impdia = ref(false)
const adddia = ref(false)
const store = useMyStore()
const route = useRoute()
const downname = ref(false)

const downss = ref({
  name: '',
  waylineId: []
})
const spinning = ref(true)// 加载中
const handleOk = async () => {
  confirmLoading.value = true
  const res = await downloadWaylineFolder(workspaceId, downss.value)
  // 下载文件流
  //, {
  // 下载的文件类型(此处可更改：具体取值参考以下链接地址)
  // type: 'application/vnd.google-earth.kmz'
  // }
  const blob = new Blob([res])
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.style.display = 'none'
  link.download = `${downss.value.name}.zip`
  link.href = url
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  if (res) {
    downname.value = false
    confirmLoading.value = false
    outWayline.value = false
    getWaylines()
  } else {
    message.error('下载失败')
  }
}
const pagination: IPage = {
  page: 1,
  total: -1,
  page_size: 10
}
const drawerVisible = ref(false)
const waylinesData = reactive({
  data: [] as WaylineFile[]
})
const waylineRoute = computed(() => {
  console.log(route.name, ' route.name')

  return route.name === ERouterName.WAYLINE
})
const ed = (e, index) => {
  waylinesData.data[index].edit = !waylinesData.data[index].edit
  setTimeout(() => {
    e.target.previousElementSibling.focus()
  }, 1)
}
const del = async (wayline) => {
  const res = await delWaylineFolder(workspaceId, { id: wayline.id })
  getWaylines()
  console.log(res.data, 1111111111)
}
const change = (evt) => {
  console.log(evt.added.element)
  downloadWayline(evt.added.element.id, evt.added.element.name)
}
const selectFolder = ref('')
const root = getRoot()
const workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId)!
const deleteTip = ref(false)
const deleteWaylineId = ref<string>('')
const canRefresh = ref(true)
const importVisible = ref<boolean>(root.$router.currentRoute.value.name === ERouterName.WAYLINE)

const username = ref(localStorage.getItem(ELocalStorageKey.Username))
const waylineForm = ref({
  name: '',
  folder: null,
  airplan: '',
  uav: ''
})
const rules = {
  name: [
    { required: true, message: '请填写航线名称', trigger: 'blur' },
  ],
  folder: [{ required: true, type: 'number', message: '请选择文件夹', trigger: 'blur' }],
  // airplan: [{ required: true, message: '请选择机场', trigger: 'blur' }],
  uav: [{ required: true, message: '请选择无人机', trigger: 'blur' }],
}
const downdia = ref(false)// 下载航线
const outWayline = ref(false)// 下载航线
const rotgo = () => {
  formRef.value
    .validate()
    .then(async () => {
      // console.log('values', waylineForm, toRaw(waylineForm))
      console.log(waylineForm.value, '表单信息')
      clearStart()
      store.commit('SET_WAYLINEFORM_MSG', waylineForm.value)
      drawWaylinePointDelete(store)
      router.push({ name: ERouterName.CREATE_WAYLINE })
    })
    .catch((error: ValidateErrorEntity<any>) => {
      console.log('error', error)
    })
}
const addbox = () => {
  waylineForm.value = {
    name: '',
    folder: null,
    airplan: '',
    uav: ''
  }
  adddia.value = true
}
const upFolderColumns = [
  {
    title: '文件名称',
    dataIndex: 'name',
  },

  {
    title: '上传状态',
    dataIndex: 'type',
  },
]
const upFolderDatatable: any = ref([])

const downcolumns = [
  {
    title: '文件名称',
    dataIndex: 'name',
  },
  {
    title: '下载进度',
    className: 'column-operation',
    dataIndex: 'operation',
  },
  {
    title: '下载状态',
    dataIndex: 'type',
  },

]

const drag = ref(false)
const state = reactive({
  message: 'A组只能往B组拖到一个元素',
  groupA: {
    name: 'itxst',
    put: true, // 允许拖入
    pull: 'clone',
  },
  groupB: {
    name: 'itxst',
    put: false,
    pull: 'clone',
  },
  modules: {
    arr1: [

    ],
    arr2: [
      { name: 'B组', id: 5 },
      { name: '员工', id: 6 },
      { name: '报表', id: 7 },
    ],
  },
})

const showadd = ref(false)
const newfoldname = ref('新建文件夹')

// 编辑
const updt = async (name: string, id: string) => {
  const res = await updateWaylineFolder(workspaceId, { folderName: name, id: id })

  getWaylines()
}

const showaddf = async () => {
  const res = await insertWaylineFolder(workspaceId, { folderName: newfoldname.value })
  // showadd.value = true
  getWaylines()
}
const takeair = (data, op) => {

}
const value1 = ref('瑞苍TJ04标机场')
const value2 = ref('Matrice 30')
const aircraftlist = ref([
  {
    nickname: 'Matrice 30',
    device_sn: '123'
  }])
const docklist = ref([
  {
    nickname: '瑞苍TJ04标机场',
    device_sn: '123'
  }])
function getDevices(closeLoading?: boolean) {
  getBindingDevices(workspaceId, { page: 0, page_size: 100 }, 0).then(res => {
    if (res.code !== 0) {
      return
    }
    const resData: Device[] = res.data.list
    resData.forEach((val: any) => {
      if (val.children) {
        val.children = [val.children]
      }
    })
    aircraftlist.value = resData
    console.log(aircraftlist.value, 222222)
  })
  getBindingDevices(workspaceId, { page: 0, page_size: 100 }, 3).then(res => {
    if (res.code !== 0) {
      return
    }
    const resData: Device[] = res.data.list
    resData.forEach((val: any) => {
      if (val.children) {
        val.children = [val.children]
      }
    })
    docklist.value = resData
    console.log(docklist.value, 333333)
  })
}
const onCheckAllChange = (wayline: any) => { // 下载航线全选方法
  if (wayline.checkAllfolder) {
    wayline.indeterminate = false
    wayline.children.forEach((item: any) => {
      item.checkThis = true
      if (downss.value.waylineId.indexOf(item.id) == -1) {
        downss.value.waylineId.push(item.id)
      }
    })
  } else if (wayline.indeterminate || !wayline.checkAllfolder) {
    wayline.indeterminate = false
    wayline.children.forEach((item: any) => {
      item.checkThis = false
      downss.value.waylineId = downss.value.waylineId.filter((i) => {
        return i != item.id
      })
    })
  }
}
const daoru = () => {
  outWayline.value = true
  impdia.value = false
}
const onCheckChange = (wayline: any) => { // 下载航线单选方法
  let tempfalse = false
  wayline.children.forEach((item: any) => {
    if (item.checkThis) {
      if (downss.value.waylineId.indexOf(item.id) == -1) {
        downss.value.waylineId.push(item.id)
      }
      tempfalse = true
    } else {
      tempfalse = false
      downss.value.waylineId = downss.value.waylineId.filter((i) => {
        return i != item.id
      })
    }
  })

  wayline.indeterminate = tempfalse
}
const dwonover = () => {
  impdia.value = false
  upFolderDatatable.value = []
  fileList.value = []
}

// 获取航线信息后画点
const showWayline = async (ele: any) => {
  message.info('读取中')
  let positions = []
  await getWaylineMSG(workspaceId, ele.element.id).then((res: any) => {
    console.log(res.data, '航线信息')
    const takeoffArr = res.data['wpml:missionConfig']['wpml:takeOffRefPoint'].split(',')
    if (res.data.template.Placemark instanceof Array) {
      positions = res.data.template.Placemark.map((item: any) => {
        return [Number(item.Point.coordinates.split(',')[0]), Number(item.Point.coordinates.split(',')[1]), Number(item['wpml:height'])]
      })
    } else {
      positions = [[Number(res.data.template.Placemark.Point.coordinates.split(',')[0]), Number(res.data.template.Placemark.Point.coordinates.split(',')[1]), res.data.template.Placemark['wpml:height']]]
    }
    positions.unshift([Number(takeoffArr[1]), Number(takeoffArr[0]), Number(takeoffArr[2])])
    drawWaylinePoint(positions, store)
  })
}
// 时间戳转yyyy-mm-dd hh:mm
const transTime = (num: any) => {
  // 替换为你的时间戳
  const timestamp = Math.round(num / 1000)
  // 创建一个Date对象，并使用时间戳作为参数
  const date = new Date(timestamp * 1000)

  // 获取年、月、日、小时和分钟
  const year = date.getFullYear()
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const day = ('0' + date.getDate()).slice(-2)
  const hours = ('0' + date.getHours()).slice(-2)
  const minutes = ('0' + date.getMinutes()).slice(-2)

  // 拼接成"yyyy-mm-dd hh:mm"格式的字符串
  const formattedDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes
  return formattedDate
}
onMounted(() => {
  // const parent = document.getElementsByClassName('scrollbar').item(0)?.parentNode as HTMLDivElement
  // height.value = document.body.clientHeight - parent.firstElementChild!.clientHeight
  getDevices()
  getWaylines()
  // const key = setInterval(() => {
  //   const data = document.getElementById('data')?.lastElementChild as HTMLDivElement
  //   if (pagination.total === 0 || Math.ceil(pagination.total / pagination.page_size) <= pagination.page || height.value <= data?.clientHeight + data?.offsetTop) {
  //     clearInterval(key)
  //     return
  //   }
  //   pagination.page++
  //   getWaylines()
  // }, 1000)
})
const onStart = () => {
  drag.value = true
  console.log(drag.value)
}
const onEnd = () => {
  drag.value = false
  console.log(state.modules.arr1)
}
// 关闭新增弹窗
function closePanel() {
  drawerVisible.value = false
}
// 新增航线表单
interface waylineForm {
  name: string
  flyToWaylineMode: string
  finishAction: string

}
const formRef = ref()
const addwaylineForm: UnwrapRef<waylineForm> = reactive({
  name: '',
  flyToWaylineMode: '',
  finishAction: ''
})
const labelCol = { span: 6 }
const wrapperCol = { span: 16 }
const addRules = {
  name: [
    { required: true, message: '请输入航线名称', trigger: 'blur' },
  ],

}
// template xml文件
const rootTemplate = {
  kml: {
    $: {
      xmlns: 'http://www.opengis.net/kml/2.2',
      'xmlns:wpml': 'http://www.dji.com/wpmz/1.0.2'
    },
    Document: {
      'wpml:author': username.value,
      'wpml:createTime': new Date().getTime(),
      'wpml:updateTime': new Date().getTime(),
      'wpml:missionConfig': {
        'wpml:flyToWaylineMode': addwaylineForm.flyToWaylineMode, // 飞行模式
        'wpml:finishAction': addwaylineForm.finishAction, // 航线结束动作
        // 'wpml:exitOnRCLost': exitOnRCLost.goContinue// 失控是否继续执行航线
      }
    }
  }
}
// const json2Xml = (data:any) => {
//   const builder = new xml2js.Builder()
//   const xml = builder.buildObject(data)
//   console.log(xml, '提交的xml文件')
// }
// // 提交新增航线表单
// const onSubmit = () => {
//   formRef.value
//     .validate()
//     .then(() => {
//       json2Xml(rootTemplate)
//       console.log()
//     })
//     .catch((error: ValidateErrorEntity<waylineForm>) => {
//       console.log('error', error)
//     })
// }
/// ////////////////////////
// 获取航线
// function getWaylines () {
//   if (!canRefresh.value) {
//     return
//   }
//   canRefresh.value = false
//   // 从后端获取航线文件
//   getWaylineFiles(workspaceId, {
//     page: pagination.page,
//     page_size: pagination.page_size,
//     order_by: 'update_time desc'
//   }).then(res => {
//     if (res.code !== 0) {
//       return
//     }
//     console.log(res, 'res航线文件')

//     waylinesData.data = [...waylinesData.data, ...res.data.list]

//     pagination.total = res.data.pagination.total
//     pagination.page = res.data.pagination.page
//   }).finally(() => {
//     canRefresh.value = true
//   })
// }
// 获取航线文件夹
function getWaylines() {
  spinning.value = true
  waylinesData.data = []
  getWaylineFolder(workspaceId).then(res => {
    if (res.code !== 0) {
      return
    }
    waylinesData.data = [...waylinesData.data, ...res.data]
    waylinesData.data = waylinesData.data.map((item: any) => {
      if (item.wayline_file_dtos.length !== 0) {
        item = {
          ...item,
          name: item.folder_name,
          children: JSON.parse(JSON.stringify(item.wayline_file_dtos)),
          edit: false,
          show: false,
          dc: false,
          checkAllfolder: false,
          indeterminate: false
        }
      } else {
        item = {
          ...item,
          name: item.folder_name,
          children: [],
          edit: false,
          dc: false,
          show: false
        }
      }

      return item
    })
    waylinesData.data = waylinesData.data.map((item: any) => {
      if (item.children.length > 0) {
        item.children = item.children.map((chil: any) => {
          chil = {
            ...chil,
            checkThis: false,
            folder_name: item.folder_name,
            folder_id: item.id
          }
          return chil
        })
      }
      return item
    })
    console.log(waylinesData.data, '航线文件夹')
    spinning.value = false
  }).finally(() => {
    canRefresh.value = true
    if (showadd.value) {
      waylinesData.data[waylinesData.data.length - 1].edit = true
    }

    showadd.value = false
  })
}

function showWaylineTip(waylineId: string) {
  deleteWaylineId.value = waylineId
  deleteTip.value = true
}

function deleteWayline() {
  deleteWaylineFile(workspaceId, deleteWaylineId.value).then(res => {
    if (res.code === 0) {
      message.success('航线文件删除成功！')
    }
    deleteWaylineId.value = ''
    deleteTip.value = false
    pagination.total = 0
    pagination.page = 1
    waylinesData.data = []
    getWaylines()
  })
}

function downloadWayline(waylineId: string, fileName: string) {
  loading.value = true
  downloadWaylineFile(workspaceId, waylineId).then(res => {
    if (!res) {
      return
    }
    const data = new Blob([res], { type: 'application/zip' })
    downloadFile(data, fileName + '.kmz')
  }).finally(() => {
    loading.value = false
  })
}

// 点击航线获取航点信息,创建计划时选择航点
async function selectRoute(wayline: any) {
  console.log(wayline, '航线信息')
  store.commit('CHANGE_WALINETYPE', { // 存储编辑信息
    bool: true,
    id: wayline.element.folder_id
  })// 代表进入编辑模式
  store.commit('SET_WAYLINEFORM_MSG', { // 存储文件夹信息
    name: wayline.element.folder_name,
    folder: wayline.element.folder_id,
    airplan: 'string',
    uav: '4TADKCF001000Q',
  })
  //
  const res = await getWaylineMSG(workspaceId, wayline.element.id)// 获取航线信息
  // console.log(res, '编辑的航线信息')
  // 录入航线全局变量
  store.commit('SET_WAYLINEFORM', {
    author: res.data['wpml:author'],

    executeHeightMode: res.data.waylines.Folder['wpml:executeHeightMode'], // 执行高度模式 way

    globalHeight: res.data.template.Folder['wpml:globalHeight'], // 海拔 tem
    ellipsoidHeight: 100, // 全局航线高度（WGS84椭球高度） tem

    autoFlightSpeed: res.data.template.Folder['wpml:autoFlightSpeed'], // 全局航线飞行速度 1-15

    flyToWaylineMode: res.data['wpml:missionConfig']['wpml:flyToWaylineMode'], // 垂直爬升安全高度
    takeOffSecurityHeight: res.data['wpml:missionConfig']['wpml:takeOffSecurityHeight'], // 安全起飞高度
    takeOffRefPoint: res.data['wpml:missionConfig']['wpml:takeOffRefPoint'], // 参考起飞点

    waypointHeadingMode: res.data.template.Folder['wpml:globalWaypointHeadingParam']['wpml:waypointHeadingMode'], // 飞行器偏航角模式
    waypointHeadingAngle: res.data.template.Folder['wpml:globalWaypointHeadingParam']['wpml:waypointHeadingAngle'], // 偏航角模式 自定义
    waypointPoiPoint: res.data.template.Folder['wpml:globalWaypointHeadingParam']['wpml:waypointPoiPoint'], // 偏航角模式 兴趣点
    waypointHeadingPathMode: res.data.template.Folder['wpml:globalWaypointHeadingParam']['wpml:waypointHeadingPathMode'], // 飞行器偏航角转动方向（枚举）

    gimbalPitchMode: res.data.template.Folder['wpml:gimbalPitchMode'], // 云台俯仰角控制模式 tem

    globalWaypointTurnMode: res.data.template.Folder['wpml:globalWaypointTurnMode'], // 全局航点类型  tem
    globalUseStraightLine: res.data.template.Folder['wpml:globalUseStraightLine'], // 全局航段轨迹是否尽量贴合直线  tem

    globalRTHHeight: res.data['wpml:missionConfig']['wpml:globalRTHHeight'], // 返航高度 非必须 miss

    finishAction: res.data['wpml:missionConfig']['wpml:finishAction'], // 航线结束动作      miss
    exitOnRCLost: res.data['wpml:missionConfig']['wpml:exitOnRCLost'], // 失控是否继续执行航线 miss
    executeRCLostAction: res.data['wpml:missionConfig']['wpml:executeRCLostAction'], // 失控动作类型 miss
  })
  const tempArr = res.data.template.Placemark instanceof Array ? res.data.template.Placemark : [{ ...res.data.template.Placemark }]

  tempArr.forEach((item: any, index: number) => { // 录入航点信息
    const msg = {
      position: {
        lng: Number(item.Point.coordinates.split(',')[0]),
        lat: Number(item.Point.coordinates.split(',')[1])
      },
      index: index,
      height: item['wpml:height'],
      dixingHeight: item['wpml:ellipsoidHeight'],
      waypointSpeed: item['wpml:waypointSpeed'],
      waypointTurnMode: item['wpml:waypointTurnParam']['wpml:waypointTurnMode'],
      waypointHeadingMode: item['wpml:waypointHeadingParam']['wpml:waypointHeadingMode'],
      waypointHeadingAngle: item['wpml:waypointHeadingParam']['wpml:waypointHeadingAngle'],
      waypointHeadingParam: item['wpml:waypointHeadingParam']['wpml:waypointHeadingPathMode'],
      action: []
    }
    // 给航线添加动作
    if (Object.keys(item).indexOf('wpml:actionGroup') !== -1) {
      console.log('有动作')
      msg.action.push(item['wpml:actionGroup']['wpml:action'])
      console.log(item, msg, '动作添加完成')

      store.commit('SET_WALINE_ALLMSG', msg)
    } else {
      store.commit('SET_WALINE_ALLMSG', msg)
    }
  })
  // console.log(store.state.waylinePointMsg, '看看添加之后的样子')
  router.push({ name: ERouterName.CREATE_WAYLINE })
}

function onScroll(e: any) {
  const element = e.srcElement
  if (element.scrollTop + element.clientHeight >= element.scrollHeight - 5 && Math.ceil(pagination.total / pagination.page_size) > pagination.page && canRefresh.value) {
    pagination.page++
    getWaylines()
  }
}

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  url?: string;
}

interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}
const fileList = ref<FileItem[]>([])

function beforeUpload(file: FileItem) {
  if (selectFolder.value === '') {
    message.error('请选择文件夹')
    return false
  } else {
    fileList.value = [file]
    console.log(fileList.value, 'fileList.value')
    upFolderDatatable.value.push({
      key: fileList.value[0].uid,
      name: fileList.value[0].name,
      type: '上传中'
    })
    loading.value = true
    return true
  }
}
const uploadFile = async () => {
  fileList.value.forEach(async (file: FileItem) => {
    const fileData = new FormData()
    fileData.append('file', file, file.name)

    await importKmzFile(workspaceId, fileData, selectFolder.value).then((res) => {
      if (res.code === 0) {
        const thisItem = upFolderDatatable.value.find(item => item.key === file.uid)
        if (thisItem) {
          thisItem.type = '已导入'
        }
        message.success(`${file.name} file uploaded successfully`)
        canRefresh.value = true
        pagination.total = 0
        pagination.page = 1
        waylinesData.data = []
        getWaylines()
      } else {
        const thisItem = upFolderDatatable.value.find(item => item.key === file.uid)
        if (thisItem) {
          thisItem.type = '失败'
        }
      }
    }).finally(() => {
      loading.value = false
      fileList.value = []
    })
  })
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

:deep(.ant-form-horizontal .ant-form-item-label) {
  width: 100px;
  text-align: left;
}

:deep(.ant-select-arrow) {
  top: 25%;
  margin-top: 0;
}

.wayline {
  height: 100%;
  padding: 15px;

  .wrap {
    height: 100%;
  }

  :deep(.ant-spin-container) {
    height: 100%;
  }

  .wayline-header {
    background-color: #FDFDFD;
    height: 50px;
    line-height: 50px;
    font-weight: 450;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 10px 0;
    box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.03);
    border-radius: 5px;

    .wayline-header-title {
      font-size: 18px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #000000;
      line-height: 27px;
      margin-left: 22px;
    }

    .wayline-header-btn {
      display: flex;
      align-items: center;
      margin-right: 10px;

      img {
        width: 25px;
        height: 25px;
        cursor: pointer;
        margin-right: 13px;
      }
    }
  }

  .wayline-body {
    background-color: #FDFDFD;
    height: calc(100% - 60px);

    :deep(.ant-select-selector) {
      width: 180px;
      height: 31px;
    }

    .wayline-body-top {
      // height: 60px;
      font-size: 14px;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      font-weight: 400;
      color: #000000;
      line-height: 21px;
      padding-top: 10px;
      margin-bottom: 10px;

      .wayline-body-top-item {
        height: 30px;
        display: flex;
        align-items: center;

        .wayline-body-top-text1 {
          width: 70px;
          margin-left: 8px;
          margin-right: 4px;
        }

        .wayline-body-top-img {
          margin-left: 50px;
        }

        .wayline-body-top-text2 {
          margin-left: 4px;
          margin-right: 4px;
        }
      }

      :deep(.ant-select-selector) {
        height: 28px !important;
      }

      .chk {
        margin-left: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .chk-img {
          margin-right: 8px;
        }

        img {
          width: 10px;
          height: 10px;
        }

        div {
          width: 11px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          img {
            width: 10px;
            height: 10px;
          }
        }

      }

      img {
        width: 24px;
        height: 22px;
        cursor: pointer;
      }
    }

    .wayline-body-dirs {
      border-radius: 4px;
      border: 2px solid rgba(58, 99, 243, 0.15);
      margin: 0 8px 14px 8px;

      .dirline {
        display: flex;
        padding: 14px;
        align-items: center;

        .posicon {
          width: 12px;
          height: 12px;
        }

        .zhankai {
          width: 16px;
          height: 16px;
        }

        .wn {
          width: 273px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          font-size: 14px;
          margin: 0 10px;
        }

        img {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }

        .arrows {
          color: #3A63F3;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

  }
}

.wayline-panel {
  background: #f3f3f3;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  height: 90px;
  width: 95%;
  font-size: 13px;
  border-radius: 2px;

  cursor: pointer;

  .title {
    display: flex;
    color: #000;
    flex-direction: row;
    align-items: center;
    height: 30px;
    font-weight: bold;
    margin: 0px 10px 0 10px;
  }
}

.scrollbar {
  height: calc(100vh - 200px);
}

.uranus-scrollbar {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #c5c8cc transparent;
}

.adddia {
  //新建航线
  position: absolute;
  width: 316px;
  // height: 259px;
  top: 90px;
  z-index: 999;
  left: 490px;
  background: #FFFFFF;
  border-radius: 5px 5px 5px 5px;

  .diahed {
    margin: 16px 22px 0px;
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #000000;
    line-height: 27px;
  }

  .diabod {
    padding: 5px 22px;

    .ant-form-item {
      margin-bottom: 5px
    }

    .diafoot {
      display: flex;
      align-items: center;
      justify-content: right;
      margin-top: 20px;
      margin-bottom: 10px;

      .btn {

        color: #fff;
        width: 132px;
        height: 30px;
        cursor: pointer;
        margin-left: 8px;
        text-align: center;
        line-height: 30px;
        border-radius: 4px;
        font-size: 14px;
        font-family: AppleSystemUIFont;
      }

      .btn1 {
        color: #000;
        background-color: #F3F3F3;
      }

      .btn2 {
        color: #fff;
        background-color: #3A63F3;
      }
    }
  }

  // }

}

.dia {
  position: absolute;
  width: 955px;
  height: 686px;
  top: 110px;
  left: 520px;
  z-index: 999;

  .diahed {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    height: 50px;
    background: #1890FF;
  }

  .diabod {
    background: #FFFFFF;
    padding: 28px 24px;

    .imp {
      display: flex;
      height: 80px;
      align-items: center;

      .imp-select {
        display: flex;
        color: #000;
        align-items: center;

        .imp-select-text {
          width: 100px;
        }
      }

      .btn {
        color: #fff;
        margin-left: 20px;
        margin-right: 10px;
        width: 200px;
        height: 40px;
        background-color: #2D8DF0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      span {
        font-size: 9px;
        color: #c5c8cc;
      }
    }

    .tabl {
      height: 386px;
      opacity: 1;
      border: 2px solid #E7E7E7;
      border-top: 0;

      .tahd {
        height: 74px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tahdbod {
          font-size: 22px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #3E3E3E;
          background: #E8E8E8;
          height: 100%;
          line-height: 74px;
          padding-left: 42px;

          &:nth-of-type(1) {
            width: 416px;
          }

          &:nth-of-type(2) {
            width: 213px;
          }

          &:nth-of-type(3) {
            width: 271px;
          }
        }
      }
    }

    .diafoot {
      display: flex;
      justify-content: right;
      align-items: center;
      margin-top: 10px;

      // .diafoot-close{
      //   display: flex;
      //   align-items: center;
      //   text-align: center;
      // }
      div {
        margin-bottom: 20px;
        cursor: pointer;
        width: 124px;
        height: 39px;
        line-height: 35px;
        text-align: center;
        color: #000;
        background: #D8D8D8;
      }
    }
  }
}

:deep(.ant-tree-node-content-wrapper) {
  height: auto !important;
  min-height: 0px;
}

:deep(.ant-tree li .ant-tree-node-content-wrapper:hover) {
  background-color: #FFFFFF;
}

.tablbd {
  height: 312px;
  overflow-y: scroll;
}

.drg {
  min-height: 312px;
}

.drag-item {
  display: flex;
  color: #000000;
  align-items: center;
  background-color: #F2F2F2;
  border-radius: 4px;
  justify-content: space-around;
  font-size: 14px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: #545454;
  cursor: pointer;
  // width: 320px;
  // margin-left: 52px;
  margin: 0 14px;
  margin-bottom: 8px;

  &:hover {
    background: #E6F7FF;
  }

  &:hover .to {
    color: #1890FF;
  }

  .blueRadio {
    width: 8px;
    height: 8px;
    background: #3A63F3;
    border-radius: 8px;
  }

  .theTime {
    font-size: 12px;
    font-family: SourceHanSansCN-Light, SourceHanSansCN;
    font-weight: 300;
    color: #000000;
  }

  .text-item {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    /* 设置 div 的最大宽度 */
    // max-width: 70px;
  }

  .width70 {
    width: 70px
  }

}

.outWaylineAll {

  // padding:34px;
  .outWaylineAll-title {
    text-align: center;
    ;
    font-size: 18px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #3D3D3D;
    margin-bottom: 10px;
  }

  .outWaylineAll-body {
    // width: 636px;
    border: 2px solid rgba(58, 99, 243, 0.15);

    .outWaylineAll-body-dirline {
      padding: 14px;

      .outWaylineAll-body-dirline-title {
        display: flex;
        justify-content: space-between;

        .title-left {
          display: flex;
          align-items: center;

          .posicon {
            width: 12px;
            height: 12px;
            margin-left: 10px;

          }

          .wn {
            width: 273px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
            font-size: 14px;
            margin: 0 10px;
          }

          .zhankai {
            width: 16px;
            height: 16px;
          }
        }
      }

      .outWaylineAll-body-dirline-body {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .body-item {
          background-color: #F2F2F2;
          width: 285px;
          height: 34px;
          overflow: hidden;
          display: flex;
          align-items: center;
          margin: 10px;
          margin-left: 0;

          .body-item-check {
            display: flex;
            align-items: center;
            background-color: #fff;
            height: 100%;
            width: 30px;

          }

          .body-item-name {
            width: 70px;
            margin-left: 15px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .body-item-name2 {
            width: 70px;
            margin-left: 5px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .body-item-icon {
            background-color: #3a63f3;
            width: 8px;
            height: 8px;
            border-radius: 50%;
          }

          .body-item-time {
            font-size: 10px;
            font-family: SourceHanSansCN-Light, SourceHanSansCN;
            font-weight: 300;
            color: #000000;
            margin-left: 5px;
            width: 120px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      img {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }

      .arrows {
        color: #3A63F3;

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .imp-select {
    display: flex;
    color: #000;
    align-items: center;

    .imp-select-text {
      width: 100px;
    }
  }

  .outWaylineAll-bo {
    height: 193px;
    margin-top: 20px;

    .flt {
      height: 100%;
      padding: 10px 20px;
      overflow-y: scroll;

      .flb {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #3D3D3D;

        .flbl {
          display: flex;
          align-items: center;

          img {
            width: 30px;
            height: 30px;
            margin-right: 8px;
          }
        }
      }
    }

    .ant-upload-text {
      margin-top: 80px;
      font-size: 18px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #3A63F3;
    }

    :deep(.ant-upload.ant-upload-drag) {
      border: 4px dashed #3A63F3;
    }

  }

  .outWaylineAll-footer {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    ;

    .outWaylineAll-footer-btn {
      width: 214px;
      height: 40px;
    }

    .btnBlue {
      background-color: #3A63F3;
    }

    .outWaylineAll-footer-null {
      width: 16px;
    }
  }
}
</style>
<style lang="scss">
.ant-modal-content {
  // width: 700px !important ;
  // height: 400px;
}
</style>
